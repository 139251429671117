import { useState, useEffect } from 'react';
import * as Fingerprint2 from 'fingerprintjs2';
import CMSApi from 'common/api/CMSAPI';
import { get, cloneDeep } from 'lodash';
import { saveJSONparsing } from 'utils/formatters';
import Product from 'common/DataObjects/Product';
import Betslip from 'common/DataObjects/Betslip';
import Bet from 'common/DataObjects/Bet';
import Race from 'common/DataObjects/Race';
import { NEW_TRACK_PAGE } from '../../../configs/main';

const useData = (props, listMode, ids) => {
    const mobileBetData = cloneDeep(props);

    mobileBetData.amount = get(props, 'modalData.prices.amountToPut', null);
    mobileBetData.sumPrice = get(props, 'modalData.prices.sumPrice', null);
    mobileBetData.uSaleInfo = get(props, 'modalData.uSaleInfo', null);
    mobileBetData.lockedLegs = get(props, 'modalData.lockedLegs', null);

    const [data, setData] = useState(mobileBetData);
    const [isPending, setPending] = useState(true);

    useEffect(
        () => {
            listMode
                ? getMobileBets(ids, get(data, 'user.id', null), setData, setPending)
                : createNewBet(data, setData, setPending);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return [data, isPending, setData];
};

const filterBetSlip = betInfo => {
    const productId = get(betInfo, 'product.id', null);
    let betSlipPath = `betSlip.betsByDates['${betInfo.date}']`;
    let response = {
        [betInfo.date]: get(betInfo, betSlipPath, null),
    };

    if (['V', 'P', 'K'].indexOf(productId) > -1) {
        /* one start */
        const raceIndex = get(betInfo, 'race.index', null);
        betSlipPath += `['${betInfo.trackCode}']['${productId}'][${raceIndex}]`;
        response = {
            [betInfo.date]: {
                [betInfo.trackCode]: {
                    [productId]: {
                        [raceIndex]: get(betInfo, betSlipPath, {}),
                    },
                },
            },
        };
    } else if (!betInfo.isMultitrackProduct) {
        /* few starts are possible */
        betSlipPath += `['${betInfo.trackCode}']['${productId}']`;
        response = {
            [betInfo.date]: {
                [betInfo.trackCode]: {
                    [productId]: get(betInfo, betSlipPath, {}),
                },
            },
        };
    } else {
        //few tracks are possible
    }

    return response;
};

const createNewBet = async (betInfo, setData, setPending) => {
    const raceInfo = {
        betslip: filterBetSlip(betInfo),
        betTable: get(betInfo, 'bet.betTable', null),
        raceDate: {
            time: betInfo.serverTime,
            date: betInfo.date,
        },
        product: {
            id: betInfo.product.id,
            name: betInfo.product.name,
        },
        isMultitrackProduct: betInfo.isMultitrackProduct,
        smartLynMode: get(betInfo, 'smartLynMode', false),
        strictMode: get(betInfo, 'strictMode', false),
        combinedTrackName: betInfo.combinedTrackName,
        trackCode: betInfo.trackCode,
        trackId: betInfo.trackId,
        race: {
            raceNumber: get(betInfo, 'race.raceNumber', null),
            index: get(betInfo, 'race.index', null),
            postTime: get(betInfo, 'race.postTime', null),
            postTimeUTC: get(betInfo, 'race.postTimeUTC', null),
            starts: filterStartsInfo(betInfo.race, betInfo.bet),
        },
        races: filterRacesInfo(betInfo),
        reserves: {
            [betInfo.date]: {
                [betInfo.trackCode]: {
                    [betInfo.product.id]: betInfo.reserves,
                },
            },
        },
        prices: {
            amount: betInfo.amount,
            sumPrice: betInfo.sumPrice,
        },
    };

    if (betInfo.isMultitrackProduct) {
        raceInfo.multitrackReserves = betInfo.multitrackReserves;
    }

    if (betInfo.smartLynMode) {
        raceInfo.lockedLegs = betInfo.lockedLegs;
    }

    /**
     * to identify user when he is not logged
     */
    const fingerprint = await Fingerprint2.getPromise({}).then(components => {
        let device = 'unknown';
        const values = components.map(component => {
            if (component.key === 'platform') device = component.value;
            return component.value;
        });
        const hash = Fingerprint2.x64hash128(values.join(''), 31);

        return { device, hash };
    });

    const params = {
        raceInfo: JSON.stringify(raceInfo),
        uSaleInfo: betInfo.uSaleInfo,
        amount: betInfo.amount,
        userId: get(betInfo, 'user.id', null),
        fingerprint: fingerprint.hash,
        device: fingerprint.device,
    };

    try {
        const response = await CMSApi.newMobileBet(params);
        const data = get(response, 'data', {});
        betInfo.id = data.id;
        betInfo.barcode_link = data.barcode_link;
    } catch (e) {
        console.log("Can't save the bet.", e);
        setPending(false);
    }

    setData(betInfo);
    setPending(false);
};

const filterRacesInfo = betInfo => {
    const { races, date, trackCode, product, betSlip } = betInfo;

    const filteredRaces = races.reduce((filteredRaces, race) => {
        //const raceTrackCode = isMultitrackProduct ? race.trackCode : trackCode;
        const betSlipInput = {
            date,
            trackCode,
            productId: product.id,
            raceIndex: race.index,
        };

        const combinationExist = betSlip.hasCombinations(betSlipInput);
        if (combinationExist) {
            const bet = new Bet(betSlip.getRaceSelection(betSlipInput));

            filteredRaces.push({
                index: race.index,
                raceNumber: race.raceNumber,
                hostTrack: race.hostTrack,
                trackCode,
                starts: filterStartsInfo(race, bet),
            });
        }

        return filteredRaces;
    }, []);

    return filteredRaces;
};

const filterStartsInfo = (race, bet) => {
    race = cloneDeep(race);

    const horses = race.horses.reduce((filteredStarts, horse) => {
        if (bet.isStartMarked(horse.startNr)) {
            filteredStarts.push({
                startNr: horse.startNr,
                horse: {
                    horseNameAndNationality: horse.horse.horseNameAndNationality,
                },
            });
        }

        return filteredStarts;
    }, []);

    return horses;
};

const getMobileBets = async (ids, userId, setData, setPending) => {
    if (ids.actual.length === 0 && ids.toRemove.length === 0) {
        setPending(false);

        return;
    }

    let data = [];
    try {
        const response = await CMSApi.getMobileBets({ ids, userId });
        if (response.success) {
            data = get(response, 'data', []);
        }
    } catch (e) {
        console.log("Can't get the bet-list", e);

        return;
    }

    console.log(data);

    const mergedBets = data.map(bet => {
        const mergedBet = {};
        mergedBet.id = bet.id;
        mergedBet.barcode_link = bet.barcode_link;

        const mobileBetData = saveJSONparsing(bet.race_info, {});

        mergedBet.sumPrice = get(mobileBetData, 'prices.sumPrice', null);
        mergedBet.serverTime = get(mobileBetData, 'raceDate.time', '');
        mergedBet.date = get(mobileBetData, 'raceDate.date', '');
        mergedBet.product = new Product().setAll(
            mobileBetData.product.id,
            mobileBetData.product.name
        );
        mergedBet.isMultitrackProduct = mobileBetData.isMultitrackProduct;
        mergedBet.combinedTrackName = mobileBetData.combinedTrackName;
        mergedBet.trackCode = mobileBetData.trackCode;

        mergedBet.race = new Race(mobileBetData.race);
        mergedBet.races = mobileBetData.races;

        mergedBet.bet = new Bet(mobileBetData.betTable);
        mergedBet.reserves = get(
            /** TODO replace  */
            mobileBetData,
            `reserves['${mergedBet.date}']['${mergedBet.trackCode}']['${mergedBet.product.id}']`,
            []
        );
        mergedBet.betSlip = new Betslip(mobileBetData.betslip);
        mergedBet.betSlip.smartLynMode = get(mobileBetData, 'smartLynMode', false);

        return mergedBet;
    });

    if (mergedBets.length > 0) {
        setData(mergedBets);
    }

    setPending(false);
};

const deleteMobileBet = async id => {
    try {
        const response = await CMSApi.deleteMobileBet({ id });
        return response;
    } catch (e) {
        console.log('deleteMobileBet', e);
    }
};

export { useData, createNewBet, getMobileBets, deleteMobileBet };
