import React, { useState, useRef, useEffect } from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import { formatDenmarkCurrency } from '../../utils/formatters'

const REQUIRED = 'required'
const ALPHABETIC = 'alphabetic'
const NO_SPECIAL = 'no-special'
const NUMERIC = 'numeric'
const EMAIL = 'email'
const MIN = 'min'
const MIN_TEXT = 'min-text'
const MININMUM_KR = 'minimum-kr'
const MIN_NUMBER = 'min-number'
const MAX = 'max'
const MAXIMUM_KR = 'maximum-kr'
const MAX_NUMBER = 'max-number'
const NO_SPACES = 'nospaces'
const PASSWORD = 'password'
const USERNAME = 'username'
const FULL_NAME = 'full-name'
const SAME_AS = 'sameas'

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const alphabeticRegex = /^[a-zA-Z æøåöÆØÅÖ]+$/
export const alphaNumeric = /^[0-9a-zA-ZæøåöÆØÅÖ ]*$/
export const numericRegex = /^\d+$/
export const hasNumber = /\d/gi
export const passwordRegex = /^(([a-z]?)(?=.*[A-Z])(?=.*[0-9]))(?=.{10,})/
export const userNameRegex = /^((?=.*[a-zA-Z]))(?=.{6,})/
export const fullNameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ '-]+$/
export const disallowedChars = /[^!&;%\\]/g
export const cutEmptyValues = (value) => value.replace(/\s/g, '')

/**
 * @param state
 * @param focus
 * @param theme {object}
 * @return {string}
 */
const getInputBorderColor = (state, focus, theme) => {
    let result = focus ? theme.colors.primaryAction : theme.colors.bgDarker
    if (state === 'error') {
        result = '#DB1C1B'
    } else if (state === 'success') {
        result = '#31D300'
    }
    return result
}

const BottomLabel = styled.p`
    margin: 10px 0 20px 8px;
    font-size: 12px;
    line-height: normal;
    color: ${(props) => props.theme.colors.primaryAction};
`
const InputWrapper = styled.div`
    margin: 44px auto 0;
    padding: 0;
    width: 100%;
    position: relative;
    border: none;
    outline: none;
    box-shadow: none;
    input {
        height: auto;
        width: 100%;
        display: block;
        box-sizing: border-box;
        padding: 17px 45px 17px 16px;
        border-radius: 5px;
        background: ${(props) =>
            props.theme.colors.bgGrey || props.theme.colors.bgDarker};
        position: relative;
        box-shadow: none;
        color: ${(props) => props.theme.colors.primary};
        opacity: 1;
        font-size: 16px;
        line-height: 16px;
        overflow: hidden;
        outline: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-family: ${(props) => props.theme.fontFamily};
        ${(props) =>
            `border: 1px solid ${getInputBorderColor(
                props.borderState,
                false,
                props.theme
            )}`};
        &:focus,
        &:hover {
            font-size: 16px;
            ${(props) =>
                ` border: 1px solid ${getInputBorderColor(
                    props.borderState,
                    true,
                    props.theme
                )}`};
            outline: none;
            box-shadow: none;
        }
        &:disabled {
            color: ${(props) => props.theme.colors.primaryAction};
            border: none;
        }
        &::placeholder {
            font-size: 16px;
            line-height: 16px;
            color: ${(props) => props.theme.colors.primary};
            outline: none;
            box-shadow: none;
        }
        &[type='password'] {
            font-family: ${(props) => props.theme.fontFamily};
            -webkit-text-security: disc;
            font-size: 16px;
            line-height: 16px;
        }
        &-webkit-autofill {
            -webkit-box-shadow: 0 0 0 1000px white inset !important;
        }
    }
`
const Title = styled.span`
    position: absolute;
    top: -20px;
    left: 8px;
    font-size: 14px;
    color: ${(props) => props.theme.colors.primary};
    line-height: normal;
`
const AltLabel = styled.div`
    position: absolute;
    right: 25px;
    top: 20px;
    cursor: ${({ cursor }) => cursor || 'auto'};
    color: ${(props) => props.theme.colors.primaryAction};
    font-size: 14px;
    line-height: normal;
`
const PasswordIndicator = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 6px;
    box-sizing: border-box;
`
const IndicatorElement = styled.div`
    background: ${(props) =>
        props.active ? props.theme.colors.active : props.theme.colors.bgDarker};
    width: 32%;
    height: 100%;
    border-radius: 3px;
`
const Error = styled.p`
    margin: 10px 0 20px 8px;
    font-size: 12px;
    line-height: normal;
    color: #db1c1b;
`
const InputButton = styled.button`
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.primaryAction};
    background: ${(props) => props.theme.colors.bgDarker};
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.theme.colors.basic};
    text-align: center;
    border-radius: 8px;
    outline: none;
    padding: 16px 0;
    margin-top: 13px;
    cursor: pointer;
    box-shadow: none;
    &:hover {
        background-color: #dee2ef;

        color: ${(props) =>
            props.theme.name === 'default'
                ? props.theme.colors.basic
                : props.theme.colors.bg};
    }
`
const InputExpander = styled.div`
    background: ${(props) => props.theme.colors.bgDarker};
    top: -4px;
    position: relative;
    border-width: 0 1px 1px;
    border-style: solid;
    ${(props) =>
        `border-color: ${getInputBorderColor(
            props.borderState,
            false,
            props.theme
        )}`};
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 0 16px;
`

const ValidatingInput = ({
    type = 'text',
    title,
    bottomLabel,
    altLabel,
    altLabelClickHandler = null,
    regex = '',
    placeholder = '',
    passwordStrength = null,
    onChangeHandler = () => {},
    onCheckValue = null,
    validate = '',
    validateOnChange = false,
    name = '',
    value = '',
    inputButton = null,
    inputButtonClickHandler = null,
    showInputButton = null,
    autoFocus = false,
    disabled = false,
    prefix = '',
    thousandSeparator = false,
    format = null,
    mask = '',
    onFocus = () => {},
    allowEmptyFormatting = false,
    allowFirstZero = true,
    min = '',
    max = '',
    minNumber = '',
    maxNumber = '',
    sameAs = '',
    setValid = '',
    numberPassword = false,
    pattern = '',
    styles = {
        InputWrapper: {},
    },
    errorMessage = '',
    forwardedRef = () => {},
    autoComplete = 'false', // must be string
    role,
    shouldRevalidateOnBlur = () => true,
    inputExpanderStates = [],
    inputExpanderContent = (borderState) => {},
}) => {
    const [isValid, setIsValid] = useState('')
    const [error, setError] = useState('')
    const inputRef = useRef(null)
    const inputCustomRef = useRef(null)

    const parseFirstName = (fullName) => {
        fullName = fullName.trim()
        const lastIndex = fullName.lastIndexOf(' ')
        return fullName.substring(0, lastIndex)
    }

    const parseLastName = (fullName) => {
        const split = fullName.trim().split(' ')
        return split[split.length - 1]
    }

    useEffect(() => {
        setTimeout(() => {
            if (autoFocus) {
                if (inputCustomRef.current) {
                    inputCustomRef.current.focus()
                } else if (inputRef.current) {
                    inputRef.current.focus()
                }
            }
        }, 500) // makes some delay for animation modal (otherwise autofocus brakes the animation)
        if (value) {
            validateValue(value)
        }
        if (errorMessage.length > 0) {
            setError(errorMessage)
        }
        forwardedRef(inputRef)
        setIsValid(setValid)
    }, [])

    useEffect(() => {
        setIsValid(setValid)
        setError(errorMessage)
    }, [setValid, errorMessage])

    const validateValue = (value) => {
        const error = validateInput(value)
        if (onCheckValue && typeof onCheckValue === 'function') {
            onCheckValue(error)
        }
    }

    useEffect(() => {
        validateOnChange && validateValue(value)
    }, [value])

    const validateInput = (value) => {
        let valid = ''
        if (value !== '') {
            valid = true
        }
        if (!regex) {
            if (validate.indexOf(PASSWORD) > -1 && !passwordRegex.test(value)) {
                valid = false
                setError(
                    'Kodeord skal minimum indeholde 1 stort bogstav, 1 tal og 10 tegn'
                )
            }
            if (
                validate.indexOf(PASSWORD) > -1 &&
                value.match(disallowedChars) &&
                value.match(disallowedChars).length !== value.length
            ) {
                valid = false
                setError('Kodeord må ikke indeholde ! & ; \\ %')
            }
            if (
                validate.indexOf(USERNAME) > -1 &&
                value.match(disallowedChars) &&
                value.match(disallowedChars).length !== value.length
            ) {
                valid = false
                setError('Brugernavn må ikke indeholde ! & ; \\ %')
            }
            if (
                validate.indexOf(ALPHABETIC) > -1 &&
                !alphabeticRegex.test(value)
            ) {
                valid = false
                setError('Feltet må kun indeholde bogstaver')
            }
            if (
                validate.indexOf(FULL_NAME) > -1 &&
                !fullNameRegex.test(value)
            ) {
                valid = false
                setError('Feltet må kun indeholde bogstaver')
            }

            if (validate.indexOf(FULL_NAME) > -1) {
                const fullName = value
                    .trim()
                    .split(' ')
                    .filter((s) => Boolean(s))

                const firstName = parseFirstName(value)
                const lastName = parseLastName(value)

                if (!fullNameRegex.test(value)) {
                    valid = false
                    setError('Feltet må kun indeholde bogstaver')
                }

                // first name or last name can contain "-",
                // but it's worth to check if it's not the first or last
                // character in the f / l name. Also checking the
                // f / l name length separately
                // TODO: rework to regex ^[a-zA-zæøåöÆØÅÖ-]{2,} ? [a-zA-zæøåöÆØÅÖ-]{2,} (no fully working)
                if (
                    fullName.length < 2 || // as array
                    fullName.length > 4 ||
                    firstName.length < 2 || // as string
                    lastName.length < 2 || // as string
                    firstName[0] === '-' ||
                    firstName[firstName.length - 1] === '-' ||
                    lastName[0] === '-' ||
                    lastName[lastName.length - 1] === '-'
                ) {
                    valid = false
                    setError('Har du husket dit efternavn?')
                }
            }

            if (validate.indexOf(NUMERIC) > -1 && !numericRegex.test(value)) {
                valid = false
                setError('Feltet bør kun indeholde cifre')
            }
            if (
                validate.indexOf(NO_SPECIAL) > -1 &&
                !alphaNumeric.test(value)
            ) {
                valid = false
                setError('Feltet bør kun indeholde bogstaver og cifre')
            }
            if (validate.indexOf(EMAIL) > -1 && !emailRegex.test(value)) {
                valid = false
                setError('Ugyldig email - har du husket @ og .dk eller .com?')
            }

            if (validate.indexOf(NO_SPACES) > -1 && !emailRegex.test(value)) {
                valid = false
                setError('Ugyldig værdi')
            }
            if (validate.indexOf(MIN) > -1 && min && value.length < min) {
                valid = false
                setError(`Skal bestå af ${min} tal`)
            }
            if (validate.indexOf(MIN_TEXT) > -1 && min && value.length < min) {
                valid = false
                setError(`Minimum ${min} bogstaver`)
            }
            if (validate.indexOf(MAX) > -1 && max && value.length > max) {
                valid = false
                setError(`Maximum ${max} tegn`)
            }
            if (validate.indexOf(MIN_NUMBER) > -1 && value < minNumber) {
                valid = false
                setError(`Minimum ${minNumber}`)
            }
            if (validate.indexOf(MAX_NUMBER) > -1 && value > maxNumber) {
                valid = false
                setError(`Maximum ${maxNumber}`)
            }
            if (validate.indexOf(MININMUM_KR) > -1 && value < minNumber) {
                valid = false
                setError(`Minimum ${formatDenmarkCurrency(minNumber, 0)} kr.`)
            }
            if (validate.indexOf(MAXIMUM_KR) > -1 && value > maxNumber) {
                valid = false
                setError(`Maximum ${formatDenmarkCurrency(maxNumber, 0)} kr.`)
            }
            if (validate.indexOf(REQUIRED) > -1 && value === '') {
                valid = false
                setError('Skal udfyldes')
            }
            if (valid) {
                setError('')
            }
            if (validate.indexOf(SAME_AS) > -1 && value !== sameAs) {
                valid = false
                setError(`Kodeordene er ikke ens, prøv venligst igen.`)
            }

            setIsValid(valid)
            return { name: name, value: !valid }
        }
    }

    const onBlurHandler = (val, e) => {
        if (shouldRevalidateOnBlur(e.relatedTarget)) {
            validateValue(val)
        }
    }

    const changeHandler = (value, event) => {
        let val = value

        if (!allowFirstZero) {
            if (/^0/.test(val)) {
                val = val.replace(/^0+/, '')
            }
        }
        if (pattern) {
            val = value.replace(pattern, '')
        }
        if (error) {
            validateValue(val, event)
        }
        onChangeHandler(val)
    }
    const onInputButtonClickHandler = () => {
        inputButtonClickHandler()
        inputRef.current && inputRef.current.focus && inputRef.current.focus()
    }

    const showInputExpander = (stateToShow = [], borderState = '') =>
        stateToShow.includes(borderState)

    const getInputByType = () => {
        if (type === 'number') {
            return (
                <NumberFormat
                    value={value}
                    prefix={prefix}
                    format={format}
                    onValueChange={(values, { event }) => {
                        changeHandler(values.value, event)
                    }}
                    onBlur={(e) => {
                        // for some reasons NumberFormat sometimes triggers double events with no event argument
                        if (e) {
                            onBlurHandler(value, e)
                        }
                    }}
                    placeholder={placeholder}
                    thousandSeparator={thousandSeparator}
                    allowEmptyFormatting={allowEmptyFormatting}
                    type="text"
                    mask={mask}
                    autoComplete={autoComplete}
                    role={role}
                    getInputRef={inputCustomRef} // use getInputRef in order to manage ref()
                    ref={inputRef}
                    onFocus={(e) => {
                        onFocus(e, validateValue)
                    }}
                />
            )
        } else {
            return (
                <input
                    disabled={disabled}
                    ref={inputRef}
                    value={value}
                    onChange={(e) => {
                        changeHandler(e.target.value, e)
                    }}
                    onBlur={(e) => {
                        onBlurHandler(e.target.value, e)
                    }}
                    autoComplete={autoComplete}
                    onFocus={(e) => {
                        onFocus(e, validateValue)
                    }}
                    role={role}
                    placeholder={placeholder}
                    type={type}
                />
            )
        }
    }
    const borderState = isValid === '' ? '' : isValid ? 'success' : 'error'
    return (
        <InputWrapper
            borderState={borderState}
            numberPassword={numberPassword}
            style={styles.InputWrapper}
        >
            {title && <Title>{title}</Title>}
            {getInputByType()}
            {showInputExpander(inputExpanderStates, borderState) && (
                <InputExpander borderState={borderState}>
                    {inputExpanderContent(borderState)}
                </InputExpander>
            )}
            {altLabel && (
                <AltLabel
                    cursor={
                        altLabelClickHandler &&
                        typeof altLabelClickHandler === 'function'
                            ? 'pointer'
                            : 'auto'
                    }
                    onClick={altLabelClickHandler}
                >
                    {altLabel}
                </AltLabel>
            )}
            {inputButton && showInputButton && (
                <InputButton onClick={onInputButtonClickHandler}>
                    {inputButton}
                </InputButton>
            )}
            {passwordStrength !== null ? (
                <PasswordIndicator>
                    {Array.from({ length: 3 }).map((_, index) => {
                        return (
                            <IndicatorElement
                                key={index}
                                active={
                                    passwordStrength > 0 &&
                                    index + 1 <= passwordStrength
                                }
                            />
                        )
                    })}
                </PasswordIndicator>
            ) : null}
            {error ? (
                <Error>{error}</Error>
            ) : (
                <BottomLabel>{bottomLabel}</BottomLabel>
            )}
        </InputWrapper>
    )
}

ValidatingInput.propTypes = {
    type: propTypes.string,
    placeholder: propTypes.string,
    title: propTypes.string,
    bottomLabel: propTypes.any,
    altLabel: propTypes.string,
    altLabelClickHandler: propTypes.func,
    regex: propTypes.any,
    isValid: propTypes.bool,
    passwordStrength: propTypes.number,
    onChangeHandler: propTypes.func,
    onCheckValue: propTypes.func,
    validate: propTypes.string,
    setValid: propTypes.bool,
    name: propTypes.string,
    value: propTypes.any,
    prefix: propTypes.any,
    format: propTypes.any,
    mask: propTypes.any,
    thousandSeparator: propTypes.bool,
    autoFocus: propTypes.bool,
    allowEmptyFormatting: propTypes.bool,
    allowFirstZero: propTypes.bool,
    disabled: propTypes.bool,
    min: propTypes.number,
    max: propTypes.number,
    minNumber: propTypes.any,
    maxNumber: propTypes.any,
    pattern: propTypes.any,
    errorMessage: propTypes.string,
    numberBlurRef: propTypes.func,
    inputExpanderStates: propTypes.arrayOf(
        propTypes.oneOf(['success', 'error', ' '])
    ),
    inputExpanderContent: propTypes.func,
}

export default ValidatingInput
