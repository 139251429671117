import React from 'react';
import { Card, CardContent, CardHeader } from 'ui/Card';
import { Col, Container, Row } from 'react-grid-system';
import { Timer } from 'features/BetSlip2/styled';
import Countdown from 'ui/Countdown';
import Icon from 'ui/Icon';
import SubHeader from 'features/BetSlip2/components/SubHeader';
import BetPick from 'features/BetSlip2/components/BetPick';
import Hr from 'ui/Hr';
import { COLORS } from 'themes';
import { SumTextContainer, SumText } from './styled';
import getTexts from 'utils/localization';

const t = getTexts();

const SelectionCard = props => {
    const {
        raceDay,
        combinedTrackName,
        race,
        serverTime,
        date,
        sumPrice,
        reserves,
        trackCode,
        product,
        isVProduct,
        races,
        bet,
        betSlip,
        isMultitrackProduct,
    } = props;

    return !product || !race ? null : (
        <Card
            style={{
                width: '90%',
                marginTop: '0px',
                boxShadow: '0 0 30px rgba(0, 0, 0, 0.3)',
            }}
        >
            <CardHeader>
                <Container>
                    <Row>
                        <Col xs={6} style={{ paddingRight: 0, paddingLeft: '5px' }}>
                            <Icon itrack size="x05" color="white" style={{ display: 'inline' }} />{' '}
                            <span className="text-bold uppercase">
                                {!isVProduct && `${t.betSlip.track} ${race.raceNumber}, `}{' '}
                                {combinedTrackName}
                            </span>{' '}
                            <span style={{ marginLeft: '20px' }} className="text-bold uppercase">
                                {product.name}
                            </span>
                            {betSlip.smartLynMode && (
                                <span
                                    style={{ marginLeft: '20px' }}
                                    className="text-bold uppercase"
                                >
                                    Smart Lyn
                                </span>
                            )}
                        </Col>
                        <Col xs={6} align="right" style={{ paddingLeft: 0, paddingRight: '5px' }}>
                            <Icon
                                itime
                                color="white"
                                size="x05"
                                style={{
                                    verticalAlign: 'sub',
                                    marginRight: '5px',
                                    display: 'inline',
                                }}
                            />{' '}
                            <Timer>
                                <Countdown
                                    alwaysDisplayStartTime={true}
                                    time={date + ' ' + race.postTime}
                                    timeUTC={date + ' ' + race.postTimeUTC}
                                    serverTime={serverTime}
                                    withDay
                                />
                            </Timer>
                            <br />
                            <span className="text-bold uppercase">{date}</span>
                        </Col>
                    </Row>
                </Container>
            </CardHeader>
            <SubHeader product={product} isVProduct={isVProduct} />
            <CardContent style={{ background: COLORS.white, padding: '0px 15px' }}>
                <BetPick
                    raceDay={raceDay}
                    product={product}
                    isVProduct={isVProduct}
                    races={races}
                    race={race}
                    singleRaceBetPicks={bet}
                    betSlip={betSlip}
                    date={date}
                    trackCode={trackCode}
                    isMultitrackProduct={isMultitrackProduct}
                    reserves={reserves}
                />
                <Hr
                    style={{
                        padding: '0px 10px 10px',
                        boxSizing: 'border-box',
                    }}
                />
                <SumTextContainer>
                    <SumText>Indsats:</SumText>
                    <SumText style={{ textAlign: 'right' }}>{sumPrice} KR.</SumText>
                </SumTextContainer>
            </CardContent>
        </Card>
    );
};

export default SelectionCard;
