import styled, { css } from 'styled-components';
import { FilledWrapper } from 'ui/Wrapper';
import { boxShadowMixin } from 'ui/Card';
import { COLORS, BACKGROUNDS } from 'themes';
import { pagePadding } from 'features/TrackPage/components/styled';
import { typographyCSS, TypographyProps } from 'ui/typography';
import { PRODUCT_IDS } from 'configs/products';
import { kombNrCol } from '../Desktop/styled';
import { HorseInfoRowElementProps, nrColVisibilityLogic } from '../common/styled';

const borderRadius = '5px';

export const SlickCustomizations = styled.div`
    .slick-list {
        box-sizing: initial;
        overflow: visible;
    }

    .slick-list {
        width: calc(100% + ${pagePadding});
    }
`;

export const RaceCardWrapper = styled(FilledWrapper)`
    position: relative;
    z-index: 15;
    padding: 0;
    border-radius: ${borderRadius};
    margin-right: ${pagePadding}; // slick fix
    background: ${COLORS.white};
    ${boxShadowMixin};
`;

export const RaceCardHeaderContainer = styled.div`
    color: ${COLORS.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    background: ${BACKGROUNDS.primaryFlat};
    padding: 0 10px 0 23px;
    height: 50px;
    box-sizing: border-box;
    border-top-left-radius: ${borderRadius};
    border-top-right-radius: ${borderRadius};
`;

export const HeaderButton = styled.button<{ active?: boolean }>`
    color: #111;
    border-radius: 5px;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    border: 0;
    font-size: 12px;
    font-weight: bold;
    transition: background 200ms linear;
    background: ${props => (props.active ? '#FED300' : '#F5F7FC')};
`;

const getGridColumns = ({ isVStrictProduct, productId, hideNrCol }: HorseInfoRowElementProps) => {
    const nrColMap = {
        [PRODUCT_IDS.T]: '160px',
        [PRODUCT_IDS.TV]: '110px',
        [PRODUCT_IDS.K]: kombNrCol,
    };
    const oddsColMap = { [PRODUCT_IDS.T]: '60px' };

    const nrCol = hideNrCol ? '' : nrColMap[productId] ?? '60px';
    const oddsCol = oddsColMap[productId] ?? '80px';

    return isVStrictProduct ? `${nrCol} 1fr 40px 60px` : `${nrCol} 1fr ${oddsCol}`;
};

const tableGrid = css<HorseInfoRowElementProps>`
    display: grid;
    align-items: center;
    column-gap: 5px;
    grid-template-columns: ${getGridColumns};
    ${nrColVisibilityLogic};
`;

interface TableHeaderProps {
    productHasSelectColumnFunctionality?: boolean;
}

export const TableHeader = styled.div<HorseInfoRowElementProps & TableHeaderProps>`
    ${tableGrid};
    grid-template-rows: 20px; // header row height
    border-bottom: 1px solid #eceff6;
    border-top: ${props => (props.productHasSelectColumnFunctionality ? '1px solid #eceff6' : '0')};
`;

export const TableRow = styled.div<HorseInfoRowElementProps>`
    ${tableGrid};
    grid-template-rows: 50px; // rest rows height

    &:nth-of-type(even) {
        background: #ebeff6;
    }

    &:last-of-type {
        border-bottom-left-radius: ${borderRadius};
        border-bottom-right-radius: ${borderRadius};
    }
`;

const tableColCSS = css<TypographyProps>`
    ${typographyCSS};
`;

export const TableHeaderCol = styled.div<TypographyProps>`
    ${tableColCSS};
    font-size: 12px;
    font-weight: 900;
`;
export const TableCol = styled.div<TypographyProps>`
    ${tableColCSS};
    font-size: 16px;
`;

export const JackpotRow = styled.div`
    padding: 5px 7px;
    background: ${BACKGROUNDS.accentAlt};
    font-weight: 900;
    height: 40px;
    color: ${COLORS.black};
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
