export interface CouponDraft {
    couponId: number;
    externalCouponId: number;
    poolId: number;
    externalPoolId: number;
    combinationNumber: number;
    combinationPrice: number;
    couponAmount: number;
    comment?: string;
    strictBet: boolean;
    quickPick: boolean;
    smartLyn: boolean;
    maxTotalBetAmount: number;
    betPlaced: boolean;
    legs: Leg[];
    winAmount: number;
    userWinAmount: number;
}

export interface Leg {
    legNumber: number;
    marks: string;
    firstReserve?: number;
    secondReserve?: number;
    open: boolean;
    trackId: number;
    trackCode: string;
    raceNumber: number;
}

export const anyBetPlaced = (coupons: CouponDraft[]) => coupons.some(coupon => coupon.betPlaced);

export const anyBetNotPlaced = (coupons: CouponDraft[]) => !anyBetPlaced(coupons);

export const createNullCouponDraft = (): CouponDraft => ({
    couponId: 0,
    externalCouponId: 0,
    poolId: 0,
    externalPoolId: 0,
    combinationNumber: 0,
    combinationPrice: 0,
    couponAmount: 0,
    strictBet: false,
    quickPick: false,
    smartLyn: false,
    maxTotalBetAmount: 0,
    betPlaced: false,
    legs: [],
    winAmount: 0,
    userWinAmount: 0,
});

export default CouponDraft;
