import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { byPlatform } from 'utils/platforms';
import { connect } from 'react-redux';
import { Rnd } from 'react-rnd';
import debounce from 'lodash/debounce';
import { isIOS, isChrome } from 'react-device-detect';

import { hideStream, startStopCast } from './actions';
import persistentStorage from 'common/storage';
import { FloatingStream, CloseStream, FixedVideoContainer } from './uiComponents';
import StreamType from 'features/StreamVideo/StreamType';
import {
    STREAM_MODE_ATG,
    STREAM_MODE_FT,
    STREAM_MODE_FTP,
    STREAM_MODE_FR,
    STREAM_MODE_SA,
    STREAM_MODE_UK,
    src,
} from 'features/StreamVideo/config';
import { priority } from 'configs/layout';
import { inIframe } from 'utils/DOM.ts';
import HLSPlayer from './HlsPlayer';
import { getCountry } from 'common/selectors/raceDaySelector';
import { trackStatPolling } from 'features/StreamVideo/trackStatistics';
import CastButton from './CastButton';
import { trackEvent, trackingIDs } from 'utils/tracking';

let streamDefaultPosition = { x: 100, y: 100 };
let streamDefaultSize = { width: 333, height: 188 };

class StreamVideo extends Component {
    constructor(props) {
        super(props);
        this.ref = createRef();
    }

    state = {
        streamType: STREAM_MODE_FT,
        containerMounted: false,
        initialTrackCountryLoaded: false,
    };

    selectStreamingType = type => {
        const trackingAttributes = trackingIDs.livestreamFloatingWidget[type];
        const { id: event, action } = trackingAttributes;

        trackEvent({ event, action, category: 'stream' });
        this.setState({ streamType: type });
    };

    getStreamTypes = () => ({
        [STREAM_MODE_FT]: {
            title: 'Bet25 LIVE',
            id: 'derby25-live',
            streamTypeSetter: () => this.selectStreamingType(STREAM_MODE_FT),
            src: src.STREAM_MODE_FT,
            hls: true,
        },
        [STREAM_MODE_FTP]: {
            title: 'Bet25 LIVE+',
            id: 'derby25-live',
            streamTypeSetter: () => this.selectStreamingType(STREAM_MODE_FTP),
            src: src.STREAM_MODE_FTP,
            hls: true,
        },
        [STREAM_MODE_ATG]: {
            title: 'ATG Nordic',
            streamTypeSetter: () => this.selectStreamingType(STREAM_MODE_ATG),
            src: src.STREAM_MODE_ATG,
            hls: true,
        },
        [STREAM_MODE_FR]: {
            title: 'Frankrig',
            streamTypeSetter: () => this.selectStreamingType(STREAM_MODE_FR),
            src: src.STREAM_MODE_FR,
            hls: true,
        },
        [STREAM_MODE_SA]: {
            title: 'Sydafrika',
            streamTypeSetter: () => this.selectStreamingType(STREAM_MODE_SA),
            src: src.STREAM_MODE_SA,
            hls: true,
        },
        [STREAM_MODE_UK]: {
            title: 'Storbritannien',
            streamTypeSetter: () => this.selectStreamingType(STREAM_MODE_UK),
            src: src.STREAM_MODE_UK,
            hls: true,
        },
    });

    componentDidMount() {
        streamDefaultPosition = this.getDefaultPosition();
        streamDefaultSize = this.getDefaultSize();
    }

    componentDidUpdate(prevProps) {
        const inlinePlayer = document.getElementById('inline-live-stream');
        const { userId } = this.props;
        const { streamType } = this.state;

        if (!prevProps.isFloatingStreamShown && this.props.isFloatingStreamShown) {
            setTimeout(() => {
                this.setState({
                    containerMounted: this.props.isFloatingStreamShown,
                    streamType: STREAM_MODE_FT,
                });

                if (streamType === STREAM_MODE_FT) {
                    // start sending Derby LIVE stats
                    trackStatPolling(userId);
                }
            }, 50);
        }

        if (
            !prevProps.liveStreamPageMounted &&
            this.props.liveStreamPageMounted &&
            this.props.floatingMode
        ) {
            inlinePlayer && this.runTransitionToStreamPagePlayer(inlinePlayer);
        }
    }

    shouldComponentUpdate(nextProps) {
        if (
            this.props.countryCode &&
            nextProps.countryCode &&
            this.props.countryCode !== nextProps.countryCode
        ) {
            return false;
        }
        return true;
    }

    runTransitionToStreamPagePlayer = inlinePlayer => {
        if (!this.ref.current) {
            return;
        }
        const { top, left } = inlinePlayer.getBoundingClientRect();
        this.ref.current.style.transition = 'all 500ms ease';
        this.ref.current.parentElement.style.transition = 'all 500ms ease';
        this.ref.current.parentElement.style.transform = `translate(${left}px, ${top}px)`;
        this.ref.current.style.width = '580px';
        this.ref.current.style.height = '326px';
        this.ref.current.parentElement.style.opacity = '0';

        setTimeout(this.close, 1000);
    };

    handleDrag = (e, data) => {
        persistentStorage.save({
            streamPosition: { x: data.lastX, y: data.lastY },
        });
    };

    handleResize = debounce((e, direction, ref, delta, position) => {
        persistentStorage.save({
            streamPosition: position,
            streamSize: {
                width: ref.offsetWidth,
                height: ref.offsetHeight,
            },
        });
    }, 1000);

    getDefaultPosition = () => {
        return persistentStorage.get('streamPosition') || streamDefaultPosition;
    };

    getDefaultSize = () => {
        return persistentStorage.get('streamSize') || streamDefaultSize;
    };

    close = () => {
        this.setState({ containerMounted: false }, () => {
            setTimeout(this.props.hideStream, 500);
        });
    };

    trackPlayAction = () => {
        trackEvent({ event: trackingIDs.livestreamPlayPause, action: 'play', category: 'stream' });
    };

    trackPauseAction = () => {
        trackEvent({ event: trackingIDs.livestreamPlayPause, action: 'pause', category: 'stream' });
    };

    renderVideoContent = props => {
        const {
            borderRadius = '5px',
            WrapperComponent = FloatingStream,
            autoplay,
            hlsPlayerRef,
            floatingMode,
        } = props;

        if (hlsPlayerRef?.current) {
            hlsPlayerRef.current.playbackRate = 1;
        }

        const streamTypes = this.getStreamTypes();

        const streamTypeAttributes = streamTypes[this.state.streamType];

        const streamTitle = streamTypeAttributes.title;

        const src = streamTypeAttributes.src;

        return (
            <WrapperComponent
                height="100%"
                width="100%"
                borderRadius={borderRadius}
                ref={this.ref}
                {...this.props}
            >
                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '5px',
                        boxSizing: 'border-box',
                        background: 'white',
                        borderRadius: borderRadius,
                        height: 'auto',
                    }}
                >
                    {floatingMode && <CloseStream iclosewhite onClick={this.close} />}

                    {isIOS ? (
                        <video
                            key={src}
                            width="100%"
                            height="100%"
                            style={{
                                borderRadius: borderRadius,
                                // 66px is a computed height of StreamType component
                                height: 'calc(100% - 66px)',
                                background: 'black',
                            }}
                            controls
                            autoPlay={autoplay}
                        >
                            <source src={src} type="application/x-mpegURL" />
                        </video>
                    ) : (
                        <>
                            <HLSPlayer
                                src={src}
                                autoPlay={autoplay}
                                controls={true}
                                width="100%"
                                height="100%"
                                style={{
                                    borderRadius: borderRadius,
                                    // 66px is a computed height of StreamType component
                                    height: 'calc(100% - 66px)',
                                    background: 'black',
                                }}
                                hlsConfig={{
                                    lowLatencyMode: false,
                                    backBufferLength: 90,
                                    debug: false,
                                    liveDurationInfinity: true,
                                    enableWorker: true,
                                    maxBufferSize: 20 * 1000 * 1000, // set buffer size to 20MB
                                    maxBufferLength: 30, // set buffer length to 30s
                                }}
                                playerRef={hlsPlayerRef}
                                onPlay={this.trackPlayAction}
                                onPause={this.trackPauseAction}
                            />
                            {isChrome && (
                                <CastButton
                                    src={src}
                                    title={streamTitle}
                                    startStopCast={this.props.startStopCast}
                                />
                            )}
                        </>
                    )}

                    <StreamType
                        streamTypes={streamTypes}
                        selectedStreamType={this.state.streamType}
                        borderRadius={borderRadius}
                    />
                </div>
            </WrapperComponent>
        );
    };

    preventSafariContentSelection = e => {
        e.preventDefault();
    };

    renderDraggableVideoContent = props => {
        const defaultPosition = this.getDefaultPosition();
        defaultPosition.y = window.scrollY + defaultPosition.y;

        return (
            <Rnd
                default={{
                    ...defaultPosition,
                    ...this.getDefaultSize(),
                }}
                style={{ position: 'fixed', zIndex: priority.DGABar + 1 }}
                minWidth={362}
                minHeight={279}
                bounds="body"
                handle=".handle"
                onDragStop={this.handleDrag}
                onResize={this.handleResize}
                onResizeStart={this.preventSafariContentSelection}
            >
                {this.renderVideoContent(props)}
            </Rnd>
        );
    };

    renderFixedVideoContent = props => {
        return <FixedVideoContainer>{this.renderVideoContent(props)}</FixedVideoContainer>;
    };

    render() {
        if (this.props.inline) {
            return this.renderVideoContent(this.props);
        }

        return this.state.containerMounted && !inIframe()
            ? byPlatform(
                  <this.renderFixedVideoContent {...this.props} />,
                  <this.renderDraggableVideoContent {...this.props} />
              )
            : null;
    }
}

StreamVideo.propTypes = {
    isFloatingStreamShown: PropTypes.bool,
};

StreamVideo.defaultProps = {
    isFloatingStreamShown: true,
};

const mapStateToProps = state => {
    return {
        isFloatingStreamShown: state.StreamVideo.isFloatingStreamShown,
        countryCode: getCountry(state),
        userId: state.auth?.user?.id,
        autoplay: state.StreamVideo.autoplay,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        hideStream: () => {
            dispatch(hideStream());
        },
        startStopCast: (isConnected = false) => {
            dispatch(startStopCast(isConnected));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StreamVideo);
