import React from 'react';
import { DOMAIN_PATH } from 'configs/main';
import CMSApi from 'common/api/CMSAPI';

import getTexts from 'utils/localization';
import { byPlatform } from 'utils/platforms';
import { trackEvent, trackingIDs } from 'utils/tracking';

import icon from 'images/icons/downloadpdf.svg';
import {
    DownloadButton,
    DownloadButtonIcon,
    DownloadButtonLabel,
} from 'features/RaceCard/PdfDownload/styled';
import PdfDownloadMobileView from './PdfDownloadMobileView';
const t = getTexts();

class CMSPdf extends React.Component {
    _isMounted = false;

    constructor() {
        super();

        this.state = {
            fileAvailable: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        let { trackCode } = this.props.getSelectedDayData(this.props.AISDataProvider);

        let oldData = this.props.getSelectedDayData(prevProps.AISDataProvider);

        if (trackCode !== oldData.trackCode) {
            this.checkFileAvailability();
        }
    }

    componentDidMount() {
        this._isMounted = true;

        this.checkFileAvailability();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    checkFileAvailability() {
        /* before showing button, make request to ensure file is available */
        let { trackCode, date } = this.props.getSelectedDayData(this.props.AISDataProvider);
        CMSApi.checkPdfAvailability({
            trackCode,
            date,
            test: true,
            type: this.props.type,
        })
            .then(res => {
                if (res && res.success) {
                    if (this._isMounted) {
                        this.setState({
                            fileAvailable: true,
                        });
                    }
                }
            })
            .catch(() => {
                console.log('pdf checkPdfAvailability error');
            });
    }

    downloadPdf = () => {
        let AISDataProvider = this.props.AISDataProvider;
        let { trackCode, date } = this.props.getSelectedDayData(AISDataProvider);

        trackEvent({
            event: trackingIDs.trackProgramDownload,
            category: 'ux',
            action: this.getTranslatedButtonText(),
        });

        window.open(
            `${DOMAIN_PATH}/derby/rest/common/download-pdf?type=${this.props.type}&date=${date}&trackCode=${trackCode}`,
            '_self'
        );
    };

    getTranslatedButtonText = () => {
        let text = 'Download',
            downloadType = this.props.type;

        if (downloadType === 'play-list') {
            text = t.PdfDownload.playList;
        }

        if (downloadType === 'channel-overview') {
            text = t.PdfDownload.channelOverview;
        }

        if (typeof t.PdfDownload[downloadType] !== 'undefined') {
            text = t.PdfDownload[downloadType];
        }

        return text;
    };

    render() {
        return this.state.fileAvailable
            ? byPlatform(
                  this.props.type === 'tips' ? (
                      <PdfDownloadMobileView
                          action={this.downloadPdf}
                          title={this.getTranslatedButtonText()}
                          description={t.PdfDownload.tipsDesc}
                      />
                  ) : null,
                  <DownloadButton onClick={this.downloadPdf}>
                      <DownloadButtonLabel>{this.getTranslatedButtonText()}</DownloadButtonLabel>
                      <DownloadButtonIcon>
                          <img src={icon} alt="Download PDF" />
                      </DownloadButtonIcon>
                  </DownloadButton>
              )
            : null;
    }
}

export default CMSPdf;
