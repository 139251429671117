import { useSelector } from 'react-redux';

import CouponFactory from 'features/BetSlip2/BettingREST/DataObjects/CouponFactory';
import { combinationsCountBy } from 'common/selectors/combinationsCountSelector';
import { PRODUCT_IDS } from 'configs/products';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import { findMultipleTrackSetup, isMultitrackProduct } from 'features/EventBoard/server/calendar';

import Track from 'common/DataObjects/Track';
import useBetPicks from './useBetPicks';

const useCoupon = () => {
    const { raceDay, productId, trackCode, race, races, racesFetched } = useTrackPage();

    const trackDTO = Track.unserialize(raceDay.trackId, raceDay.track);
    // CouponFactory uses race numbers and starts only
    const shortenedRaces = races.map(race => ({
        raceNumber: race.raceNumber,
        starts: race.horses.map(horse => ({ startNr: horse.startNr })),
    }));

    // CouponFactory uses start numbers only
    const shortenedStarts = racesFetched
        ? race.horses.map(horse => ({ startNr: horse.startNr }))
        : [];

    const { singleRaceBetPicks, racePicksMap, raceReservesMap } = useBetPicks({
        raceDay,
        productId,
        trackCode,
        race,
    });

    const combinationsCount = useSelector(state =>
        combinationsCountBy(state, {
            productId,
            trackCode,
            date: raceDay.raceDayDate,
            raceIndex: race.index,
            races,
        })
    );

    const strictMode = useSelector((state: any) => {
        return productId === PRODUCT_IDS.V64 ||
            productId === PRODUCT_IDS.V65 ||
            productId === PRODUCT_IDS.GS75
            ? state.BetSlip.strictMode
            : false;
    });

    const isMultitrack = isMultitrackProduct(raceDay, productId);

    return new CouponFactory({
        programNumber: raceDay.programNumber,
        date: raceDay.raceDayDate,
        productId,
        track: trackDTO,
        raceNumber: race.raceNumber,
        combinationNumber: combinationsCount,
        bet: singleRaceBetPicks,
        starts: shortenedStarts,
        racePicks: racePicksMap,
        races: shortenedRaces,
        strictMode,
        isMultitrack,
        multipleTrackSetups: isMultitrack ? findMultipleTrackSetup(raceDay, productId) : null,
        reserves: raceReservesMap,
    });
};

export default useCoupon;
