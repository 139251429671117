import styled from 'styled-components';
import ipin from 'icons/pin-login.png';
import iprofile from 'icons/profile.png';
import itransact from 'icons/transactions.png';
import iselfexcl from 'icons/self-exclusion.png';
import ilimits from 'icons/deposit-limit.png';
import isubscr from 'icons/subscriptions.png';
import ilogout from 'icons/logout.png';
import ideposit from 'icons/deposit.png';
import ipayout from 'icons/payout.png';
import icontactus from 'images/dk/burger-menu/kontakt_os.png';
import iresulterning from 'images/dk/burger-menu/resultering.png';
import itips from 'images/dk/burger-menu/tips_forslag.png';
import iresponsiblegame from 'images/dk/burger-menu/ansvarligt_spil.png';
import iterms from 'images/dk/burger-menu/regler_vilkare.png';
import iabout from 'images/dk/burger-menu/om_derby25.png';
import ireportus from 'images/dk/burger-menu/anmeld_os.png';
import ifaq from 'images/dk/burger-menu/FAQ.png';
import iclose from 'icons/edit.png';
import iclose3 from 'icons/close.3.svg';
import iclosewhite from 'icons/edit_white.png';
import ipiggybank from 'icons/piggybank.png';
import itrack from 'icons/track.png';
import ipdf from 'icons/pdf.png';
import itime from 'icons/starts_in.png';
import play from 'icons/play.png';
import replay from 'icons/gense.png';
import ivoucher from 'icons/voucher.png';
import ishoe from 'icons/shoe.svg';
import iteam from 'icons/team.svg';
import istar from 'icons/star.svg';
import ichat from 'icons/chat.svg';
import imail from 'icons/mail.svg';
import ishare from 'icons/copy-link.svg';
import ispilklub from 'icons/spilklub.svg';
import ispilklub2 from 'icons/spilklub2.svg';
import ilyn from 'icons/lyn.svg';
import itrash from 'icons/trash.svg';
import icloudfilled from 'icons/cloud-filled.svg';
import ifailcross from 'icons/fail-cross.svg';
import icheckcolorful from 'images/icons/check-colorful.svg';
import iwarncolorful from 'images/icons/warn-colorful.svg';
import ierrorcolorful from 'images/icons/error-colorful.svg';
import idownload from 'images/icons/download.svg';
import iarrowsmooth from 'images/icons/arrow-smooth.svg';
import iretail from 'images/icons/shop.svg';

const icons = {
    ipin,
    iprofile,
    itransact,
    iselfexcl,
    ilimits,
    isubscr,
    ilogout,
    ideposit,
    ipayout,
    icontactus,
    iresulterning,
    itips,
    iresponsiblegame,
    iterms,
    iabout,
    ireportus,
    ifaq,
    iclose,
    iclose3,
    iclosewhite,
    ipiggybank,
    itrack,
    ipdf,
    itime,
    play,
    replay,
    ivoucher,
    ishoe,
    iteam,
    istar,
    ichat,
    imail,
    ishare,
    ispilklub,
    ispilklub2,
    ilyn,
    itrash,
    icloudfilled,
    ifailcross,
    icheckcolorful,
    iwarncolorful,
    ierrorcolorful,
    idownload,
    iarrowsmooth,
    iretail,
};

const sizes = {
    x025: 12,
    x05: 16,
    x07: 20,
    x1: 24,
    x15: 27,
    x2: 32,
    x3: 48,
    x4: 72,
    x5: 128,
    x6: 192,
    x7: 256,
};

const getSize = props => {
    let dim = sizes.x1;

    if (isNaN(props.size)) {
        if (props.size && sizes[props.size]) {
            dim = sizes[props.size];
        }
    } else {
        dim = props.size;
    }

    return `
        width: ${dim}px;
        height: ${dim}px;
    `;
};

const getColor = props => {
    switch (props.color) {
        case 'black':
            return 'brightness(0%)';
        case 'gray':
        case 'grey':
            return 'grayscale(100%) brightness(110%) contrast(0.3)';
        case 'white':
            return 'brightness(0) invert(1)';
        case 'red':
            return 'grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8)';
        case 'blue':
            return 'grayscale(100%) brightness(30%) sepia(100%) hue-rotate(-180deg) saturate(700%) contrast(0.8)';
        case 'lightBlue':
            return 'invert(45%) sepia(56%) saturate(690%) hue-rotate(182deg) brightness(97%) contrast(103%)';
        case 'green':
            return 'grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8)';
        case 'yellow':
            return 'grayscale(100%) brightness(120%) sepia(90%) hue-rotate(5deg) saturate(500%) contrast(0.7)';
        default:
            return 'none';
    }
};

const getSrc = props => {
    const predefinedIcons = Object.keys(icons).filter(i => {
        return props.hasOwnProperty(i);
    });

    return predefinedIcons.length > 0 ? icons[predefinedIcons[0]] : props.src;
};

const Icon = styled.img.attrs(props => ({
    src: getSrc(props),
}))`
    margin: ${props => (props.margin ? props.margin : '0px')};
    padding: 0px;
    vertical-align: middle;
    ${props => getSize(props)};
    filter: ${getColor};
    transform: rotate(${props => (props.rotation ? `${props.rotation}deg` : 'none')});
`;

export default Icon;
