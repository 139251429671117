import React, { useMemo } from 'react';
import get from 'lodash/get';

import * as Styled from './Shortcuts.styled';

import useCalendarQuery from 'features/EventBoard/hooks/useCalendarQuery';
import { Shortcut } from 'features/EventBoard/server/calendar';
import { toServerDate, today } from 'utils/date';
import { createProductShortcuts } from 'features/EventBoard/components/Shortcuts/helpers';

import ShortcutsPreloader from 'features/EventBoard/components/Shortcuts/ShortcutsPreloader';
import ShortcutButton from 'features/EventBoard/components/Shortcuts/ShortcutButton';
import useShortcutState from 'features/EventBoard/hooks/useShortcutState';

import { NEW_TRACK_PAGE } from 'configs/main';
import { generateEventPath, history } from 'utils/navigation';
import { trackEvent, trackingIDs } from 'utils/tracking';

export const DATE_FORMAT = 'ddd DD[.]MM';

const Shortcuts = () => {
    const { shortcut, setShortcut } = useShortcutState();

    const date = get(shortcut, 'raceDate', toServerDate(today()));

    const { data, isSuccess, isLoading } = useCalendarQuery({ date });

    const productShortcuts = useMemo(
        () => (isSuccess ? createProductShortcuts(data?.shortCuts) : []),
        [data, isSuccess]
    );

    const navigateToEvent = (shortcut: Shortcut) => {
        trackEvent({
            event: trackingIDs.homepage.shortcuts,
            action: shortcut.product,
            category: 'shortcuts',
        });

        if (NEW_TRACK_PAGE) {
            return history.push(
                generateEventPath({
                    date: shortcut.raceDate,
                    productId: shortcut.product,
                    track: shortcut.trackName,
                    race: 1,
                })
            );
        }

        setShortcut(shortcut);
    };

    return (
        <Styled.ShortcutsContainer>
            <Styled.ShortcutsTitle>V-spil genveje</Styled.ShortcutsTitle>

            <Styled.CardsContainer>
                {!isLoading ? (
                    productShortcuts.map((shortcut, key) => (
                        <ShortcutButton
                            key={key}
                            product={shortcut}
                            shortcutsNumber={productShortcuts.length}
                            onClick={() => navigateToEvent(shortcut)}
                        />
                    ))
                ) : (
                    /** @ts-ignore **/
                    <ShortcutsPreloader />
                )}
            </Styled.CardsContainer>
        </Styled.ShortcutsContainer>
    );
};

export default Shortcuts;
