import React, { Component, Fragment } from 'react';
import TopBar from 'ui/topbar';
import TopBarText from 'ui/topbar/Text';
import styled from 'styled-components';
import { ContentHeight, Fillable, FullScreen } from 'ui/FullScreen';
import Text from 'ui/Text';
import userConductor from 'common/conductors/UserConductor';
import Spinner from 'ui/Spinner';
import LimitForm from './LimitForm';
import LimitTable from './LimitTable';
import Button from 'ui/controls/Button';
import DepositLimitDTO from './DepositLimitDTO';
import { withRouter } from 'react-router-dom';
import notification from 'common/conductors/NotificationConductor';

import getTexts from 'utils/localization';
import { isMobile } from 'utils/platforms';
const t = getTexts();
const borderRadius = '15px';

const Container = styled(Fillable)`
    padding: 25px;
`;

//@TODO Replace hardcoded size 64px to value from Spinner component
const CenteredSpinner = styled(Spinner)`
    position: fixed;
    top: calc(50% + 64px / 2);
    left: calc(50% - 64px / 2);
`;

const Title = styled(Button)`
    text-align: left;
    border-radius: ${borderRadius};
    padding: 15px;
    font-size: 16px;
    margin: 70px 5px 0 5px;
    display: block;
    width: calc(100% - 10px);
`;

class DepositLimits extends Component {
    constructor() {
        super();
        /**
         * @property {DepositLimitDTO} limits
         */
        this.state = {
            limit: {},
            dailyInput: '',
            weeklyInput: '',
            monthlyInput: '',
            limitReady: false,
        };
    }

    componentDidMount() {
        isMobile && this.props.enableCloseButton();

        userConductor.getDepositLimits().then(limit => {
            this.setState({ limit, limitReady: true });

            this.setState({
                dailyInput: limit.dailyDate === null ? limit.dailyAmount : limit.dailyAmountChange,
                weeklyInput:
                    limit.weeklyDate === null ? limit.weeklyAmount : limit.weeklyAmountChange,
                monthlyInput:
                    limit.monthlyDate === null ? limit.monthlyAmount : limit.monthlyAmountChange,
            });
        });
    }

    setDailyInput = value => {
        this.setState({ dailyInput: value.replace(/[^0-9]+/g, '') });
    };
    setWeeklyInput = value => {
        this.setState({ weeklyInput: value.replace(/[^0-9]+/g, '') });
    };
    setMonthlyInput = value => {
        this.setState({ monthlyInput: value.replace(/[^0-9]+/g, '') });
    };

    submit = () => {
        userConductor
            .setDepositLimits(
                new DepositLimitDTO({
                    ...this.state.limit,
                    dailyAmountChange: this.state.dailyInput,
                    weeklyAmountChange: this.state.weeklyInput,
                    monthlyAmountChange: this.state.monthlyInput,
                })
            )
            .then(limit => {
                this.setState({ limit }, () => {
                    notification.success('Indbetalingsgrænse er tilføjet.');
                });
            })
            .catch(error => {
                notification.error(error);
            });
    };

    render() {
        const { limit, limitReady } = this.state;
        return (
            <FullScreen>
                {!this.props.desktop ? (
                    <ContentHeight>
                        <TopBar>
                            <TopBarText className="sm capitalize">
                                {t.depositLimits.title}
                            </TopBarText>
                        </TopBar>
                    </ContentHeight>
                ) : null}
                <Container>
                    <Text accent>{t.depositLimits.text1}</Text>
                    <Text accent>{t.depositLimits.text2}</Text>
                    {limitReady ? (
                        <Fragment>
                            <Title>{t.depositLimits.daily}</Title>
                            <LimitTable
                                amount={limit.dailyAmount}
                                date={limit.dailyDate}
                                amountChange={limit.dailyAmountChange}
                            />
                            <LimitForm
                                onChange={this.setDailyInput}
                                borderRadius={borderRadius}
                                value={this.state.dailyInput || ''}
                                submit={this.submit}
                                validate={{ min: 100, max: 100000 }}
                            />

                            <Title>{t.depositLimits.weekly}</Title>
                            <LimitTable
                                amount={limit.weeklyAmount}
                                date={limit.weeklyDate}
                                amountChange={limit.weeklyAmountChange}
                            />
                            <LimitForm
                                onChange={this.setWeeklyInput}
                                borderRadius={borderRadius}
                                value={this.state.weeklyInput || ''}
                                submit={this.submit}
                                validate={{ min: 100, max: 700000 }}
                            />

                            <Title>{t.depositLimits.monthly}</Title>
                            <LimitTable
                                amount={limit.monthlyAmount}
                                date={limit.monthlyDate}
                                amountChange={limit.monthlyAmountChange}
                            />
                            <LimitForm
                                onChange={this.setMonthlyInput}
                                borderRadius={borderRadius}
                                value={this.state.monthlyInput || ''}
                                submit={this.submit}
                                validate={{ min: 100, max: 3000000 }}
                            />
                        </Fragment>
                    ) : (
                        <CenteredSpinner size="x2" />
                    )}
                </Container>
            </FullScreen>
        );
    }
}

export default withRouter(DepositLimits);
