import React, { useState } from 'react'
import { ForgotPassWrapper, Wrapper } from './styled'
import PropTypes from 'prop-types'
import { login, LOGIN_TYPES } from '../../services/LoginService'
import { dark, defaultTheme } from '../../utils/loginThemes'
import TopBarLogin from './Components/TopBarLogin'
import ButtonArea from './Components/ButtonArea'
import styled from 'styled-components'
import Img from '../Img'
import Text from '../Text'

const themesMap = {
    default: defaultTheme,
    dark,
}

export const IconWrapper = styled.div`
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
`
export const StyledList = styled.ol`
    counter-reset: li;
    padding: 2.25rem 0 0.25rem 2.75rem;

    li {
        margin-bottom: 0.375rem;

        h3 {
            font-size: 24px;
            color: ${({ primaryColor }) => primaryColor || '#db1c1b'};
            padding: 0;
            margin: 0;
        }

        small {
            font-size: 18px;
            color: ${({ textColor }) => textColor || '#333'};
            display: block;
            padding: 0.5rem 0 1.5rem;
        }
    }
    > li {
        position: relative;
        counter-increment: li;
        min-height: 2rem;
        padding-top: 0.25rem;
        list-style: none;
        margin: 0;

        &:before,
        &:after {
            position: absolute;
            display: flex;
        }

        &:before {
            content: counter(li);
            left: -2.75rem;
            top: 0;
            height: calc(2rem - 1px);
            width: calc(2rem - 1px);
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 1px solid #e6edfb;
            color: ${({ primaryColor }) => primaryColor || '#db1c1b'};
            font-weight: bold;
            font-size: 18px;
        }

        &:after {
            content: '';
            left: -1.75rem;
            top: calc(2rem + 0.5rem);
            height: calc(100% - 2rem - 1rem);
            border-left: 1px solid #e6edfb;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }
`

/**
 * @param closeAction {function} close button handler
 * @param stayOnPrevPage {bool} should we stay on same page after login
 * @param beforeLogin {function} before login handler function
 * @param loginHandler {function} Login button handler
 * @param theme {string} select theme
 * @param site {string} project name
 * @param oldAPI {boolean} old API path
 * */
const ForgotPassword = ({
    closeAction,
    stayOnPrevPage = false,
    beforeLogin = () => {},
    theme = 'default',
    site = '',
    oldAPI = false,
    primaryColor = '',
    productIcon = '',
}) => {
    const [mitIdLoading, setMitIdLoading] = useState({
        [LOGIN_TYPES.MIT_ID]: false,
    })
    const [close, setClose] = useState(false)
    const currentTheme = themesMap[theme]

    const onClose = () => {
        setClose(true)
        setTimeout(() => {
            closeAction()
        }, 200)
    }

    const alternateLogin = (type) => {
        beforeLogin(type)

        if (stayOnPrevPage) {
            localStorage.setItem('stay_on_prev_page', window.location.pathname)
        }
        login(
            type,
            site,
            mitIdLoading,
            (value) => setMitIdLoading(value),
            oldAPI,
            'forgotpassword'
        )
    }

    return (
        <Wrapper
            bg={currentTheme.colors.bg}
            close={close}
            key={`close-animation-${close}`}
        >
            <TopBarLogin
                title={'Nulstil kodeord'}
                currentTheme={currentTheme}
                closeAction={onClose}
            />
            <ForgotPassWrapper style={{ paddingTop: '25px' }}>
                {productIcon && (
                    <IconWrapper>
                        <Img src={productIcon} width={'154px'} alt={''} />
                    </IconWrapper>
                )}

                <h3>Ingen panik!</h3>
                <Text size={'lg'} style={{ margin: 0 }}>
                    Følg trinene nedenfor for at gendanne adgangen til din
                    konto.
                </Text>

                <StyledList
                    primaryColor={primaryColor}
                    textColor={currentTheme.colors.secondary}
                >
                    <li>
                        <h3>Log ind med MitID.</h3>
                        <small>
                            Brug dit MitID for sikkert at verificere din
                            identitet.
                        </small>
                    </li>
                    <li>
                        <h3>Vælg et nyt kodeord.</h3>
                        <small>
                            Efter verifikation bliver du guidet til at vælge et
                            nyt kodeord.
                        </small>
                    </li>
                </StyledList>
            </ForgotPassWrapper>
            <ButtonArea
                currentTheme={currentTheme}
                btnLoading={mitIdLoading}
                buttonClickHandler={alternateLogin}
            />
        </Wrapper>
    )
}

ForgotPassword.propTypes = {
    isMitIdLogin: PropTypes.bool,
    closeAction: PropTypes.func,
    loginHandler: PropTypes.func.isRequired,
    stayOnPrevPage: PropTypes.bool,
    theme: PropTypes.string,
    site: PropTypes.string.isRequired,
    oldAPI: PropTypes.bool,
}

export default ForgotPassword
