import React from 'react';
import { BetPickBtn } from './PickButtons.styled';
import useKombColumnState from 'features/TrackPage/hooks/useKombColumnState';
import useColumnSelection from 'features/TrackPage/hooks/useColumnSelection';
import { KombPickColumnButtonsContainer } from './PickButtons.styled';
import { trackEvent, trackingIDs } from 'utils/tracking';

const KombPickColumnButtons = () => {
    const { kombMarksIndicator } = useKombColumnState();
    const { toggleKombColumn } = useColumnSelection();

    const handleToggleTrioColumn = (col: number) => {
        trackEvent({
            event: trackingIDs.racingCard.selectAllBtn,
            category: 'shortcuts',
            action: col,
        });

        toggleKombColumn(col);
    };

    return (
        <KombPickColumnButtonsContainer center>
            <BetPickBtn active={kombMarksIndicator[1]} onClick={() => handleToggleTrioColumn(1)}>
                1
            </BetPickBtn>
            <BetPickBtn active={kombMarksIndicator[2]} onClick={() => handleToggleTrioColumn(2)}>
                2
            </BetPickBtn>
        </KombPickColumnButtonsContainer>
    );
};

export default KombPickColumnButtons;
