import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ProductsTransitionBar } from '@it25syv/25syv-ui';

import { DGABar, priority } from 'configs/layout';
import rofusLogo from 'images/dk/rofus.png';
import derbyImage from 'images/derby.png';
import betImage from 'images/bet.png';
import rodImage from 'images/rod.png';

import { BACKGROUNDS } from 'themes';
import { sidebar } from 'configs/layout';

import { resetRaceDay } from 'features/AISDataProvider/actions';
import { setDate } from 'ui/DatePicker/actions';

import { byPlatform } from 'utils/platforms';
import { trimSlashes } from 'utils/navigation';
import { trackEvent, trackingIDs } from 'utils/tracking';

export const WhiteSpaceFixer = styled.div`
    height: ${DGABar.desktop.height + 2}px;
    background: ${BACKGROUNDS.navigation};
`;

const TransitionBarSpace = styled.div`
    height: ${byPlatform(DGABar.mobile.height, DGABar.desktop.height)}px;
    background: ${BACKGROUNDS.navigation};
`;

const FixedWrapper = styled.div`
    position: fixed;
    z-index: ${priority.DGABar};
    top: 0;
    right: 0;
    height: auto;
    width: calc(
        100% - ${props => (props.isLeftSideBarOpen ? sidebar.width : sidebar.widthClosed)}px
    );
    transition: all 300ms ease-in-out;
    transition-delay: ${props => (props.isLeftSideBarOpen ? '-200ms' : '200ms')};
`;

const TransitionBarExtended = styled(ProductsTransitionBar)`
    box-sizing: border-box;
    padding: 0 10px;
    position: relative;
    p,
    span {
        font-size: inherit;
    }
    @media screen and (max-width: 1450px) {
        font-size: 12px;
    }
    @media screen and (max-width: 1290px) {
        font-size: 10px;
    }
    @media screen and (max-width: 360px) {
        font-size: 8px;
    }
`;

const ProductsTransitionBarContainer = ({
    currentLoginUTC,
    isLeftSideBarOpen,
    resetRaceDay,
    setDate,
}) => {
    const history = useHistory();
    const onLogoClick = () => {
        history.push('/');
        setDate(new Date());
        resetRaceDay();
    };

    const handleTransitionBarClick = e => {
        const isLinkElement = [e.target.parentElement.tagName, e.target.tagName].includes('A');

        const href =
            e.target?.parentElement?.getAttribute('href') ?? e.target?.getAttribute('href');

        const isProductLink = isLinkElement && ['/sport', '/casino', '/heste'].includes(href);

        if (isProductLink) {
            e.preventDefault();

            const productName = trimSlashes(href);

            trackEvent({
                event: trackingIDs.topbar.brandLinks,
                action: productName,
                category: 'shortcuts',
            });

            setTimeout(() => {
                window.location.href = href;
            }, 500);
        }
    };

    return (
        <TransitionBarSpace>
            <FixedWrapper isLeftSideBarOpen={isLeftSideBarOpen}>
                <TransitionBarExtended
                    p={2}
                    rofusLogo={rofusLogo}
                    betImage={betImage}
                    derbyImage={derbyImage}
                    rodImage={rodImage}
                    lastLoginTime={currentLoginUTC}
                    showTimer={!!currentLoginUTC}
                    clockFormat={'HH:mm'}
                    timerFormat={'HH:mm'}
                    activeDerbyColor={'#169959'}
                    activeProject={'derby'}
                    color={'#fff'}
                    rodLink={'/casino'}
                    betLink={'/sport'}
                    derbyLink={'/heste'}
                    logoOnClickHandler={onLogoClick}
                    background={BACKGROUNDS.navigation}
                    // custom props
                    isLeftSideBarOpen={isLeftSideBarOpen}
                    onClick={handleTransitionBarClick}
                />
            </FixedWrapper>
        </TransitionBarSpace>
    );
};

const mapStateToProps = state => ({
    currentLoginUTC: state.auth.user ? state.auth.user.currentLoginUTC : null,
});

const mapDispatchToProps = dispatch => ({
    resetRaceDay: () => dispatch(resetRaceDay()),
    setDate: date => dispatch(setDate(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsTransitionBarContainer);
