import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import MobileBet from './MobileBet';
import LocalMobileBet from './LocalMobileBet';
import { setMobileBetInit, addMobileBetCount } from './state/actions';

import betSlipSelector, { getReserves } from 'common/selectors/betSlipSelector';
import { combinationsCountBy } from 'common/selectors/combinationsCountSelector';
import { hideModal, showModal } from 'common/actions/uiActions';

import { useData, deleteMobileBet } from './hooks/useData';
import getTexts from 'utils/localization';

import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import useServerTimeQuery from 'features/EventBoard/hooks/useServerTimeQuery';
import { extractCombinedTrackName } from 'features/EventBoard/server/calendar';
import Bet from 'common/DataObjects/Bet';

const t = getTexts();

const MobileBetContainer = props => {
    const { product, isVProduct, raceDay, trackCode, trackId, isMultitrack, race, races } =
        useTrackPage();

    const user = useSelector(state => state.auth.user);

    const strictMode = useSelector(state => state.BetSlip.strictMode);
    const smartLynMode = useSelector(state => state.BetSlip.smartLynMode);
    const reserves = useSelector(getReserves);
    const multitrackReserves = useSelector(state =>
        get(state, 'BetSlip.reservesByDates[date]', [])
    );
    const mobileBetInit = useSelector(state => state.MobileBet.initialization);

    const combinationsCount = useSelector(state =>
        combinationsCountBy(state, {
            productId: product.id,
            trackCode,
            date: raceDay.raceDayDate,
            raceIndex: race.index,
            races,
        })
    );

    const combinedTrackName = extractCombinedTrackName(raceDay, product.id);

    const betSlip = useSelector(betSlipSelector);

    const betTable = betSlip.getRaceSelection({
        date: raceDay.raceDayDate,
        trackCode,
        productId: product.id,
        raceIndex: race.index,
    });

    const bet = new Bet(betTable);

    const { serverTime } = useServerTimeQuery();

    const sharedProps = {
        ...props,
        raceDay,
        product,
        isVProduct,
        date: raceDay.raceDayDate,
        trackCode,
        trackId,
        isMultitrackProduct: isMultitrack,
        race,
        races,
        raceNumber: race.raceNumber,
        strictMode,
        smartLynMode,
        betSlip,
        bet,
        combinationsCount,
        combinedTrackName,
        reserves,
        multitrackReserves,
        mobileBetInit,
        serverTime,
        user,
    };

    const dispatch = useDispatch();

    const [data, isPending] = useData(sharedProps);

    if (!isPending && mobileBetInit) {
        dispatch(setMobileBetInit(false));
    }

    const saveBetToLocal = (id, startDateTime) => {
        dispatch(addMobileBetCount());
        LocalMobileBet.add(id, startDateTime);

        dispatch(showModal('SUCCESS_BET_SAVING', 103));
        setTimeout(() => {
            dispatch(hideModal('SAVED_BET_CONTAINER'));
            dispatch(hideModal('SUCCESS_BET_SAVING'));
        }, 3000);
    };

    const closeModal = () => {
        dispatch(hideModal('SAVED_BET_CONTAINER'));

        if (mobileBetInit) {
            dispatch(setMobileBetInit(false));
        }
    };

    return (
        <MobileBet
            isPending={isPending}
            yellowButtonText={t.Terminal.mobileBet.saveCoupon}
            onClose={id => {
                deleteMobileBet(id);
                closeModal();
            }}
            onYellowButtonClick={(id, startDateTime) => {
                saveBetToLocal(id, startDateTime);
            }}
            showShopList={() => {
                dispatch(showModal('SHOP_LIST', 103));
            }}
            data={data}
        />
    );
};

export default MobileBetContainer;
