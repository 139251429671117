import React from 'react';
import { Col, Row } from 'react-grid-system';
import Text from 'ui/Text';

import getTexts from 'utils/localization';
const t = getTexts();

const PickHeader = ({ product, isVProduct }) => {
    return (
        <Row className="uppercase text-bold">
            <Col xs={2}>
                <Text className="text-bold text-center" size="x1">
                    {/* Get rid of the copy/paste. There is the same code in the features/BetSlip/SubHeader */}
                    {product.id === 'T' || product.id === 'TV' || product.id === 'K'
                        ? t.betSlip.place
                        : isVProduct
                        ? t.betSlip.department
                        : t.betSlip.track}
                </Text>
            </Col>
            <Col xs={10} style={{ padding: '0 20px 0 0' }}>
                <Text className="text-bold" size="x1">
                    {t.betSlip.nrAndHorse}
                </Text>
            </Col>
        </Row>
    );
};

export default PickHeader;
