import styled from 'styled-components';
import { byPlatform } from 'utils/platforms';
import React from 'react';
import Icon from 'ui/Icon';
import { BACKGROUNDS } from 'themes/index.ts';
import { fadeInMixin } from 'ui/animations/FadeIn2';

const Overlay = styled.div`
    height: ${({ height }) => height || '100%'};
    width: auto;
    background: rgba(0, 0, 0, ${({ opacity }) => opacity || 0.5});
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: center;
    ${({ skipDefaultAnimation }) => (skipDefaultAnimation ? '' : fadeInMixin)};
`;

const isTablet = () => window.innerWidth > 760;

const PopupWrapper = styled.div`
    background: ${props => props.bg || BACKGROUNDS.white};
    border-radius: ${props => props.radius || '25px'};
    margin: 0 auto;
    padding: ${props => props.padding || 0};
    background-image: url(${props => props.bg});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: auto;
    height: ${byPlatform(isTablet ? '396px' : '198px', 'auto')};
    width: ${props => props.width || byPlatform('100%', '600px')};
    min-height: ${props => props.minHeight || byPlatform('100%', '339px')};
    max-width: ${byPlatform(isTablet ? '704px' : '352px', 'none')};
    box-shadow: ${props => props.boxShadow || '0 0 12px rgb(62, 45, 45, 1)'};
    overflow: ${byPlatform('hidden', 'visible')};
    // visible is needed for showing close button on desktop
    ${fadeInMixin};
`;

const CloseButtonArea = styled.div`
    position: absolute;
    width: 40px;
    height: 40px;
    cursor: pointer;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CloseButton = styled.div`
    width: 25px;
    height: 25px;
    background: #eceff5;
    border-radius: 15px;
    color: #222;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    font-weight: 900;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 12px rgb(62, 45, 45, 1);
`;

const CloseIcon = styled(Icon).attrs({ color: 'gray' })`
    width: 10px;
    height: 10px;
`;

const Popup = ({
    children,
    onClose,
    TransparentBackgroundComponent = Overlay,
    PopupWrapperComponent = PopupWrapper,
    CloseButtonAreaComponent = CloseButtonArea,
    CloseButtonComponent = CloseButton,
    CloseIconComponent = CloseIcon,
    closeButtonLocation = '',
    displayCloseButton = true,
    disableCloseButton = false,
    styles = {
        PopupWrapper: {},
    },
}) => {
    closeButtonLocation = closeButtonLocation || byPlatform('outer', 'inner');

    const renderCloseButton = () =>
        displayCloseButton ? (
            <CloseButtonAreaComponent onClick={onClose}>
                {CloseButtonComponent === null ? (
                    <CloseIconComponent iclose />
                ) : (
                    <CloseButtonComponent style={{ cursor: 'pointer' }}>
                        {CloseIconComponent ? (
                            <CloseIconComponent iclose />
                        ) : (
                            byPlatform('x', <CloseIconComponent iclose />)
                        )}
                    </CloseButtonComponent>
                )}
            </CloseButtonAreaComponent>
        ) : null;

    return (
        <TransparentBackgroundComponent
            style={{
                background: byPlatform('rgba(0,0,0,0.8)', 'rgba(0,0,0,0.5)'),
            }}
            onClick={onClose}
        >
            {closeButtonLocation === 'outer' && !disableCloseButton && renderCloseButton()}
            <PopupWrapperComponent
                onClick={e => {
                    // avoid hiding on clicking any popup inner element
                    e.stopPropagation();
                }}
                style={styles.PopupWrapper}
            >
                {closeButtonLocation === 'inner' && !disableCloseButton && renderCloseButton()}
                {children}
            </PopupWrapperComponent>
        </TransparentBackgroundComponent>
    );
};

export default Popup;

export { Overlay, PopupWrapper, CloseButtonArea, CloseButton, CloseIcon };
