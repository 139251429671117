import React from 'react';
import { COLORS } from 'themes';
import RaceCard from 'features/RaceCard';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './carousel-styles.css';
import Wrapper from 'ui/Wrapper';
import Text from 'ui/Text';
import { connect } from 'react-redux';
import { setRace } from 'features/AISDataProvider/actions';
import { getTrackFromRacingCard } from 'common/selectors/trackSelector';
import racesSelector, {
    getDisabledRaceNumbers,
    isAnyRaceStarted,
    getForecastRace,
    racesFetchedSelector,
    racesPendingSelector,
    racesNotEmpty,
} from 'common/selectors/racesSelector';
import { isMultitrackProduct } from 'common/selectors/multipleTrackSetupsSelector';
import {
    getInputErrorReason,
    getRaceDay,
    gotEventError,
    isPending,
} from 'common/selectors/raceDaySelector';
import { betPickSelector } from 'common/selectors/betTableSelector';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { byPlatform, forMobile } from 'utils/platforms';
import Mark from 'ui/Mark';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import productSelector, { isStrictVProduct, isVProduct } from 'common/selectors/productSelector';
import { inputErrorReasons } from 'features/AISDataProvider';
import VRaceOverview from 'features/RacesCarusel/VRaceOverview';
import persistentStorage from 'common/storage';
import YouthWarning from '../TrackPage/components/RaceCard/common/YouthWarning';
import Forecast from 'features/RacesCarusel/Forecast';
import { RaceNavigationLabel } from 'ui/RaceNavigation';
import Checkbox2 from 'ui/Checkbox2';
import Preloader from '../TrackPage/components/RaceCard/common/Preloader';
import { terminal } from 'configs/layout';
import RaceCardReplays from 'features/RaceCard/RaceCardReplays';
import { ForecastContainer, RacesRowWrapper, RaceLineWrapper } from 'features/RacesCarusel/styled';
import ProductTips from 'features/TrackPage/components/ProductTips/ProductTips';
import RacesRow from '../TrackPage/components/RaceLine/RacesRow';
import { withProductTipsVisibilityStore } from '../TrackPage/hooks/useProductTipsVisibility';
import { generateEventPath, history } from 'utils/navigation';
import Track from '../../common/DataObjects/Track';
import { trackEvent, trackingIDs } from 'utils/tracking';

class RaceCarusel extends React.Component {
    rootSliderEl = null;

    constructor() {
        super();

        this.state = {
            hasError: false,
            reserveModeEnabled: false,
            showOverview: persistentStorage.getLS('vRaceOverviewDisabled') !== 'true',
            fixRacesSlider: false,
            initialOffsetTop: 0,
        };

        this.racesRef = React.createRef();
        this.scrollContainerRef = React.createRef();
        this.activeButtonRef = React.createRef();
        this.handleScroll = this.handleScroll.bind(this);
    }

    getOverviewStatus() {
        return persistentStorage.getLS('vRaceOverviewDisabled') !== 'true';
    }

    toggleOverviewStatus() {
        trackEvent({
            event: trackingIDs.racingCard.VRaceOverviewCheckbox,
            action: 'overview_racecard',
            category: 'ux',
        });

        persistentStorage.saveLS({
            vRaceOverviewDisabled: this.state.showOverview,
        });
        this.setState({ showOverview: !this.state.showOverview });
    }

    setReserveMode = () => {
        this.setState({ reserveModeEnabled: !this.state.reserveModeEnabled });
    };

    componentDidCatch() {
        this.setState({ hasError: true });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isStrictVProduct && !this.props.isStrictVProduct) {
            this.setState({ reserveModeEnabled: false });
        }

        if (!prevProps.shouldRenderContent && this.props.shouldRenderContent) {
            window.addEventListener('scroll', this.handleScroll);
            window.scrollTo(0, 0);
            const offsetTop = this.racesRef.current?.offsetTop;
            this.setState({ initialOffsetTop: offsetTop });
        }

        if (this.scrollContainerRef?.current && this.activeButtonRef?.current) {
            const scrollBarElement = this.scrollContainerRef.current;
            const activeButtonRect = this.activeButtonRef.current?.getBoundingClientRect();
            scrollBarElement.scrollLeft = activeButtonRect?.left - activeButtonRect?.width / 2 || 0;
        }

        this.preventHorizontalScrollingTilTouchMove();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const { initialOffsetTop } = this.state;
        if (window.scrollY >= initialOffsetTop) {
            this.setState({ fixRacesSlider: true });
        } else {
            this.setState({ fixRacesSlider: false });
        }
    };

    preventHorizontalScrollingTilTouchMove = () => {
        this.rootSliderEl = window.document.querySelector('.slick-slider');

        if (this.rootSliderEl) {
            this.rootSliderEl.addEventListener('touchstart', this.touchStart);
            this.rootSliderEl.addEventListener('touchmove', this.preventTouch, {
                passive: false,
            });
        }
    };

    onCaruselMoved = index => {
        const { productId, selectedRaceDay } = this.props;

        history.push(
            generateEventPath({
                date: selectedRaceDay.date,
                track: Track.getSlug(selectedRaceDay.trackName),
                productId,
                race: index + 1,
            })
        );

        this.setRaceToReduxState(index);
    };

    setRaceToReduxState = index => {
        const { races } = this.props;
        this.props.setRace(races[index].raceNumber, index);
    };

    touchStart(e) {
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }

    preventTouch(e) {
        const minValue = 10; // threshold

        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;

        // Vertical scrolling does not work when you start swiping horizontally.
        if (Math.abs(this.clientX) > minValue) {
            if (e && e.preventDefault) e.preventDefault();
            e.returnValue = false;

            return false;
        }
    }

    renderRacingCard = (race, index) => {
        let {
            betPicks,
            track,
            disabledRaceNumbers,
            showStatisticPopup,
            showReplayPopup,
            isMultitrackProduct,
            racingCardFetched,
        } = this.props;

        if (racingCardFetched && !race) {
            return null;
        }

        if (!race) {
            return <Preloader />;
        }

        if (race.youthRace) {
            return <YouthWarning key={index} race={race} track={track} />;
        }

        return (
            <RaceCard
                key={index}
                race={race}
                index={race.index}
                setReserveMode={this.setReserveMode}
                reserveModeEnabled={this.state.reserveModeEnabled}
                isMultitrack={isMultitrackProduct}
                bet={betPicks.get(race.index)}
                poolOverview={false}
                disabledRaceNumbers={disabledRaceNumbers}
                showStatisticPopup={showStatisticPopup}
                showReplayPopup={showReplayPopup}
            />
        );
    };

    renderVRaceOverview = (races, index) => {
        const { betPicks, track, isStrictVProduct } = this.props;

        const shouldHideOverview =
            !isStrictVProduct || !races || !races[0].saleOpen || !this.state.showOverview;

        if (shouldHideOverview) {
            return null;
        }

        return (
            <VRaceOverview
                key={'v' + index}
                races={races}
                setReserveMode={this.setReserveMode}
                reserveModeEnabled={this.state.reserveModeEnabled}
                trackCode={track.code}
                betPicks={betPicks}
            />
        );
    };

    slider = null;

    renderForMobile = (isDesktop = false) => {
        const settings = {
            vertical: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            beforeChange: (oldIndex, newIndex) =>
                setTimeout(() => this.onCaruselMoved(newIndex), 200),
            draggable: isDesktop,
            swipe: !isDesktop,
            touchThreshold: 10,
            initialSlide: this.props.raceIndex,
            lazyLoad: true,
        };

        let { races } = this.props;

        return (
            <SlickSlider
                ref={slider => (this.slider = slider)}
                key={this.props.raceIndex}
                {...settings}
            >
                {races.map((race, i) => this.renderRacingCard(race, i))}
            </SlickSlider>
        );
    };

    renderForDesktop = () => {
        const race = this.props.races[this.props.raceIndex];

        return (
            <Wrapper padding="0 0 20px 0" data-test-id="betting-table">
                {this.renderVRaceOverview(this.props.races, this.props.raceIndex)}
                {this.renderRacingCard(race, this.props.raceIndex)}
            </Wrapper>
        );
    };

    renderForTerminal = () => {
        const race = this.props.races[this.props.raceIndex];
        const wrapperStyles = {
            width: terminal.homepage.wrapperWidth,
            padding: '0px',
            transition: 'width 1s',
        };

        return (
            <Wrapper {...wrapperStyles}>
                {this.renderVRaceOverview(this.props.races, this.props.raceIndex)}
                {this.renderRacingCard(race, this.props.raceIndex)}
            </Wrapper>
        );
    };

    renderSliderAccordingToPlatform = () => {
        const renderFunction = byPlatform(
            this.renderForMobile,
            this.renderForDesktop,
            this.renderForTerminal
        );

        return typeof renderFunction === 'function' ? renderFunction() : <div />;
    };

    renderNoDataText = () => (
        <Wrapper margin="50px 0">
            <Text align="center">
                {this.props.inputErrorReason &&
                this.props.inputErrorReason === inputErrorReasons.INPUT_ERROR_TRACK
                    ? 'Løbet er ikke tilgængeligt på Bet25 Heste'
                    : 'Der er ingen tilgængelige løb i øjeblikket.'}
            </Text>
        </Wrapper>
    );

    renderOverviewCheckbox = () => {
        const { anyRaceStarted, productId } = this.props;

        if (anyRaceStarted) {
            return null;
        }

        const checkbox = (
            <div className="flex flex-end center-v">
                <RaceNavigationLabel style={{ color: COLORS.black }}>
                    {productId} oversigt
                </RaceNavigationLabel>
                <Checkbox2
                    className="flex-end"
                    id="raceOverview"
                    checked={this.getOverviewStatus()}
                    onChange={this.toggleOverviewStatus.bind(this)}
                    size={24}
                    style={{ minWidth: '24px' }}
                />
            </div>
        );

        return this.props.isStrictVProduct ? byPlatform(null, checkbox, checkbox) : null;
    };

    renderTopReplays = () => {
        const { track, disabledRaceNumbers, selectedRaceIndex, races } = this.props;

        return (
            <RaceCardReplays
                disabledRaceNumbers={disabledRaceNumbers}
                track={track}
                selectedRaceIndex={selectedRaceIndex}
                races={races}
            />
        );
    };

    renderRaceLine = () => {
        const { races, isVProduct } = this.props;

        const raceLineItems = races.map((race, index) => {
            return isVProduct ? index + 1 : race.uiNumber || race.raceNumber;
        });
        // reserving some space for V overview checkbox in case of isVProduct
        // const gridTemplateColumns = isVProduct ? '90px 3fr 2fr' : '90px 1fr';

        return (
            <>
                {/*<RaceLineContainer*/}
                {/*    style={omitMobile({ gridTemplateColumns })}*/}
                {/*    data-test-id="races"*/}
                {/*    // ref={byPlatform(this.racesRef, null)}*/}
                {/*    // fixed={this.state.fixRacesSlider}*/}
                {/*>*/}
                {/*    /!*    /!*{omitMobile(<RaceNavigationLabel>Løb/afd.</RaceNavigationLabel>)}*!/*!/*/}

                {/*    /!*    /!*<RaceLineScrollbar ref={this.scrollContainerRef}>*!/*!/*/}
                {/*    /!*    /!*    <RaceLine*!/*!/*/}
                {/*    /!*    /!*        raceNumbers={raceLineItems}*!/*!/*/}
                {/*    /!*    /!*        setRaceIndex={this.setRaceToReduxState}*!/*!/*/}
                {/*    /!*    /!*        raceIndex={this.props.raceIndex}*!/*!/*/}
                {/*    /!*    /!*        fadedRaceNumbers={disabledRaceNumbers}*!/*!/*/}
                {/*    /!*    /!*        innerRef={this.activeButtonRef}*!/*!/*/}
                {/*    /!*    /!*        isVProduct={isVProduct}*!/*!/*/}
                {/*    /!*    /!*    />*!/*!/*/}
                {/*    /!*    /!*</RaceLineScrollbar>*!/*!/*/}
                {/*    /!*    *!/*/}
                {/*    /!*    {this.renderOverviewCheckbox()}*!/*/}
                {/*    <RacesRow raceLineItems={raceLineItems} races={races} />*/}
                {/*</RaceLineContainer>*/}
                <RaceLineWrapper>
                    <RacesRowWrapper>
                        <RacesRow
                            raceLineItems={raceLineItems}
                            legacyRacesData={races}
                            legacySetRace={this.props.setRace}
                            legacySelectedRace={
                                isVProduct
                                    ? this.props.selectedRaceIndex + 1
                                    : this.props.selectedRaceNumber
                            }
                        />
                    </RacesRowWrapper>
                    {this.renderOverviewCheckbox()}
                </RaceLineWrapper>
            </>
        );
    };

    render() {
        const { hasError } = this.state;

        if (hasError) {
            return (
                <Wrapper>
                    <Mark grey>Could not display the races.</Mark>
                </Wrapper>
            );
        }

        const {
            selectedRaceDay,
            isVProduct,
            shouldRenderContent,
            isPending,
            productTipsVisibility,
        } = this.props;

        if (isPending) {
            return <Preloader />;
        }

        if (shouldRenderContent) {
            return (
                <>
                    <div
                        style={{
                            height: this.state.fixRacesSlider
                                ? this.racesRef.current?.offsetHeight
                                : 0,
                        }}
                    />
                    {this.renderRaceLine()}

                    {productTipsVisibility &&
                        forMobile(
                            <Wrapper padding="0 20px" margin="15px 0">
                                <ProductTips />
                            </Wrapper>
                        )}

                    {!isVProduct && selectedRaceDay.hasAnyResult && this.renderTopReplays()}

                    <ForecastContainer>
                        <Forecast ongoingRace={this.props.forecastRace} />
                    </ForecastContainer>

                    {this.renderSliderAccordingToPlatform()}

                    {/*<Wrapper padding={byPlatform('0 20px', '0 20px 20px')} margin="0 0 15px">*/}
                    {/*    <HorseTips*/}
                    {/*        raceIndex={this.props.selectedRaceIndex}*/}
                    {/*        race={this.props.races[this.props.raceIndex]}*/}
                    {/*    />*/}
                    {/*</Wrapper>*/}
                </>
            );
        }

        if (this.props.gotEventError) {
            return this.renderNoDataText();
        }

        return <Preloader />;
    }
}

const mapStateToProps = state => {
    const racingCardNotEmpty = racesNotEmpty(state),
        racingCardFetched = racesFetchedSelector(state),
        racingCardPending = racesPendingSelector(state),
        races = racesSelector(state);

    const shouldRenderContent =
        racingCardNotEmpty && racingCardFetched && !racingCardPending && races.length;

    return {
        selectedRaceDay: getRaceDay(state),
        track: getTrackFromRacingCard(state),
        productId: productSelector(state).id,
        isStrictVProduct: isStrictVProduct(state),
        isVProduct: isVProduct(state),
        races,
        forecastRace: getForecastRace(state),
        isMultitrackProduct: isMultitrackProduct(state),
        betPicks: betPickSelector(state),
        selectedRaceIndex: state.AISDataProvider.raceIndex,
        selectedRaceNumber: state.AISDataProvider.selectedRaceNumber,
        disabledRaceNumbers: getDisabledRaceNumbers(state),
        inputErrorReason: getInputErrorReason(state),
        anyRaceStarted: isAnyRaceStarted(state),
        gotEventError: gotEventError(state),
        isPending: isPending(state),
        shouldRenderContent,
        racingCardFetched,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setRace: (raceNumber, raceIndex) => {
            dispatch(setRace(raceNumber, raceIndex));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withProductTipsVisibilityStore(RaceCarusel));

export { RaceCarusel };
