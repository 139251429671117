import React from 'react';
import classNames from 'classnames';

import { BetPickBtn } from './PickButtons.styled';
import useTrioColumnState from 'features/TrackPage/hooks/useTrioColumnState';
import useColumnSelection from 'features/TrackPage/hooks/useColumnSelection';
import { isMobile } from 'utils/platforms';
import { trackEvent, trackingIDs } from 'utils/tracking';

const TrioPickColumnButtons = () => {
    const { trioMarksIndicator } = useTrioColumnState();
    const { toggleTrioColumn } = useColumnSelection();
    const cls = classNames('flex col-gap-5 pl-15 fade-in', { 'pt-5': isMobile, 'pb-5': isMobile });

    const handleToggleTrioColumn = (col: number) => {
        trackEvent({
            event: trackingIDs.racingCard.selectAllBtn,
            category: 'shortcuts',
            action: col,
        });

        toggleTrioColumn(col);
    };

    return (
        <div className={cls}>
            <BetPickBtn active={trioMarksIndicator[1]} onClick={() => handleToggleTrioColumn(1)}>
                1
            </BetPickBtn>
            <BetPickBtn active={trioMarksIndicator[2]} onClick={() => handleToggleTrioColumn(2)}>
                2
            </BetPickBtn>
            <BetPickBtn active={trioMarksIndicator[3]} onClick={() => handleToggleTrioColumn(3)}>
                3
            </BetPickBtn>
        </div>
    );
};

export default TrioPickColumnButtons;
