export const defaultTheme = {
    name: 'default',
    fontFamily: "'Roboto Condensed', sans-serif",
    fontWeight: '900',
    colors: {
        bg: '#ffffff',
        bgDarker: '#eceff6',
        bgGrey: '#F7F8FC',
        basic: '#333333',
        primary: '#575c69',
        primaryAction: '#9198aa',
        primaryActionHover: '#e9ebee',
        secondary: '#FFF7CD',
        secondaryAction: '#FFD401',
        active: '#0bc628', //#5BB85D
        link: '#1b4ef7',
        bgBlue: '#E4EFFE',
        borderBlue: '#D3E3FB'
    }
}

export const dark = {
    name: 'dark',
    fontFamily: "'Kanit',sans-serif",
    fontWeight: '400',
    colors: {
        bg: '#121212',
        bgDarker: '#262626',
        bgGrey: '#262626',
        basic: '#ffffff',
        primary: '#BEBEBE',
        primaryAction: '#818181',
        primaryActionHover: '#e9ebee',
        secondary: '#522200',
        secondaryAction: '#F77705',
        active: '#51A351',
        link: '#97D800',
        bgBlue: '#3F7FE91A',
        borderBlue: '#3F7FE91A'
    }
}
