import SEIcon from 'images/flags/SE.png';
import DKIcon from 'images/flags/DK.png';
import FRIcon from 'images/flags/FR.png';
import UKIcon from 'images/flags/UK.png';
import ZAIcon from 'images/flags/ZA.png';

export const STREAM_MODE_ATG = 'STREAM_MODE_ATG';
export const STREAM_MODE_FT = 'STREAM_MODE_FT';
export const STREAM_MODE_FTP = 'STREAM_MODE_FTP';
export const STREAM_MODE_FR = 'STREAM_MODE_FR';
export const STREAM_MODE_SA = 'STREAM_MODE_SA';
export const STREAM_MODE_UK = 'STREAM_MODE_UK';

export const src = {
    [STREAM_MODE_FT]: 'https://kanal75-lab-b.akamaized.net/live/Data/Kanal-1/HLS_B/Kanal-1.m3u8',
    [STREAM_MODE_FTP]: 'https://kanal75-lab-b.akamaized.net/live/Data/Kanal-3/HLS_B/Kanal-3.m3u8',
    [STREAM_MODE_ATG]: 'https://kanal75-lab-b.akamaized.net/live/Data/Kanal-2/HLS_B/Kanal-2.m3u8',
    [STREAM_MODE_FR]:
        'https://kanal75-lab-b.akamaized.net/live/Data/kanal-4-fr/HLS_B/kanal-4-fr.m3u8',
    [STREAM_MODE_SA]:
        'https://kanal75-lab-b.akamaized.net/live/Data/kanal-6-sa/HLS_B/kanal-6-sa.m3u8',
    [STREAM_MODE_UK]:
        'https://kanal75-lab-b.akamaized.net/live/Data/kanal-5-gb/HLS_B/kanal-5-gb.m3u8',
};

export const icons = {
    [STREAM_MODE_ATG]: SEIcon,
    [STREAM_MODE_FR]: FRIcon,
    [STREAM_MODE_SA]: ZAIcon,
    [STREAM_MODE_UK]: UKIcon,
    [STREAM_MODE_FT]: DKIcon,
    [STREAM_MODE_FTP]: DKIcon,
};

export const streamCountry = {
    DK: STREAM_MODE_FT,
    SE: STREAM_MODE_ATG,
    FR: STREAM_MODE_FR,
    ZA: STREAM_MODE_SA,
    GB: STREAM_MODE_UK,
};
