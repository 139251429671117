import { PRODUCT_IDS, PRODUCTS_NAMES, V_GAMES } from 'configs/products';
import moment from 'moment';
import { AnyRace } from 'features/TrackPage/model/Race';
import { extractTrackSlug } from 'utils/navigation';
import { uniq } from 'lodash';

export interface CalendarData {
    fromDate: string;
    toDate: string;
    raceDayInfos: {
        raceDayInfos: RaceDay[];
    };
}

export interface CalendarDataByDate {
    fromDate: string;
    toDate: string;
    currentDate: string;
    raceDays: RaceDay[];
    shortCuts: Shortcut[];
}

export type ProductId = keyof typeof PRODUCT_IDS;
export type ProductName = keyof typeof PRODUCTS_NAMES;
export type ProductIds = Array<ProductId>;
type ProductIdsValues = (typeof PRODUCT_IDS)[keyof typeof PRODUCT_IDS];

export interface ProductsJackpot {
    jackpot: { currency: string; sum: number };
    product: ProductId;
}

export interface RaceDay {
    availableForBetting: ProductIds;
    betTypes: Array<BetType>;
    canceled: boolean;
    country: Label;
    disabledProducts: Array<typeof PRODUCT_IDS>;
    firstRacePostTime: string;
    firstRacePostTimeUTC: string;
    fullProductList: ProductIds;
    mainBetType: Label;
    meetingNumber: number;
    meetingType: Label;
    multipleTrackPoolSetups: Array<MultipleTrackPoolSetup>;
    nextSaleOpeRaceNumber?: number;
    programNumber: number;
    raceCardAvailable: boolean;
    raceDayDate: string;
    racesInfo: Array<RaceInfo>;
    productsWithJackpot: Array<ProductsJackpot>;
    sportSystemId: number;
    track: Label;
    trackId: number;
    trackName: string;
    trot: boolean;
    weather: Weather;
    youthRaces: number[];
}

export declare type ShortenRaceDay = Pick<RaceDay, 'raceDayDate' | 'trackId' | 'track'>;

interface Weather {
    currentConditions: [
        {
            tempCelsius: number;
            weatherCode: number;
            weatherDescription: [{ value: string }];
        },
    ];
}

export interface BetType {
    hasBoost: boolean;
    hasResult: boolean;
    name: Label;
    national: boolean;
    races: number[];
}

export interface Label {
    code: string;
    domesticText: string;
    englishText: string;
}

interface Track {
    trackId: number;
    track: Label;
}

export interface MultipleTrackPoolSetup {
    product: ProductId;
    betType: Label;
    bettingRaceNumbers: number[];
    hasBoost: boolean;
    hostTracks: Array<Track>;
    itspEventCode: string;
    legInfo: {
        legInfos: Array<LegInfo>;
    };
    multipleTrackName: string;
    multipleTrackNameEnglish: string;
    programNumber: number;
    raceDayDate: string;
    track: Label;
    trackId: number;
}

interface LegInfo {
    hostTrack: Track;
    legNr: number;
    raceNr: number;
    trot: boolean;
}

interface RaceInfo {
    betTypeCodes: ProductIdsValues;
    canceled: boolean;
    hasResult: boolean;
    postTime: string;
    postTimeUTC: string;
    raceNr: number;
    raceType: Label;
    trackSurface: Label;
}

export interface Shortcut {
    product: keyof typeof PRODUCT_IDS;
    raceDate: string;
    trackId: number;
    trackCode: string;
    trackName: string;
}

export const RaceDayPlaceholder: RaceDay = {
    availableForBetting: [],
    betTypes: [],
    canceled: true,
    country: {} as Label,
    disabledProducts: [],
    firstRacePostTime: '',
    firstRacePostTimeUTC: '',
    fullProductList: [],
    mainBetType: {} as Label,
    meetingNumber: 0,
    meetingType: {} as Label,
    multipleTrackPoolSetups: [],
    programNumber: 0,
    raceCardAvailable: false,
    raceDayDate: '',
    racesInfo: [],
    productsWithJackpot: [],
    sportSystemId: 0,
    track: {} as Label,
    trackId: 0,
    trackName: '',
    trot: false,
    weather: {} as Weather,
    youthRaces: [],
};

export const BetTypePlaceholder: BetType = {
    hasBoost: false,
    hasResult: false,
    name: {} as Label,
    national: false,
    races: [],
};

export function getRaceDays(calendarData: CalendarDataByDate) {
    return calendarData.raceDays;
}

export function findRaceDay(
    raceDays: RaceDay[],
    date: string,
    trackId: number
): RaceDay | undefined {
    return raceDays.find(
        raceDayInfo =>
            raceDayInfo.raceDayDate === date &&
            (raceDayInfo.trackId === trackId || findByMultitrackTrackId(raceDayInfo, trackId))
    );
}

function findByMultitrackTrackId(raceDayInfo: RaceDay, trackId: number) {
    return raceDayInfo.multipleTrackPoolSetups?.find(
        multitrackSetup => multitrackSetup.trackId === trackId
    );
}

export function getRaceCount(raceDay: RaceDay) {
    return raceDay.racesInfo.length;
}

export function isAvailableForBetting(raceDay: RaceDay) {
    return raceDay.availableForBetting.length > 0;
}

export function getSupportedProducts(raceDay: RaceDay) {
    return raceDay.fullProductList.filter(product => V_GAMES.includes(product));
}

export const isRaceOutdated = (race: RaceInfo, raceDate: string) => {
    const currentTimeInUtc = moment.utc();
    const currentRaceStartTime = moment(`${raceDate} ${race.postTimeUTC}`, 'YYYY-MM-DD HH:mm:ss');
    const fiveMinutesLater = currentRaceStartTime.add(5, 'm');

    return Boolean(
        currentTimeInUtc.isSameOrAfter(moment.utc(fiveMinutesLater.format('YYYY-MM-DD HH:mm:ss')))
    );
};

export const isRaceOngoing = (startTime: string): boolean => {
    const currentTimeInUtc = moment.utc();

    return Boolean(currentTimeInUtc.isSameOrAfter(moment.utc(startTime)));
};

export function hasProductOngoingRace(raceDay: RaceDay, productId: ProductId): boolean {
    return Boolean(
        raceDay.racesInfo.some(
            race =>
                race.betTypeCodes.includes(productId) &&
                isRaceOngoing(`${raceDay.raceDayDate} ${race.postTimeUTC}`)
        )
    );
}

export const findMultipleTrackSetup = (
    raceDay: RaceDay,
    productId: ProductId
): MultipleTrackPoolSetup | undefined => {
    return raceDay.multipleTrackPoolSetups?.find(multitrackSetup => {
        return multitrackSetup.product === productId;
    });
};

export const findCoupleTrack = (raceDay: RaceDay, productId: ProductId): Track | undefined => {
    const multipleTrackSetup = findMultipleTrackSetup(raceDay, productId);
    return multipleTrackSetup?.hostTracks.find(hostTrack => hostTrack.trackId !== raceDay.trackId);
};

export const isMultitrackProduct = (raceDay: RaceDay, productId: ProductId) => {
    return Boolean(findMultipleTrackSetup(raceDay, productId));
};

export const extractMultitrackTrackNames = (raceDay: RaceDay, productId: ProductId): string[] => {
    const multitrackSetup = findMultipleTrackSetup(raceDay, productId);

    if (!multitrackSetup) {
        return [raceDay.trackName];
    }

    return multitrackSetup.hostTracks.map(track => track.track.domesticText);
};

export const extractCombinedTrackName = (raceDay: RaceDay, productId: ProductId): string => {
    const trackNames = extractMultitrackTrackNames(raceDay, productId);
    return uniq(trackNames).join(' / ');
};

export const extractMultitrackTrackCodes = (raceDay: RaceDay, productId: ProductId): string[] => {
    const multitrackSetup = findMultipleTrackSetup(raceDay, productId);

    if (!multitrackSetup) {
        return [raceDay.track.code];
    }

    return multitrackSetup.hostTracks.map(track => track.track.code);
};

export const extractMultitrackTrackId = (raceDay: RaceDay): number | undefined => {
    const setups = raceDay.multipleTrackPoolSetups;

    if (setups) {
        // we can take any product, because it's the same between products
        return setups[0].trackId;
    }
};

export const extractRaceDay = (raceDays: RaceDay[], trackName: string) => {
    return (
        raceDays.find(
            raceDay => extractTrackSlug(raceDay.trackName) === extractTrackSlug(trackName)
        ) ?? RaceDayPlaceholder
    );
};

export const extractFirstRaceDay = (raceDays: RaceDay[]) => {
    return (
        raceDays.sort((a, b) => (a.firstRacePostTime < b.firstRacePostTime ? -1 : 1))[0] ??
        RaceDayPlaceholder
    );
};

export const extractBetType = (raceDay: RaceDay, productId: ProductId): BetType => {
    if (productId === PRODUCT_IDS.VP) {
        productId = 'V';
    }

    return raceDay.betTypes.find(betType => betType.name.code === productId) ?? BetTypePlaceholder;
};

export const extractVinderBetType = (raceDay: RaceDay): BetType =>
    raceDay.betTypes.find(betType => betType.name.code === PRODUCT_IDS.V) ?? BetTypePlaceholder;

export const extractFirstAvailableRace = (betType: BetType, races: AnyRace[]): number => {
    return betType.races.findIndex((_, idx) => races[idx]?.saleOpen) + 1 ?? 1;
};

export const isVProduct = (productId: ProductId) =>
    V_GAMES.some(vProductId => vProductId === productId);

export const hasResultByRaceNr = (raceDay: RaceDay, raceNumber: string | number): boolean =>
    !!raceDay.racesInfo.find(raceInfo => raceInfo.raceNr === Number(raceNumber))?.hasResult;

export const isRacingCardAvailable = (raceDay: RaceDay) =>
    raceDay.trackId !== 0 && raceDay.raceCardAvailable && raceDay.programNumber !== 0;
