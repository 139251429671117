import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Icon from 'ui/Icon';
import Modal, { useModalAnimation } from 'ui/ModalContent';
import { Overlay, PopupWrapper } from 'ui/Popup';

import { byPlatform } from 'utils/platforms';

import useCommentMutation from 'features/Spilklub2/hooks/useCommentMutation';

import { COLORS } from 'themes';
import classNames from 'classnames';
import { getPoolDetailsAsync } from '../actions';

const borderRadius = '4px';
const spacing = '16px';
const fontSize = '16px';

const CloseBtn = styled.div`
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 ${spacing}; // some space for click handler
`;

const Header = styled.div`
    background: #1ca963;
    height: 40px;
    box-sizing: border-box;
    padding-right: ${spacing};
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${COLORS.white};
    font-weight: 900;
    font-size: ${fontSize};
    border-top-left-radius: ${borderRadius};
    border-top-right-radius: ${borderRadius};
`;

const SaveBtn = styled.span`
    cursor: pointer;
    color: ${props => (props.active ? COLORS.white : COLORS.overlay300)};
`;

const Textarea = styled.textarea`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 0;
    padding: ${spacing};
    resize: none;
    font-size: ${fontSize};
    border-bottom-left-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};
`;

const CommentPopup = ({ couponId, comment: existingComment, pool }) => {
    const dispatch = useDispatch();

    const [comment, setComment] = useState(existingComment);

    const textareaRef = useRef(null);

    const { saveComment } = useCommentMutation({ couponId });

    const { animateHide, onAnimationEnd, hiding } = useModalAnimation({
        id: 'SPILKLUB_ADD_COMMENT',
    });

    useEffect(() => {
        if (textareaRef.current) {
            const textarea = textareaRef.current;
            textarea.focus();
            // Set caret to end
            textarea.setSelectionRange(textarea.value.length, textarea.value.length);
        }
    }, []);

    const save = () => {
        saveComment(comment)
            .then(() => {
                dispatch(getPoolDetailsAsync.request(pool.internalPool.id));
            })
            .finally(animateHide);
    };

    return (
        <Modal
            className={classNames('modal-fade', { 'modal-fade-out': hiding })}
            onAnimationEnd={onAnimationEnd}
            priority={2}
        >
            <Overlay opacity={0.7}>
                <PopupWrapper
                    radius={borderRadius}
                    width={byPlatform('100%', '600px')}
                    className="border-box"
                    boxShadow="0"
                    minHeight="unset"
                >
                    <Header>
                        {/* Close button */}
                        <CloseBtn onClick={animateHide}>
                            <Icon iclose3 size="x05" />
                        </CloseBtn>
                        {/* Centered text */}
                        Tilføj til din kupon
                        {/* "Save" button */}
                        <SaveBtn onClick={save} active={Boolean(comment)}>
                            Gem
                        </SaveBtn>
                    </Header>
                    <Textarea
                        ref={textareaRef}
                        placeholder="Tilføj tekst til din kupon"
                        maxLength="999"
                        value={comment}
                        onChange={event => setComment(event.target.value)}
                    ></Textarea>
                </PopupWrapper>
            </Overlay>
        </Modal>
    );
};

export default CommentPopup;
