import React from 'react';
import Slider from 'react-slick';
import moment from 'moment';
import { connect } from 'react-redux';

import { DEFAULT_SELECTED_PRODUCT } from 'configs/products';
import { setProduct, setProductShortcut, setRaceDay } from 'features/AISDataProvider/actions';
import {
    getMultitrackCouple,
    isMultitrackProduct,
} from 'common/selectors/multipleTrackSetupsSelector';
import Track from 'common/DataObjects/Track';
import {
    getTrackFromRacingCard,
    getTracksJackpotAvailabilityMap,
} from 'common/selectors/trackSelector';

import * as RaceNavigation from 'ui/RaceNavigation';

import { scrollToElement } from 'utils/DOM';
import { byPlatform, forMobile, isMobile } from 'utils/platforms';

import {
    PreloaderButtons,
    NextTrackButton,
    PrevTrackButton,
    JackpotLabel,
    jackpotLabelTopOffset,
    SlickSliderContainer,
} from 'ui/RaceNavigation';

import './slick.css';
import {
    getAllMultitrackSetupsByDate,
    getUniversalTrackId,
} from 'common/selectors/raceDaySelector';
import {
    MINIMUM_ITEMS_TO_SHOW_SLIDER,
    TRACKS_ORDER_BY_COUNTRY,
} from 'features/TrackPage/components/TrackLine/TrackButtons';
import { extractEventParams, extractTrackSlug, generateEventPath, history } from 'utils/navigation';
import { trackEvent, trackingIDs } from 'utils/tracking';

class TracksBySelectedDay extends React.Component {
    scrollContainerRef = React.createRef();
    activeButtonRef = React.createRef();

    componentDidUpdate(prevProps) {
        if (!prevProps.track.id && Boolean(this.props.track.id)) {
            scrollToElement(this.scrollContainerRef, this.activeButtonRef);
        }
    }

    onSelectTrackHandler = raceDay => {
        const {
            isMultitrackProduct,
            selectedProduct,
            coupleTrack,
            racingCardPending,
            trackPoolPending,
            selectedRaceDay,
        } = this.props;

        trackEvent({
            event: trackingIDs.racingCard.trackBtn,
            action: 'switch_lane_racecard',
            category: 'ux',
        });

        const isSameTrack = raceDay.trackId === selectedRaceDay.trackId;
        // there's no reason to redirect user to Vinder or do extra history.push if it's the same track
        if (isSameTrack || racingCardPending || trackPoolPending) {
            return;
        }

        const { date, race } = extractEventParams();

        const isSameMultitrack =
            isMultitrackProduct && (raceDay.trackId === coupleTrack.id || isSameTrack);

        // in case of multi track product if a user switches between
        // tracks for the same multi track pool we have to choose
        // the same product for it automatically
        const productToNavigate = isSameMultitrack ? selectedProduct : DEFAULT_SELECTED_PRODUCT;

        const raceToNavigate = isSameMultitrack ? race : 1;

        history.push(
            generateEventPath({
                productId: productToNavigate.id,
                date,
                track: extractTrackSlug(raceDay.trackName),
                race: raceToNavigate,
            })
        );
    };

    filterRaceDayByProduct(races, product) {
        return races
            ? races.filter(raceDay =>
                  !this.props.productShortcut
                      ? true
                      : raceDay.products.find(raceDayProduct => raceDayProduct.id === product.id)
              )
            : [];
    }

    getTracks = () => {
        const { raceDayData, selectedDate, selectedProduct } = this.props;

        let data = raceDayData;

        if (selectedProduct) {
            data = this.filterRaceDayByProduct(data, selectedProduct);
        }

        data = data
            .filter(raceDay => raceDay.date === moment(selectedDate).format('YYYY-MM-DD'))
            .sort((a, b) => {
                const trackACountryOrder = TRACKS_ORDER_BY_COUNTRY.indexOf(a.country.code);
                const trackBCountryOrder = TRACKS_ORDER_BY_COUNTRY.indexOf(b.country.code);

                if (trackBCountryOrder === -1) {
                    return -1;
                }

                if (trackACountryOrder === trackBCountryOrder) {
                    // if both tracks are in the same country, sort by post time
                    return a.firstRacePostTime < b.firstRacePostTime ? -1 : 1;
                }

                return trackACountryOrder > trackBCountryOrder ? 1 : -1;
            });

        return data;
    };

    getNumberOfTracks = () => this.getTracks().length;

    shouldShowPreloader = () => {
        const { loading, raceDayData } = this.props;
        return loading && !raceDayData;
    };

    shouldUseSlider = () => {
        const numberOfTracks = this.getNumberOfTracks();
        return (
            numberOfTracks > MINIMUM_ITEMS_TO_SHOW_SLIDER &&
            !isMobile &&
            !this.shouldShowPreloader()
        );
    };

    renderTrackList() {
        const {
            selectedRaceDay,
            racingCardPending,
            trackPoolPending,
            defaultRaceDay,
            tracksJackpotAvailabilityMap,
            allTracksMultitrackSetupsEntries,
            coupleTrack,
        } = this.props;

        if (this.shouldShowPreloader()) {
            return this.renderPreloader();
        }

        const tracksData = this.getTracks();

        const useSlider = this.shouldUseSlider();

        return tracksData.map((raceDay, i) => {
            const track = raceDay.track;
            const universalTrackId = getUniversalTrackId(allTracksMultitrackSetupsEntries, track);
            const hasJackpot =
                tracksJackpotAvailabilityMap[universalTrackId] ||
                // additional check to consider jackpot for non-multitrack pools
                // of the tracks that actually also have multitrack pools
                tracksJackpotAvailabilityMap[track.id];
            const isActive =
                selectedRaceDay && selectedRaceDay.trackId === raceDay.trackId && defaultRaceDay;

            const countryImage =
                RaceNavigation.countryCodeMap[raceDay.country.code] ||
                RaceNavigation.countryCodeMap.Default;

            return (
                <RaceNavigation.ButtonWithSublabel
                    key={raceDay.trackId}
                    style={{ display: 'flex' }}
                >
                    <RaceNavigation.TrackNavigationButton
                        disabled={racingCardPending || trackPoolPending}
                        active={isActive}
                        data-test-id={isActive ? 'active' : 'inactive'}
                        accent={raceDay.trackId === coupleTrack.id}
                        onClick={this.onSelectTrackHandler.bind(this, raceDay)}
                        useSlider={useSlider}
                        style={{
                            padding: byPlatform('10 14px', '0 13px', '0 18px'),
                            // fixes Mob. Padding after the last button DER-2629 (padding-right doesn't work for mobile)
                            marginRight: forMobile(i + 1 === tracksData.length ? '20px' : '5px'),
                            // react ignores 'padding' property here for mobile
                            paddingLeft: byPlatform('14px', '13px', '18px'),
                            paddingRight: byPlatform('14px', '13px', '18px'),
                            minWidth: 'auto',
                        }}
                        {...(isActive ? { ref: this.activeButtonRef } : {})}
                    >
                        <RaceNavigation.CountryImage src={countryImage} />
                        {track.name}
                    </RaceNavigation.TrackNavigationButton>

                    {hasJackpot && <JackpotLabel>Jackpot</JackpotLabel>}

                    {/*<RaceNavigation.SubLabel>*/}
                    {/*    {moment(raceDay.firstRacePostTime).utc() > moment(serverTime).utc()*/}
                    {/*        ? moment(raceDay.firstRacePostTime).utc().from(moment(serverTime).utc())*/}
                    {/*        : t.RacingCard.ongoing}*/}
                    {/*</RaceNavigation.SubLabel>*/}
                </RaceNavigation.ButtonWithSublabel>
            );
        });
    }

    renderPreloader = () => (
        <PreloaderButtons count={4} width="100px" wrapperStyles={{ padding: 0 }} />
    );

    render() {
        return (
            <Container
                useSlider={this.shouldUseSlider()}
                scrollContainerRef={this.scrollContainerRef}
            >
                {this.renderTrackList()}
            </Container>
        );
    }
}

const Container = ({ children, useSlider, scrollContainerRef }) => {
    const sliderArrowsStyle = {
        marginTop: byPlatform('0', jackpotLabelTopOffset),
    };

    const sliderSettings = {
        className: 'slider variable-width',
        dots: false,
        infinite: false,
        speed: 500,
        slidesToScroll: 2,
        slidesToShow: MINIMUM_ITEMS_TO_SHOW_SLIDER,
        prevArrow: <PrevTrackButton styles={sliderArrowsStyle} />,
        nextArrow: <NextTrackButton styles={sliderArrowsStyle} />,
        responsive: [
            {
                breakpoint: 630,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1030,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                },
            },
        ],
    };

    return useSlider ? (
        <SlickSliderContainer className="homepage-tracks">
            <Slider {...sliderSettings}>{children}</Slider>
        </SlickSliderContainer>
    ) : (
        <RaceNavigation.TracksScrollBar ref={scrollContainerRef}>
            {children}
            {/* fixes Mob. Padding after the last button DER-2629 (padding-right doesn't work for mobile) */}
            {forMobile(<RaceNavigation.ScrollbarRightIndent />)}
        </RaceNavigation.TracksScrollBar>
    );
};

const mapStateToProps = state => ({
    isMultitrackProduct: isMultitrackProduct(state),
    coupleTrack: isMultitrackProduct(state) ? getMultitrackCouple(state) : Track.getNullObject(),
    allTracksMultitrackSetupsEntries: getAllMultitrackSetupsByDate(state),
    track: getTrackFromRacingCard(state),
    selectedDate: state.DatePicker.date,
    selectedProduct: state.AISDataProvider.selectedProduct,
    selectedRaceDay: state.AISDataProvider.selectedRaceDay,
    serverTime: state.AISDataProvider.serverTime,
    raceDayFetched: state.AISDataProvider.raceDayFetched,
    raceDayData: state.AISDataProvider.raceDayData,
    tracks: state.AISDataProvider.tracks,
    tracksJackpotAvailabilityMap: getTracksJackpotAvailabilityMap(state),
    racingCardPending: state.AISDataProvider.racingCardPending,
    trackPoolPending: state.AISDataProvider.trackPoolPending,
    defaultRaceDay: state.AISDataProvider.defaultRaceDay,
    raceDayError: state.AISDataProvider.raceDayError,
    racingCardError: state.AISDataProvider.racingCardError,
    loading: state.AISDataProvider.raceDayPending || state.AISDataProvider.racingCardPending,
});

const mapDispatchToProps = dispatch => {
    return {
        setRaceDay: raceDay => {
            dispatch(setRaceDay(raceDay));
        },
        setProduct: product => {
            dispatch(setProduct(product));
        },
        setProductShortcut: product => {
            dispatch(setProductShortcut(product));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TracksBySelectedDay);
