import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { connect } from 'react-redux';

import Icon from 'ui/Icon';
import Text from 'ui/Text';
import playIcon from 'icons/livestream.svg';

import { priority } from 'configs/layout';

import getTexts from 'utils/localization';
const t = getTexts();

const animationSpeed = '0.4s';

const radius = css`
    border-radius: 80px;
`;

const shineKeyframes = keyframes`
    0% {
      opacity: 0;
      transform: translateX(-100px) skewX(-15deg);
      transition-property: opacity, transform;
    }
    5%, 100% {
      opacity: 1;
      transform: translateX(300px) skewX(-15deg);
      transition-property: opacity, transform;
    }
`;

const shine = css`
    --shine-degree: 120deg;
    --shine-color: rgba(255, 255, 255, 0.2);
    --shine-effect: linear-gradient(
        var(--shine-degree),
        transparent,
        var(--shine-color),
        transparent
    );

    &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(--shine-effect);
        left: -100%;
        animation: ${shineKeyframes} 60s ease-in-out infinite;
    }
`;

const Shine = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    ${radius};
    ${shine};
`;

const DropShadow = styled.div`
    ${radius};
    box-shadow: 0 5px 15px 0 #71fdb87d;
    width: 100%;
    height: 100%;
    position: absolute;
`;

const IconContainer = styled.div`
    ${radius};
    transition: all ease ${animationSpeed};
    position: fixed;
    bottom: 20px;
    width: 100px;
    height: 48px;
    left: 85px;
    right: auto;
    z-index: ${priority.streamPlayButton};
    box-shadow: 0 -1px 0px 0px #fff;
    background: #1ca963;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
        background: #40b77c;
    }

    &:hover img {
        transform: scale(1.1);
    }

    &.hidden {
        transform: scale(0);
    }
`;
const StyledIcon = styled(Icon).attrs({ src: playIcon })`
    transition: all ease ${animationSpeed};
    margin: 0 6px 0 14px;

    &.hidden {
        transform: scale(0);
    }
`;

const PLayStreamButton = props => {
    return (
        <IconContainer
            className={props.isFloatingStreamShown ? 'hidden' : ''}
            onClick={props.playStream}
        >
            <DropShadow />
            <Shine />
            <StyledIcon className={props.isFloatingStreamShown ? 'hidden' : ''} />
            <Text bold white size={'13'}>
                {t.liveStream}
            </Text>
        </IconContainer>
    );
};

const mapStateToProps = state => {
    return {
        isFloatingStreamShown: state.StreamVideo.isFloatingStreamShown,
    };
};

export default connect(mapStateToProps)(PLayStreamButton);
