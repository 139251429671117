import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Text from '../../components/Text'
import Icon from '../../components/Icon'
import Flex from '../../components/Flex'
import { detectMobile } from '../../utils/deviceDetect'
import angleLeft from '../../images/icons/angleLeft.svg'
import search from '../../images/icons/search.svg'
import wallet from '../../images/icons/wallet.svg'
import user from '../../images/icons/user.svg'

const Colors = {
    accent: '#FFD804',
    light: '#FFFFFF',
    semiWhite: 'rgba(255,255,255, 0.5)',
    dark: '#000000',
    grey: 'rgb(100, 100, 100)',
    semiDark: 'rgba(0,0,0, 0.6)',
}

const StyledAppNavigation = styled(Flex)`
    padding: 5px;
    box-sizing: border-box;
    height: 60px;
    ${!detectMobile() &&
        css`
            position: relative;
            left: 2px;
            justify-content: flex-end;
        `};
`
const LeftArea = styled.div`
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    ${!detectMobile() &&
        css`
            position: absolute;
            left: 0;
        `};
`
const RightContentWrapper = styled.div`
    display: flex;
    ${detectMobile()
        ? css`
              width: 100%;
          `
        : css`
              width: calc(50vw + 256px);
              @media (max-width: 1536px) {
                  width: 66vw;
              }
          `}
`
const LeftSideWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`
const SearchButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 1rem;
    border-radius: 5px;
    cursor: pointer;
    ${!detectMobile()
        ? css`
              background: rgba(255, 255, 255, 0.1);
              max-width: 254px;
              min-width: 140px;
              box-sizing: border-box;
              width: 100%;
              height: 100%;
              transition: all 0.2s ease;

              ${Icon} {
                  opacity: 0.5;
                  transition: all 0.2s ease;
              }
              &:hover {
                  background: rgba(255, 255, 255, 0.2);
                  ${Icon} {
                      opacity: 1;
                  }
              }
          `
        : css`
              margin-left: ${({ withoutSidebar }) =>
                  withoutSidebar ? '0' : '20px'};
          `};

    ${Text} {
        margin: 0;
        color: white;
    }
`
const ContentWrapper = styled.div`
    margin-left: auto;
    margin-right: 0;
    display: flex;
`
const UserAreaWrapper = styled.div`
    display: ${({ userEmail }) => (userEmail ? 'flex' : 'none')};
    align-items: center;
    margin-left: 0.5rem;
    ${detectMobile() &&
        css`
            flex-grow: 1;
            justify-content: flex-end;
        `};
`
const LoginWrapper = styled.div`
    margin-left: 0.5rem;
    display: ${({ userEmail }) => (!userEmail ? 'flex' : 'none')};
    ${detectMobile() &&
        css`
            flex-grow: 1;
            justify-content: flex-end;
        `};
`
const InfoText = styled(Text)`
    white-space: nowrap;
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Roboto Condensed', sans-serif;
    color: white;

    @media (max-width: 992px) {
        margin-right: 5px;
    }
`
const UserAreaButton = styled.div`
    cursor: pointer;
    width: auto;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    height: 44px;
    border-radius: 0.25rem;
    margin: 0 0.5rem 0 0;
    background: ${({ background }) => background || Colors.accent};

    @media (max-width: 992px) {
        padding: 0.4rem;
    }

    ${Icon} {
        margin-left: 0;
        @media (max-width: 992px) {
            margin: 0.5rem 0.7rem;
        }
    }
`
const ShowDesk = styled.div`
    display: flex;
    ${Text} {
        margin: 0 0 0 0.5rem;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: bold;
        font-size: 14px;
    }
    @media (max-width: 992px) {
        display: none;
    }
`
const EnterButton = styled.button`
    padding: 1rem 1.6rem;
    border-radius: 5px;
    font-size: 1rem;
    line-height: 1;
    display: inline-block;
    border: 1px solid transparent;
    transition: all 0.3s ease-out;
    margin-right: 0.5rem;
    cursor: pointer;
    background: ${Colors.accent};
    color: ${Colors.dark};
    font-weight: bold;
    font-family: 'Roboto Condensed', sans-serif;
    @media (max-width: 992px) {
        padding: 0.8rem;
    }
    @media screen and (max-width: 700px) {
        font-size: 14px;
        span {
            display: none;
        }
    }
`

const TopBar = ({
    onBackButtonClickHandler,
    background,
    searchHandler,
    userEmail,
    balance,
    secondColor,
    bankClickHandler,
    showUserArea,
    signUpHandler,
    showLoginModal,
    afterSearchArea,
    beforeUserArea,
    afterUserArea,
    withoutSidebar = false,
    showBackButton = false,
    isGameRunning = false,
}) => {
    const balanceFormatter = (x) => {
        if (!x) return
        return new Intl.NumberFormat('da-DK', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(x || 0)
    }
    return (
        <StyledAppNavigation
            background={background}
            justify={'space-between'}
            align={'center'}
            id={'app-navigation'}
        >
            <LeftArea>
                {detectMobile() && showBackButton && (
                    <Icon
                        ml={3}
                        src={angleLeft}
                        color={Colors.light}
                        size={showBackButton ? 'xl' : 'lg'}
                        onClick={onBackButtonClickHandler}
                    />
                )}
            </LeftArea>
            {(searchHandler || afterSearchArea) && (
                <LeftSideWrapper>
                    {searchHandler && (
                        <SearchButton
                            onClick={searchHandler}
                            withoutSidebar={withoutSidebar}
                        >
                            {!detectMobile() && <Text>Søg efter spil…</Text>}
                            <Icon
                                src={search}
                                color={Colors.light}
                                size={'md'}
                            />
                        </SearchButton>
                    )}
                    {afterSearchArea && <Fragment>{afterSearchArea}</Fragment>}
                </LeftSideWrapper>
            )}
            <RightContentWrapper>
                <ContentWrapper>
                    {beforeUserArea && <Fragment>{beforeUserArea}</Fragment>}
                    {userEmail && (
                        <UserAreaWrapper userEmail={userEmail}>
                            <UserAreaButton
                                background={Colors.accent}
                                onClick={bankClickHandler}
                            >
                                <Icon
                                    size={'md'}
                                    src={wallet}
                                    color={Colors.semiDark}
                                    my={2}
                                />
                                <ShowDesk>
                                    <Text color="#333">Indbetal</Text>
                                </ShowDesk>
                            </UserAreaButton>
                            <UserAreaButton
                                mx={1}
                                background={secondColor}
                                borderRadius={1}
                                onClick={showUserArea}
                            >
                                <Icon
                                    size={'md'}
                                    src={user}
                                    color={Colors.semiWhite}
                                    m={!isGameRunning ? 2 : 0}
                                />
                                {!isGameRunning && (
                                    <InfoText>
                                        {balanceFormatter(
                                            balance ? balance : '0'
                                        )}{' '}
                                        kr.
                                    </InfoText>
                                )}
                            </UserAreaButton>
                        </UserAreaWrapper>
                    )}
                    <LoginWrapper userEmail={userEmail}>
                        <EnterButton onClick={signUpHandler}>
                            Opret <span>konto</span>
                        </EnterButton>
                        <EnterButton
                            style={{
                                background: secondColor,
                                color: Colors.light,
                            }}
                            onClick={showLoginModal}
                        >
                            Log ind
                        </EnterButton>
                    </LoginWrapper>
                    {afterUserArea && <Fragment>{afterUserArea}</Fragment>}
                </ContentWrapper>
            </RightContentWrapper>
        </StyledAppNavigation>
    )
}

TopBar.propTypes = {
    background: PropTypes.string.isRequired,
    sideNavOpen: PropTypes.bool,
    sideNavHandler: PropTypes.func,
    searchHandler: PropTypes.func,
    isGameRunning: PropTypes.bool,
    userEmail: PropTypes.string,
    username: PropTypes.string,
    balance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    secondColor: PropTypes.string.isRequired,
    bankClickHandler: PropTypes.func.isRequired,
    showUserArea: PropTypes.func.isRequired,
    signUpHandler: PropTypes.func.isRequired,
    showLoginModal: PropTypes.func.isRequired,
    beforeUserArea: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    afterUserArea: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    afterSearchArea: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
}

export default TopBar
