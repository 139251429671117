import React from 'react';
import Clock from '../common/Clock';
import { RaceCardHeaderContainer, HeaderButton } from './styled';
import { RaceDay } from 'features/EventBoard/server/calendar';
import { AnyRace } from 'features/TrackPage/model/Race';
import getTexts from 'utils/localization';
const t = getTexts();

interface RaceCardHeaderProps {
    raceDay: RaceDay;
    race: AnyRace;
    reservesMode: boolean;
    toggleReservesMode(): void;
    isVStrictProduct: boolean;
    statsExpanded: boolean;
    toggleStats(): void;
}

const RaceCardHeader = ({
    raceDay,
    race,
    toggleReservesMode,
    reservesMode,
    isVStrictProduct,
    statsExpanded,
    toggleStats,
}: RaceCardHeaderProps) => {
    return (
        <RaceCardHeaderContainer>
            <Clock raceDay={raceDay} race={race} />

            <div className="flex gap-10">
                {isVStrictProduct && (
                    <HeaderButton active={reservesMode} onClick={toggleReservesMode}>
                        Reserve heste
                    </HeaderButton>
                )}
                <HeaderButton onClick={toggleStats}>
                    {statsExpanded
                        ? t.RacingCard.controlArea.viewStats
                        : t.RacingCard.controlArea.hideStats}
                </HeaderButton>
            </div>
        </RaceCardHeaderContainer>
    );
};

export default RaceCardHeader;
