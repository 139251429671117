import React from 'react';

import DownloadButton from './DownloadButton';
import getTexts from 'utils/localization';
import { trackEvent, trackingIDs } from 'utils/tracking';

import usePdfDownload from '../usePdfDownload';
import useATGPdfDownload from '../useATGPdfDownload';
import { downloadFileByLink } from 'utils/DOM';

const t = getTexts();

const PdfDownload = () => {
    const { fileAvailable: tipsPDFAvailable, downloadPdf: downloadTipsPDF } = usePdfDownload({
        type: 'tips',
    });
    const { fileAvailable: playlistPDFAvailable, downloadPdf: downloadPlaylistPDF } =
        usePdfDownload({
            type: 'play-list',
        });

    const { pdfAvailable: ATGPageWithPDFAvailable, PDFLink: ATGLink } = useATGPdfDownload();

    const downloadPdf = () => {
        downloadFileByLink(ATGLink, 'baneprogram.pdf', 'application/pdf');
    };

    const handleTipsPdfClick = () => {
        trackEvent({
            event: trackingIDs.trackProgramDownload,
            category: 'ux',
            action: t.PdfDownload.tips,
        });

        downloadTipsPDF();
    };

    const handlePlaylistPdfClick = () => {
        trackEvent({
            event: trackingIDs.trackProgramDownload,
            category: 'ux',
            action: t.PdfDownload.playList,
        });

        downloadPlaylistPDF();
    };

    const handleTrackProgramPdfClick = () => {
        trackEvent({
            event: trackingIDs.trackProgramDownload,
            category: 'ux',
            action: t.PdfDownload.courseProgram,
        });

        downloadPdf();
    };

    return (
        <div className="flex pr-10">
            {tipsPDFAvailable && (
                <DownloadButton onClick={handleTipsPdfClick}>{t.PdfDownload.tips}</DownloadButton>
            )}
            {playlistPDFAvailable && (
                <DownloadButton onClick={handlePlaylistPdfClick}>
                    {t.PdfDownload.playList}
                </DownloadButton>
            )}
            {ATGPageWithPDFAvailable && (
                <DownloadButton onClick={handleTrackProgramPdfClick}>
                    {t.PdfDownload.courseProgram}
                </DownloadButton>
            )}
        </div>
    );
};

export default PdfDownload;
