import React, { Component, Fragment } from 'react';
import BetButton from './BetButton';
import HorseWithJockey, { Jockey } from './HorseWithJockey';
import OddsIndicator from './OddsIndicator';
import { ColStarts, HorseCol, ShoeCol, TwinButtonsWrapper } from './styled';
import Wrapper from 'ui/Wrapper';
import {
    getCoach,
    getFormtal,
    getKRStart,
    getMoney,
    getStarter,
    getWeight,
} from 'features/TrackPage/components/RaceCard/Mobile/ShortStatistic';
import { PRODUCT_IDS, PRODUCTS_NAMES } from 'configs/products';
import TrioBetButtons from 'features/Game/TrioBetButtons';
import { excludedProducts } from 'features/Game/GameHeader';
import ShoeInfo from 'features/TrackPage/components/RaceCard/Desktop/ShoeInfo.jsx';
import { TableColumn, TableRow } from 'ui/Table';
import Checkbox from 'ui/Checkbox';

import { byPlatform } from 'utils/platforms';
import { forTerminal, isTerminal } from 'utils/platforms';
import { trackEvent, trackingIDs } from 'utils/tracking';

export const buttonsColWidth = {
    [PRODUCT_IDS.T]: byPlatform('145px', '120px'),
    [PRODUCT_IDS.TV]: byPlatform('90px', '70px'),
    [PRODUCT_IDS.K]: byPlatform('90px', '70px'),
};

export const buttonsColWidthDefault = '40px';

class Game extends Component {
    constructor() {
        super();

        this.state = {
            rowSelected: false,
        };
    }

    maybeTrackTwinUBtnClick = betValue => {
        if (this.props.product.id === PRODUCT_IDS.TV && betValue === 'U') {
            trackEvent({
                event: trackingIDs.racingCard.twinUBtn,
                action: 'u_twin',
                category: 'ux',
            });
        }
    };

    onActivate = betValue => {
        this.maybeTrackTwinUBtnClick(betValue);
        this.props.onBetAdded && this.props.onBetAdded(this.props.start.startNr, betValue);
    };

    onDisactivate = betValue => {
        this.maybeTrackTwinUBtnClick(betValue);
        this.props.onBetRemoved && this.props.onBetRemoved(this.props.start.startNr, betValue);

        if (isTerminal && this.state.rowSelected) {
            this.setState({
                rowSelected: false,
            });
        }
    };

    hideButtonsColumn = () => {
        const { race } = this.props;
        return !race.saleOpen || race.poolClosed;
    };

    toggleRowSelection = event => {
        event.stopPropagation();

        const { start } = this.props;
        if (start.scratched) {
            return;
        }

        this.setState(
            {
                rowSelected: !this.state.rowSelected,
            },
            () => {
                const handler = this.state.rowSelected
                    ? this.props.onBetAdded
                    : this.props.onBetRemoved;

                handler(start.startNr, 1);
                handler(start.startNr, 2);
            }
        );
    };

    renderButtonsComponent = () => {
        const {
            race,
            start,
            bet,
            raceStarted,
            product,
            addReserve,
            removeReserve,
            reserveModeEnabled,
            raceReserves,
        } = this.props;

        const raceFinished = !race.saleOpen || race.poolClosed;

        const commonBetButtonProps = {
            onActivate: this.onActivate,
            onDisactivate: this.onDisactivate,
            addReserve,
            removeReserve,
            reserveNumber: raceReserves.indexOf(start.startNr) + 1,
            reserved: product.isVProduct() && raceReserves.includes(start.startNr),
            reserveModeEnabled,
            raceStarted: raceStarted,
            raceNumber: race.raceNumber,
            bet: bet,
            betButtonStyles: {
                width: byPlatform(38, 30, 45),
            },
            startNumber: start.startNr,
            scratched: start.isScratched && start.isScratched(),
            raceFinished,
        };

        let gameView = null;
        if (this.props.product) {
            switch (this.props.product.name) {
                case PRODUCTS_NAMES.TV:
                    gameView = (
                        <TwinButtonsWrapper>
                            <BetButton betValue={start.startNr} {...commonBetButtonProps} />
                            <BetButton betValue={'U'} {...commonBetButtonProps} />
                        </TwinButtonsWrapper>
                    );
                    break;
                case PRODUCTS_NAMES.K:
                    gameView = (
                        <TwinButtonsWrapper>
                            {forTerminal(
                                <Checkbox
                                    checked={this.state.rowSelected}
                                    size="x2"
                                    styles={{
                                        height: '15px',
                                        margin: '10px',
                                    }}
                                    changeHandler={this.toggleRowSelection}
                                />
                            )}
                            <BetButton betValue={1} {...commonBetButtonProps} />
                            <BetButton betValue={2} {...commonBetButtonProps} />
                        </TwinButtonsWrapper>
                    );
                    break;
                case PRODUCTS_NAMES.T:
                    gameView = <TrioBetButtons {...commonBetButtonProps} />;
                    break;
                default:
                    gameView = <BetButton betValue={start.startNr} {...commonBetButtonProps} />;
            }

            return <div onClick={this.props.onClick}>{gameView}</div>;
        } else {
            return null;
        }
    };

    render() {
        const { start, gallop, product, bet, shoeInfo, race } = this.props;
        const raceFinished = !race.saleOpen || race.poolClosed;
        const showStartNumber =
            [PRODUCT_IDS.T, PRODUCT_IDS.K].includes(product.id) ||
            (raceFinished && PRODUCT_IDS.TV === product.id);

        if (!bet) {
            return null;
        }
        return (
            <Wrapper
                onClick={this.props.onClick}
                width="100%"
                padding="0px"
                align="center"
                data-test-id={start.isScratched && start.isScratched() ? 'scratched' : 'outright'}
            >
                <TableRow>
                    {!(raceFinished && (product.isTrio() || product.isTvilling())) && (
                        <TableColumn
                            basis={buttonsColWidth[product.id] ?? buttonsColWidthDefault}
                            pl={0}
                            pr={0}
                        >
                            {this.renderButtonsComponent()}
                        </TableColumn>
                    )}
                    <ColStarts grow={0.8}>
                        <HorseWithJockey start={start} showStartNr={showStartNumber} />
                    </ColStarts>

                    {forTerminal(
                        <ColStarts grow={0.8}>
                            <Jockey start={start} />
                        </ColStarts>
                    )}

                    {typeof product.isVProduct === 'function' &&
                        product.isVProduct() &&
                        product.id !== 'LD' &&
                        !excludedProducts.includes(product.id) && (
                            <TableColumn grow={0.15}>
                                <OddsIndicator
                                    odds={start.VPercentage}
                                    vPercentTrend={start.vPercentTrend}
                                    scratched={start.isScratched()}
                                    vPercentage
                                />
                            </TableColumn>
                        )}
                    <TableColumn grow={0.3}>
                        <OddsIndicator
                            odds={start ? start.getOdds(product) : '1.00'}
                            scratched={start.isScratched()}
                        />
                    </TableColumn>
                    {byPlatform(
                        null,
                        <Fragment>
                            <HorseCol short={1} grow={0.5}>
                                {getCoach(start)}
                            </HorseCol>
                            {gallop && (
                                <HorseCol short={1} grow={0.1}>
                                    {getFormtal(start)}
                                </HorseCol>
                            )}
                            <HorseCol center={1} grow={0.1}>
                                {gallop ? (
                                    getWeight(start)
                                ) : (
                                    <ShoeCol>
                                        <ShoeInfo
                                            frontShoe={shoeInfo ? shoeInfo.front : false}
                                            backShoe={shoeInfo ? shoeInfo.back : false}
                                        />
                                    </ShoeCol>
                                )}
                            </HorseCol>
                            <HorseCol grow={0.3}>{getMoney(start)}</HorseCol>
                            <HorseCol grow={0.3}>{getKRStart(start)} kr.</HorseCol>
                            <HorseCol center={1} grow={0.4}>
                                {getStarter(start)}
                            </HorseCol>
                        </Fragment>
                    )}
                </TableRow>
            </Wrapper>
        );
    }
}

export default Game;
