import React, { Fragment, lazy, Suspense } from 'react';
import { MODALS, SCREEN_MOVING_DURATION, SCREENS } from '../config';
import { AnimateMoving } from 'ui/Transition';
import { BackButton, CloseButton, ScreenWrapper } from '../components/styled';
import BetSlipScreen from './BetSlipScreen';
import Spinner from 'ui/Spinner';
import {
    CloseAll,
    CloseScreen,
    DisplayScreen,
    Screens as ScreensType,
    Shown,
} from '../hooks/useScreens';
import GroupInfoScreen from './GroupInfoScreen';
import GroupMember from '../model/Group';
import Pool from '../model/Pool';
import useUnwrappedModals from 'common/hooks/useUnwrappedModals';
import GroupAvatarScreen from './GroupAvatarScreen';
import CouponPreviewScreen from './CouponPreviewScreen';
import {
    RESET_BET_BUDDY_SELECTIONS,
    RESET_BET_BUDDY_SELECTIONS_MODE,
} from '../../BetSlip/state/actions';
import { useDispatch } from 'react-redux';
import ErrorBoundary from 'ui/ErrorBoundary';

const GroupFormScreen = lazy(() => import(`features/Spilklub/Mobile/GroupFormScreen`));
const GroupScreen = lazy(() => import(`../Mobile/GroupScreen`));
const PoolDashboardScreen = lazy(() => import(`../Mobile/PoolDashboardScreen`));
const FinalSelectionsScreen = lazy(() => import(`../Mobile/FinalSelectionsScreen`));
const ShareLinkScreen = lazy(() => import(`../Mobile/ShareLinkScreen`));

interface ScreensProps {
    closeScreen: CloseScreen;
    closeAll: CloseAll;
    displayScreen: DisplayScreen;
    screenShown: Shown;
    screens: ScreensType;
    onRootScreenClosed?(): void;
    onRootScreenOpen?(): void;
}

const Screens = ({
    closeScreen,
    displayScreen,
    screenShown,
    screens,
    onRootScreenClosed = () => {},
    onRootScreenOpen = () => {},
}: ScreensProps) => {
    const dispatch = useDispatch();
    const { show, hide, getData } = useUnwrappedModals();
    const rootModalData = getData(MODALS.BET_BUDDY_ENTRY);
    const groupId = rootModalData.groupId;

    return (
        <ErrorBoundary onClose={() => hide(MODALS.BET_BUDDY_ENTRY)}>
            <div style={{ height: '100%' }}>
                {screenShown(SCREENS.BET_SLIP) && (
                    <ScreenWrapper data-test-id="betslip">
                        <AnimateMoving
                            duration={SCREEN_MOVING_DURATION}
                            destroy={onRootScreenClosed}
                            onExpand={onRootScreenOpen}
                        >
                            {disappear => (
                                <Fragment>
                                    <CloseButton onClick={disappear} absolute />
                                    <BetSlipScreen
                                        onConfirm={(combinationPrice, combinationNumber) => {
                                            displayScreen(SCREENS.CREATE_GROUP, {
                                                combinationPrice,
                                                combinationNumber,
                                            });
                                        }}
                                    />
                                </Fragment>
                            )}
                        </AnimateMoving>
                    </ScreenWrapper>
                )}
                {screenShown(SCREENS.CREATE_GROUP) && (
                    <ScreenWrapper data-test-id="create-group">
                        <AnimateMoving
                            duration={SCREEN_MOVING_DURATION}
                            destroy={() => closeScreen(SCREENS.CREATE_GROUP)}
                            styles={{ height: '100%', overflowY: 'scroll' }}
                        >
                            {disappear => (
                                <Fragment>
                                    <BackButton onClick={disappear} absolute />
                                    <Suspense fallback={<Spinner />}>
                                        <GroupFormScreen
                                            {...screens[SCREENS.CREATE_GROUP]}
                                            onConfirm={() => displayScreen(SCREENS.SHARE_LINK)}
                                            showAvatarsScreen={() => displayScreen(SCREENS.AVATARS)}
                                        />
                                    </Suspense>
                                </Fragment>
                            )}
                        </AnimateMoving>
                    </ScreenWrapper>
                )}
                {screenShown(SCREENS.AVATARS) && (
                    <ScreenWrapper>
                        <AnimateMoving duration={200} destroy={() => closeScreen(SCREENS.AVATARS)}>
                            {disappear => <GroupAvatarScreen onClose={disappear} />}
                        </AnimateMoving>
                    </ScreenWrapper>
                )}
                {screenShown(SCREENS.SHARE_LINK) && (
                    <ScreenWrapper data-test-id="share-link">
                        <AnimateMoving
                            duration={SCREEN_MOVING_DURATION}
                            destroy={() => closeScreen(SCREENS.SHARE_LINK)}
                        >
                            {disappear => (
                                <Fragment>
                                    <Suspense fallback={<Spinner />}>
                                        <ShareLinkScreen
                                            {...screens[SCREENS.SHARE_LINK]}
                                            hideScreen={disappear}
                                            showGroupList={(showPool: boolean) =>
                                                displayScreen(SCREENS.GROUPS, {
                                                    showPool,
                                                })
                                            }
                                        />
                                    </Suspense>
                                </Fragment>
                            )}
                        </AnimateMoving>
                    </ScreenWrapper>
                )}

                {screenShown(SCREENS.GROUPS) && (
                    <ScreenWrapper data-test-id="groups">
                        <AnimateMoving
                            duration={SCREEN_MOVING_DURATION}
                            destroy={() => closeScreen(SCREENS.GROUPS)}
                        >
                            {disappear => (
                                <Suspense fallback={<Spinner />}>
                                    <GroupScreen
                                        {...screens[SCREENS.GROUPS]}
                                        close={disappear}
                                        showPoolDetails={() =>
                                            displayScreen(SCREENS.POOL_DASHBOARD)
                                        }
                                        groupId={groupId}
                                    />
                                </Suspense>
                            )}
                        </AnimateMoving>
                    </ScreenWrapper>
                )}
                {screenShown(SCREENS.POOL_DASHBOARD) && (
                    <ScreenWrapper data-test-id="pool-dashboard">
                        <AnimateMoving
                            duration={SCREEN_MOVING_DURATION}
                            destroy={() => closeScreen(SCREENS.POOL_DASHBOARD)}
                        >
                            {disappear => (
                                <Fragment>
                                    <BackButton onClick={disappear} absolute />

                                    <Suspense fallback={<Spinner />}>
                                        <PoolDashboardScreen
                                            {...screens[SCREENS.POOL_DASHBOARD]}
                                            showFinalSelections={() =>
                                                displayScreen(SCREENS.FINAL_SELECTIONS)
                                            }
                                            showGroupInfo={(
                                                pool: Pool,
                                                groupMembers: GroupMember[]
                                            ) =>
                                                displayScreen(SCREENS.GROUP_INFO, {
                                                    pool,
                                                    groupMembers,
                                                })
                                            }
                                        />
                                    </Suspense>
                                </Fragment>
                            )}
                        </AnimateMoving>
                    </ScreenWrapper>
                )}
                {screenShown(SCREENS.GROUP_INFO) && (
                    <ScreenWrapper data-test-id="group-info">
                        <AnimateMoving
                            duration={SCREEN_MOVING_DURATION}
                            destroy={() => closeScreen(SCREENS.GROUP_INFO)}
                        >
                            {disappear => (
                                <Fragment>
                                    <BackButton onClick={disappear} absolute />
                                    <Suspense fallback={<Spinner />}>
                                        <GroupInfoScreen
                                            {...screens[SCREENS.GROUP_INFO]}
                                            showFinalSelections={() =>
                                                displayScreen(SCREENS.FINAL_SELECTIONS)
                                            }
                                        />
                                    </Suspense>
                                </Fragment>
                            )}
                        </AnimateMoving>
                    </ScreenWrapper>
                )}
                {screenShown(SCREENS.FINAL_SELECTIONS) && (
                    <ScreenWrapper data-test-id="final-selections">
                        <AnimateMoving
                            duration={SCREEN_MOVING_DURATION}
                            destroy={() => closeScreen(SCREENS.FINAL_SELECTIONS)}
                        >
                            {disappear => (
                                <Fragment>
                                    <BackButton onClick={disappear} absolute />
                                    <Suspense fallback={<Spinner />}>
                                        <FinalSelectionsScreen
                                            showPoolDetails={() => {
                                                closeScreen(SCREENS.FINAL_SELECTIONS);
                                                show(MODALS.BET_BUDDY_ENTRY, {
                                                    showScreens: [
                                                        SCREENS.GROUPS,
                                                        SCREENS.POOL_DASHBOARD,
                                                    ],
                                                });
                                            }}
                                        />
                                    </Suspense>
                                </Fragment>
                            )}
                        </AnimateMoving>
                    </ScreenWrapper>
                )}
                {screenShown(SCREENS.COUPON_PREVIEW) && (
                    <ScreenWrapper>
                        <AnimateMoving
                            duration={SCREEN_MOVING_DURATION}
                            destroy={() => {
                                closeScreen(SCREENS.COUPON_PREVIEW);
                                dispatch({
                                    type: RESET_BET_BUDDY_SELECTIONS_MODE,
                                });
                                dispatch({
                                    type: RESET_BET_BUDDY_SELECTIONS,
                                });
                            }}
                        >
                            {disappear => (
                                <Fragment>
                                    <BackButton onClick={disappear} absolute />
                                    <Suspense fallback={<Spinner />}>
                                        <CouponPreviewScreen
                                            showPoolDetails={() => {
                                                closeScreen(SCREENS.COUPON_PREVIEW);
                                                show(MODALS.BET_BUDDY_ENTRY, {
                                                    showScreens: [
                                                        SCREENS.GROUPS,
                                                        SCREENS.POOL_DASHBOARD,
                                                    ],
                                                });
                                            }}
                                        />
                                    </Suspense>
                                </Fragment>
                            )}
                        </AnimateMoving>
                    </ScreenWrapper>
                )}
            </div>
        </ErrorBoundary>
    );
};

export default Screens;
