import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from '../../components/Icon'
import Flex from '../../components/Flex'
import DigitalClock from '../../components/DigitalClock'
import DigitalTimer from '../../components/DigitalTimer'
import WithClock from '../../common/hocs/WithClock'
import WithTimer from '../../common/hocs/WithTimer'
import rod from '../../images/casino.svg'
import rodLogo from '../../images/producs-logos/casino-text.svg'
import derby from '../../images/derby.png'
import derbyLogo from '../../images/producs-logos/heste-text.svg'
import bet from '../../images/bet.png'
import betLogo from '../../images/producs-logos/sport-text.svg'
import spill from '../../images/spillemyndigheden.png'
import ReactTooltip from 'react-tooltip'
import { bet25MainLogo } from '../../images'

const Clock = WithClock(DigitalClock)
const Timer = WithTimer(DigitalTimer)

const ToolbarWrapper = styled(Flex)`
    flex-basis: 100%;
    flex-direction: column;
`

const ProductsTransitionBarWrapper = styled.div`
    display: flex;
    box-sizing: border-box;
    width: 100%;
    max-width: 100vw;
    position: relative;
    background: ${({ background }) => (background ? background : '1C3E60')};
    justify-content: space-between;
    padding: 8px;
    align-items: center;
    height: 60px;
    ${({ noBorder }) =>
        !noBorder && 'border-bottom: 1px solid rgba(255, 255, 255, 0.1)'};

    .tooltip {
        opacity: 1 !important;
        font-size: 14px;
        font-weight: bold;
        color: #505050;
    }
    .mobile-time {
        display: none;
    }
    @media screen and (max-width: 1200px) {
        .tooltip {
            display: none !important;
        }
    }
    @media screen and (max-width: 700px) {
        flex-wrap: wrap;
        padding: 0 10px;
        font-size: 10px;
        height: 80px;
        border-bottom: none;

        .mobile-time {
            display: flex;
            margin: 0 0 0 14px;
        }
        .desktop-time {
            display: none;
        }
    }
`
const ItemWrapper = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 12px;
    height: 100%;
    text-decoration: none;
    color: white;
    font-size: 12px;
    white-space: nowrap;

    img {
        height: 20px;
    }
    &:hover {
        background: rgba(255, 255, 255, 0.1);
    }
    @media screen and (max-width: 1400px) {
        //1250 (~1200px) as edge case
        padding: 12px 5px;
    }
    @media screen and (max-width: 700px) {
        padding: 5px 2px;
        font-size: 10px;
    }
`

const ItemLogoWrapper = styled(ItemWrapper)`
    cursor: pointer;
    display: ${({ hideOnMob }) => (!hideOnMob ? 'none' : 'flex')};
    @media screen and (max-width: 700px) {
        display: ${({ hideOnMob }) => (hideOnMob ? 'none' : 'flex')};
        margin-right: 50px;
    }
`

const FlexWrapper = styled.div`
    display: flex;
    justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
    align-items: center;
    height: 100%;
    width: ${({ width }) => (width ? width : 'auto')};

    @media screen and (max-width: 1050px) {
        width: auto;
    }

    @media screen and (max-width: 700px) {
        width: 100%;
        height: 40px;
        justify-content: space-between;
    }
`

const LogoWrapper = styled.a`
    overflow: hidden;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    border-radius: 8px;
    height: 100%;
    width: 32%;
    position: relative;
    padding: 0 15px;
    transition: all 0.3s ease;
    background: ${({ bg }) => bg};
    &:hover {
        background: ${({ hoverBg }) => hoverBg};
    }

    @media screen and (max-width: 1150px) {
        ${({ isActive }) =>
            isActive ? 'img {opacity: 1};' : 'img {opacity: 0.5};'};
    }
`
const StyledIcon = styled.img`
    height: ${({ height }) => height || '20px'};
`
const ProductImg = styled.img`
    margin: ${({ margin }) => (margin ? margin : '0')};
    height: ${({ height }) => (height ? height : '120%')};
`
const LogosWrapper = styled(FlexWrapper)`
    display: flex;
    height: 100%;
    max-height: 43px;
    width: 480px;
    justify-content: ${({ center }) => (center ? 'center' : 'space-between')};
    align-items: center;
    margin-right: 15px;
    @media screen and (max-width: 1150px) {
        width: 250px;
        height: 40px;

        ${ProductImg} {
            display: none;
        }
        ${LogoWrapper} {
            position: relative;
            background: none;
            justify-content: center;
        }
    }
    @media screen and (max-width: 700px) {
        width: 100%;
        ${({ center }) => center && 'justify-content: center;'};
    }
`

const TimeWrapper = styled(FlexWrapper)`
    font-size: 1rem;
    width: 33%;
    justify-content: flex-end;
    @media screen and (max-width: 1050px) {
        width: auto;
    }
    @media (max-width: 700px) {
        font-size: 9px;
        justify-content: space-between;
        width: 100%;
    }
    ${Icon} {
        @media screen and (max-width: 374px) {
            height: 16px;
            width: 12px;
        }
    }
`

const ProductsTransitionBar = ({
    simple = false,
    customBetLogo = '',
    rofusLogo = spill,
    derbyImage = derby,
    betImage = bet,
    rodImage = rod,
    activeProject = 'bet',
    rodLink = 'https://www.roed25.dk/',
    betLink = 'https://www.bet25.dk/',
    derbyLink = 'https://www.derby25.dk/',
    logoOnClickHandler,
    ...props
}) => {
    const timePart = (type) => (
        <TimeWrapper className={type}>
            <ItemWrapper
                data-tip="StopSpillet"
                href="https://www.stopspillet.dk/"
                target="_blank"
            >
                Hjælpelinje: StopSpillet
            </ItemWrapper>
            <ItemWrapper
                href="https://www.rofus.nu/"
                target="_blank"
                data-tip="Frivilligt udelukkelse via ROFUS"
            >
                Udeluk dig via ROFUS
            </ItemWrapper>
            <ItemWrapper
                href="https://intercom.help/25syv/da/articles/3493600-ansvarligt-spil-25syv"
                target="_blank"
                data-tip="Vejledning om ansvarligt spil"
            >
                Spil ansvarligt
            </ItemWrapper>

            {props.showTimer ? (
                <Fragment>
                    <ItemWrapper data-tip="Mit tidsforbrug">
                        <Timer
                            countFrom={props.lastLoginTime}
                            color={props.color}
                            displayFormat={props.timerFormat}
                        />
                    </ItemWrapper>
                </Fragment>
            ) : (
                <Fragment>
                    <ItemWrapper data-tip="Klokken">
                        <Clock
                            color={props.color}
                            displayFormat={props.clockFormat}
                        />
                    </ItemWrapper>
                </Fragment>
            )}
        </TimeWrapper>
    )
    return (
        <ToolbarWrapper id={'product-transition-bar'}>
            <ProductsTransitionBarWrapper {...props} noBorder={simple}>
                <FlexWrapper width="33.3%">
                    <ItemLogoWrapper
                        hideOnMob={true}
                        onClick={() =>
                            logoOnClickHandler
                                ? logoOnClickHandler()
                                : (window.location = '/')
                        }
                    >
                        <img
                            src={bet25MainLogo}
                            alt="Bet25"
                            width="65px"
                            height="32px"
                        />
                    </ItemLogoWrapper>
                    <ItemWrapper
                        data-tip="Spillemyndigheden"
                        href="https://www.spillemyndigheden.dk/"
                        target="_blank"
                    >
                        <img
                            src={rofusLogo}
                            alt="rofusLogo"
                            width="20px"
                            height="20px"
                        />
                    </ItemWrapper>
                    <ItemWrapper data-tip="Aldersgrænse">18+</ItemWrapper>

                    {timePart('mobile-time')}
                </FlexWrapper>
                <LogosWrapper center={simple}>
                    <ItemLogoWrapper
                        data-tip="Spillemyndigheden"
                        hideOnMob={false}
                        onClick={() =>
                            logoOnClickHandler
                                ? logoOnClickHandler()
                                : (window.location = '/')
                        }
                    >
                        <img
                            src={bet25MainLogo}
                            alt="Bet25"
                            width="65px"
                            height="32px"
                        />
                    </ItemLogoWrapper>

                    <LogoWrapper
                        data-tip="Danmarks nye millionspil: Spil & vind på heste"
                        href={derbyLink}
                        isActive={activeProject === 'derby'}
                        hoverBg="#11884D"
                        bg="#1ABB6D"
                    >
                        <StyledIcon src={derbyLogo} alt="derbyLogo" />
                        <ProductImg
                            src={derbyImage}
                            margin="10px 0 0 0 "
                            height="125%"
                        />
                    </LogoWrapper>
                    <LogoWrapper
                        data-tip="Odds og livebetting i særklasse"
                        href={betLink}
                        isActive={activeProject === 'bet'}
                        hoverBg="#20486F"
                        bg="#112840"
                    >
                        <StyledIcon src={betLogo} alt="betLogo" />
                        {!simple && (
                            <ProductImg
                                src={betImage}
                                margin="10px 0 0 0 "
                                height="120%"
                            />
                        )}
                    </LogoWrapper>
                    <LogoWrapper
                        data-tip="Danmarks hyggeligste online casino"
                        href={rodLink}
                        isActive={activeProject === 'rod'}
                        hoverBg="#E63837"
                        bg="#B81514"
                    >
                        <StyledIcon src={rodLogo} alt="rodLogo" />
                        <ProductImg
                            src={rodImage}
                            margin="11px 0 0 0 "
                            height="73%"
                        />
                    </LogoWrapper>
                </LogosWrapper>
                {timePart('desktop-time')}
                <ReactTooltip
                    border={true}
                    borderColor="#70707033"
                    className="tooltip"
                    type="light"
                    place="bottom"
                    effect="solid"
                />
            </ProductsTransitionBarWrapper>
        </ToolbarWrapper>
    )
}

ProductsTransitionBar.propTypes = {
    betImage: PropTypes.string,
    derbyImage: PropTypes.string,
    rodImage: PropTypes.string,
    rodLink: PropTypes.string,
    betLink: PropTypes.string,
    derbyLink: PropTypes.string,
    activeProject: PropTypes.oneOf(['derby', 'rod', 'bet']),
    background: PropTypes.string,
    color: PropTypes.string,
    clockFormat: PropTypes.string,
    timerFormat: PropTypes.string,
    rofusLogo: PropTypes.string,
    hideMiddlePart: PropTypes.bool,
    logoOnClickHandler: PropTypes.func,
}

export default ProductsTransitionBar
