import PunterREST from 'common/api/PunterREST';
import { sha256 } from 'utils/crypto';
import isObject from 'lodash/isObject';
import persistentStorage from 'common/storage';
import {
    STREAM_MODE_ATG,
    STREAM_MODE_FR,
    STREAM_MODE_FT,
    STREAM_MODE_FTP,
    STREAM_MODE_SA,
    STREAM_MODE_UK,
} from '../features/StreamVideo/config';

const punter = PunterREST();

export const trackingIDs = {
    deposit: {
        topBar: 'ux_A0001',
        accountArea: 'ux_A0001B',
        amounts: 'ux_A0002',
    },
    betslipAmounts: 'ux_A0003',
    trackProgramDownload: 'ux_A0004',
    liveStreamBtn: 'shortcuts_A0001',
    spilklub: {
        shareGroupViaEmail: 'shortcuts_A0002',
        subscribeToGroup: 'ux_A0005',
    },
    closeHighlightsBanner: 'ux_A0006',
    anySidebarLinkClick: 'ux_A0007',
    spiltipsReadMoreBtn: 'ux_A0008',
    racingCard: {
        openStats: 'ux_A0009',
        openStatsMobile: 'shortcuts_A0008',
        trackBtn: 'ux_A0012',
        selectAllBtn: 'shortcuts_A0003',
        twinUBtn: 'ux_A0010',
        cleanCoupon: 'shortcuts_A0010',
        VRaceOverviewCheckbox: 'ux_A0011',
    },
    homepage: {
        shortcuts: 'shortcuts_A0004',
        spilklubGroups: 'shortcuts_A0005',
        lynspilTypes: 'shortcuts_A0006',
    },
    topbar: {
        brandLinks: 'shortcuts_A0007',
    },
    userArea: {
        logoutBtn: 'shortcuts_A0009',
    },
    livestreamFloatingWidget: {
        [STREAM_MODE_FT]: { id: 'stream_A0001', action: 'A1' },
        [STREAM_MODE_FTP]: { id: 'stream_A0002', action: 'A2' },
        [STREAM_MODE_ATG]: { id: 'stream_A0003', action: 'A3' },
        [STREAM_MODE_FR]: { id: 'stream_B0001', action: 'B1' },
        [STREAM_MODE_SA]: { id: 'stream_B0002', action: 'B2' },
        [STREAM_MODE_UK]: { id: 'stream_B0003', action: 'B3' },
    },
    livestreamPlayPause: 'shortcuts_A0001B',
};

export const trackEvent = (event, trackData = false) => {
    const eventName = isObject(event) ? event.event : event;

    window.dataLayer &&
        window.dataLayer.push({
            event: eventName,
            ...(isObject(event) && event),
        });

    trackData && trackUserData();
};

export const trackUserData = async () => {
    const user = await punter.getUser({ silent: true }).then(res => {
        if (res?.success) {
            return res.data;
        }
        return false;
    });

    if (!user) {
        return;
    }

    const userData = {
        fn: await sha256(user.firstName),
        ln: await sha256(user.lastName),
        em: await sha256(user.email),
        Pn: await sha256(user.phoneNr),
    };

    persistentStorage.saveLS(userData);
};

export const withEventTracking =
    (fn, event) =>
    (...args) => {
        trackEvent(event);
        return fn(...args);
    };
