import md5 from 'md5';
import Conductor from './Conductor';
import PunterREST from 'common/api/PunterREST';
import CmsREST from 'common/api/CMSAPI';
import DepositLimitDTO from 'features/UserArea/DepositLimits/DepositLimitDTO';
import Payout from 'features/UserArea/Withdraw/Payout';
import User from 'common/DataObjects/User';
import Profile from 'features/UserArea/UserProfile/Profile';
import {
    EXCLUSION_TYPE_24_H,
    EXCLUSION_TYPE_MONTH,
    EXCLUSION_TYPE_PERMANENT,
} from 'features/UserArea/SelfExclusion';
import DepositBonus from 'features/UserArea/Deposit/Bonus';
import { toggleMailChimpSubscription } from 'common/api/ContentAPI';
import getTexts from 'utils/localization';
import { deserializeUserStatus } from 'common/DataObjects/UserStatus.ts';
import { sha512 } from 'utils/crypto';
import { makeRequest } from 'utils/REST';
import omit from 'lodash/omit';
import { setTimeoutPromise } from '../../utils/promise';

const t = getTexts();

class UserConductor extends Conductor {
    constructor() {
        const interfacesByLocales = {
            DK: {
                punter: PunterREST(),
                cms: CmsREST,
            },
        };

        super(interfacesByLocales);
    }

    /**
     * Get current user data
     * @returns {Promise.<User>}
     */
    getUser() {
        return this.handler.punter.getUser().then(body => User.unserialize(body.data));
    }

    /**
     * @param   {string} userName
     * @param   {string} password
     * @returns {Promise.<User>}
     */
    login(userName, password) {
        return this.handler.punter.login(userName, password).then(body => {
            if (body.success) {
                let serializedUser = User.unserialize(body.data);

                this.attachUserToDevice(serializedUser);
                this.setLastLoginDate(serializedUser.id);

                return serializedUser;
            } else {
                throw body;
            }
        });
    }

    /**
     * @param   {string} result
     * @param   {string} content
     * @returns {Promise.<User>}
     */

    /**
     * @returns {Promise.<User|false>}
     */
    isUserLoggedIn() {
        return this.handler.punter.isUserLoggedIn().then(body => {
            return body.success ? User.unserialize(body.data) : false;
        });
    }

    logout() {
        return this.handler.punter.logout();
    }

    /**
     * @returns {Promise.<DepositLimitDTO>}
     */
    getDepositLimits() {
        return this.handler.punter.getDepositLimits().then(res => {
            if (!res.success) {
                throw res.errorMessage;
            }
            return DepositLimitDTO.unserialize(res.data);
        });
    }

    /**
     * @param   {DepositLimitDTO}           limit
     * @returns {Promise.<DepositLimitDTO>}
     */
    setDepositLimits(limit) {
        return this.handler.punter.setDepositLimits(limit.serialize()).then(res => {
            if (!res.success) {
                throw res.errorMessage;
            }
            return DepositLimitDTO.unserialize(res.data);
        });
    }

    /**
     * @return {Promise.<Array>.<DepositBonus>}
     */
    getDepositBonuses() {
        return this.handler.punter.getDepositBonuses().then(res => {
            if (!res.success && res.statusCode === 401) {
                throw t.sessionExpired;
            }

            // Uncomment for testing
            // return [
            //     {
            //         externalId: 12259,
            //         type: 'D',
            //         action: 'D',
            //         percent: 100,
            //         maxAmount: 100,
            //         minAmount: 50,
            //         rollover: 3,
            //     },
            // ];

            return res.data
                .map(bonus => DepositBonus.unserialize(bonus))
                .filter(bonus => bonus.isAvailable());
        });
    }

    getUserStatus() {
        return this.handler.punter.getUserStatus().then(res => {
            if (!res.success && res.statusCode === 401) {
                throw res.message;
            }
            return deserializeUserStatus(res.data);
        });
    }

    /**
     * A payout is an object of containing state of users
     * status of withdrawal operations.
     * @returns {Promise.<Payout>}
     */
    getPayout() {
        let userTmp;

        return this.getUser()
            .then(user => {
                userTmp = user;
                return userTmp;
            })
            .then(this.handler.punter.getPayout)
            .then(body => {
                const {
                    pendingBalance,
                    minOdds,
                    amount,
                    bonus,
                    rollover,
                    remainingCashinWageringAmount,
                    remainingCreditWageringAmount,
                    totalBonus,
                } = body.data;

                return new Payout(
                    userTmp,
                    pendingBalance,
                    minOdds,
                    amount,
                    bonus,
                    rollover,
                    remainingCashinWageringAmount,
                    remainingCreditWageringAmount,
                    totalBonus
                );
            });
    }

    /**
     * Request withdraw / payout operation
     * @param   {number}          amount
     * @returns {Promise.<User>}
     */
    withdraw(amount) {
        return this.handler.punter.withdraw(amount);
    }

    /**
     * Set new pin login for the user
     * Device in CMS will be updated
     * @param {number} pin
     */
    setPin(pin) {
        return this.handler.punter.setPin(pin).then(res => res.data);
    }

    resetPin() {
        return this.handler.punter.clearHash().then(res => {
            if (res.success) {
                return res.data;
            }
            return Promise.reject(res.errorMessage || 'PunterREST.clearHash responded with error.');
        });
    }

    actualizeHash() {
        return this.handler.punter.fetchHash().then(res => {
            if (res.success) {
                return res.data;
            }

            return Promise.reject(res.errorMessage || 'PunterREST.fetchHash responded with error.');
        });
    }

    pinLogin(pin, pinHash, userName) {
        return this.handler.punter.pinLogin(pin, pinHash, userName).then(res => {
            if (!res.success) {
                return Promise.reject(res);
            }
            return User.unserialize(res.data);
        });
    }

    registerUserDeviceInCMS() {
        let uniqDeviceHash = this.generateUseragentId();
        this.handler.cms
            .registerDevice({
                hardware_id: uniqDeviceHash,
                useragent_id: uniqDeviceHash,
                mobileApp: 'bet25-web',
            })
            .catch(e => console.log('CMS respond with error:', e));
    }

    attachUserToDevice(user) {
        let uniqDeviceHash = this.generateUseragentId();
        this.handler.cms
            .attachUser({
                user_id: user.id,
                fullname: `${user.firstName ? user.firstName : ''} ${
                    user.lastName ? user.lastName : ''
                }`,
                email: user.email,
                balance: user.balance,
                username: user.userName,
                hardware_id: uniqDeviceHash,
                useragent_id: uniqDeviceHash,
                mobileApp: 'bet25-web',
            })
            .catch(e => console.log('CMS respond with error:', e));
    }

    setLastLoginDate(userId) {
        let uniqDeviceHash = this.generateUseragentId();
        this.handler.cms
            .lastLogin({
                user_id: userId,
                mobileApp: 'bet25-web',
                hardware_id: uniqDeviceHash,
                useragent_id: uniqDeviceHash,
            })
            .catch(e => console.log('CMS respond with error:', e));
    }

    /**
     * Creates specific hash which will allow to identify client
     */
    generateUseragentId() {
        const hash = md5(window.navigator.userAgent + 'bet25-web');

        return `web-application-${hash}`;
    }

    enableNewsletterSubscription() {
        return this.handler.punter.enableNewsletterSubscription();
    }

    disableNewsletterSubscription(email) {
        return this.handler.punter.disableNewsletterSubscription(email);
    }

    setForcedPermission(checked = false, email) {
        return this.handler.punter.setForcedPermission({
            on: checked ? 1 : 0,
            email,
        });
    }

    /**
     * Returns updated status of newsletter
     * subscription after the toggling in PasPro
     * After updating in PasPro, it's send request to
     * MainChimp (subscribe/unsubscribe)
     * @returns {Promise.<boolean>}
     */
    async toggleNewsletterSubscription() {
        const user = await this.isUserLoggedIn();

        if (!user) {
            return Promise.reject('Can not get a current user subscription information.');
        }

        if (user.hasNewsletterSubscription) {
            toggleMailChimpSubscription({
                task: 'unsubscribe',
                email: user.email,
            }).catch(e => {
                console.log(e);
            });

            return this.handler.punter.disableNewsletterSubscription(user.email);
        } else {
            let data = {
                userId: user.id,
                email: user.email,
                fname: user.firstName,
                lname: user.lastName,
                userName: user.userName,
                task: 'subscribe',
            };

            toggleMailChimpSubscription(data).catch(e => {
                console.log(e);
            });

            return this.handler.punter.enableNewsletterSubscription();
        }
    }

    /**
     *
     * @param  {string} period
     * @return {Promise.<null>}
     */
    excludeSelf(period) {
        switch (period) {
            case EXCLUSION_TYPE_24_H:
                return this.handler.punter.excludeUserDays(1);
            case EXCLUSION_TYPE_MONTH:
                return this.handler.punter.excludeUserByPeriod('ex_1m');
            case EXCLUSION_TYPE_PERMANENT:
                return this.handler.punter.excludeUserPermanently();
            default:
                return Promise.resolve(null);
        }
    }

    signupStep1(cpr) {
        return this.handler.punter.signup.step1(cpr);
    }

    signupStep2(result, signature) {
        return this.handler.punter.signup.step2(result, signature);
    }

    signupStep34(UserData) {
        return this.handler.punter.signup.step34(UserData);
    }

    storePunter(userData) {
        const defaultValues = {
            currency: 'DKK',
            country: 'DK',
            code_note: 'Signupbonus bet25.dk',
        };
        const getBirthday = cpr => {
            const day = cpr.substr(0, 2);
            const month = cpr.substr(2, 2);
            const year = cpr.substr(4, 2);
            return `${day}-${month}-19${year}`;
        };
        const getGender = cpr => {
            const lastLetter = cpr.slice(-1);
            if (lastLetter % 2 === 0) {
                return 'F';
            } else {
                return 'M';
            }
        };
        const getFirstName = fullName => {
            let lastIndex = fullName.lastIndexOf(' ');

            return fullName.substring(0, lastIndex);
        };

        userData = {
            userName: userData.username,
            password: userData.password,
            firstName: getFirstName(userData.fullName),
            lastName: userData.fullName.split(' ').at(-1),
            email: userData.email,
            currency: defaultValues.currency,
            birthDate: getBirthday(userData.cpr),
            gender: getGender(userData.cpr),
            address: userData.address,
            country: defaultValues.country,
            phoneNr: userData.phoneNumber,
            mobileNr: userData.phoneNumber,
            bonusCode: userData.bonusCode,
            bonusCodeNote: defaultValues.code_note,
            mailList: userData.newsletterSub,
            securityAnswer: userData.pinCode,
            expectBetting: userData.expectBetting,
            dailyDepositLimit: userData.dailyDepositLimit,
            weeklyDepositLimit: userData.weeklyDepositLimit,
            monthlyDepositLimit: userData.monthlyDepositLimit,
            cprNumber: userData.cpr,
        };

        const params = omit({ ...userData }, ['cprNumber']);

        return this.handler.punter.signup.storePunter(params);
    }

    register() {
        return this.handler.punter.signup.register();
    }

    resetPassword(userName, password) {
        return this.handler.punter.resetPassword(userName, password);
    }

    startMitId(cpr, idp) {
        // This method doesn't have /punter url segment, so calling without punter service
        return makeRequest('post', '/rest/mitId/start', { cpr, idp })
            .then(response => {
                return response.json();
            })
            .then(data => {
                return data;
            });
    }

    getSecurityQuestionList() {
        return this.handler.punter.getSecurityQuestionList();
    }

    getExpectedBetting() {
        return this.handler.punter.getExpectedBetting();
    }

    getTransactionListAll(dateFrom, dateTo, start, end) {
        return this.handler.punter.getTransactionListAll(dateFrom, dateTo, start, end);
    }
    getTransactionList(dateFrom, dateTo) {
        return this.handler.punter.getTransactionList(dateFrom, dateTo);
    }

    /**
     * @returns {Promise.<Profile>}
     */
    getProfile() {
        return this.handler.punter
            .getProfile()
            .then(res => {
                if (!res.success) {
                    return Promise.reject(res.errorMessage);
                }
                return Profile.unserialize(res.data);
            })
            .catch(e => console.log(e));
    }

    /**
     * @param   {Profile}            profile
     * @returns {Promise.<Profile>}
     */
    updateProfile(profile) {
        return this.handler.punter.updateProfile(profile.serialize()).then(() => profile);
    }

    /**
     *
     * @param  {string} currentPassword
     * @param  {string} newPassword
     * @param  {string} passwordConfirm
     * @return {Promise.<null>}
     */
    updatePassword(currentPassword, newPassword, passwordConfirm) {
        return this.handler.punter.updatePassword(currentPassword, newPassword, passwordConfirm);
    }

    /**
     *
     * @param  {integer} bonusCode
     * @param  {string} bonusNote
     * @return {Promise.<null>}
     */
    addTeam(bonusCode, bonusNote) {
        return this.handler.punter.addTeam(bonusCode, bonusNote);
    }

    applyVoucher(serial, code) {
        return this.handler.punter.depositVoucher(serial, code).then(res => {
            if (res.success) {
                return res.data;
            }
            throw res.errorMessage;
        });
    }
    setExpectedBetting(expectedBet) {
        return this.handler.punter.setExpectedBetting(expectedBet);
    }

    checkEmail(email) {
        const salt = '1238ASvNkjsfaw23v.da!)&efu';
        return sha512(email + salt).then(hash => {
            return this.handler.punter.checkEmail(email, hash);
        });
    }

    checkUserName(username) {
        const salt = '1238ASvNkjsfaw23v.da!)&efu';
        return sha512(username + salt).then(hash => {
            return this.handler.punter.checkUserName(username, hash);
        });
    }

    getActiveBonus() {
        return this.handler.punter.getActiveBonus();
    }

    forfeitBonus() {
        return this.handler.punter.forfeitBonus();
    }

    setRetailPin({ pin }) {
        return this.handler.punter.setRetailPin({ pin });
    }

    isLoginAttemptsExceeded(loginResponse) {
        return (
            !loginResponse.success &&
            loginResponse.statusCode === 400 &&
            loginResponse.data === true
        );
    }

    payoutRetailCoupon({ couponId, saleId }) {
        return this.handler.punter.payoutRetailCoupon({ couponId, saleId }).then(res => {
            if (res.success) {
                return true;
            }
            return Promise.reject(res.errorMessage);
        });
    }
}

export default new UserConductor();
