import React from 'react';

import useToggle from 'common/hooks/useToggle';

import Icon from 'ui/Icon';
import Text from 'ui/Text';

import usePreventDefault from 'common/hooks/usePreventDefault';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';

import { byPlatform } from 'utils/platforms';
import { thousandsDots } from 'utils/formatters';

import {
    ForecastContainer,
    Table,
    TableHeader,
    TableContent,
    TableSubHeader,
    SubHeaderCell,
    TableRow,
    TableCell,
    HorseNrBtn,
    ExpandBtn,
    ForecastTooltip,
    QuestionMark,
} from './styled';

import { VRace } from 'features/TrackPage/model/Race';

function getForecastRace(races: VRace[]) {
    return races.find(race => {
        return race?.horses.some(horse => horse?.VPool?.possibleValue);
    });
}

const Forecast = () => {
    const { races, isVProduct, product, calendarLoading, racesLoading } = useTrackPage();
    const vRaces = races as VRace[];

    const forecastRace = getForecastRace(vRaces);

    const [shown, toggle, delayedShown] = useToggle(false, 500);
    const toggleFn = usePreventDefault(toggle, true);

    if (!forecastRace || calendarLoading || racesLoading || !isVProduct) {
        return null;
    }

    return (
        <ForecastContainer>
            <Table>
                <TableHeader shown={delayedShown} onClick={toggleFn}>
                    <div>
                        <Text white size={'x1.5'} weight={'700'}>
                            {product.id} værdier efter {forecastRace.index + 1}. afd.
                        </Text>
                        <QuestionMark
                            data-tip
                            data-place={'top'}
                            data-type={'dark'}
                            data-effect={'solid'}
                            data-for={'forecast-tooltip'}
                            onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();
                            }}
                        >
                            ?
                        </QuestionMark>
                    </div>
                    <ExpandBtn onClick={toggleFn}>
                        <Icon
                            iarrowsmooth
                            size="x025"
                            style={{
                                position: 'relative',
                                top: shown ? 0 : '-2px',
                                transition: 'all 200ms linear',
                            }}
                            rotation={shown ? 180 : undefined}
                        />
                    </ExpandBtn>
                </TableHeader>
                <TableContent shown={shown}>
                    <TableSubHeader>
                        <SubHeaderCell style={{ justifySelf: 'center' }}>Nr.</SubHeaderCell>
                        <SubHeaderCell>Hest</SubHeaderCell>
                        <SubHeaderCell>Værdi</SubHeaderCell>
                    </TableSubHeader>
                    {/* Content */}
                    {forecastRace.horses.map((horse, i) => (
                        <TableRow key={i}>
                            <TableCell style={{ justifySelf: 'center' }}>
                                <HorseNrBtn>{i + 1}</HorseNrBtn>
                            </TableCell>
                            <TableCell style={{ fontWeight: 900 }}>
                                {horse?.horse?.horseNameAndNationality}
                            </TableCell>
                            <TableCell style={{ fontSize: '13px' }}>
                                {horse?.VPool?.possibleValue
                                    ? thousandsDots(horse?.VPool?.possibleValue) + ' KR.'
                                    : horse?.vpPool?.scratched
                                    ? 'Udgået'
                                    : '-'}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableContent>
            </Table>
            <ForecastTooltip id={'forecast-tooltip'} backgroundColor={'#333333'}>
                <Text white size={'x07'} width={byPlatform('290px', '320px')} condensed>
                    Denne prognose estimerer den potentielle udbetaling for dit V-spil baseret på
                    aktuelle odds og spilmønstre. Den hjælper dig med at forstå, hvor meget du
                    potentielt kan vinde, hvis dine valgte heste vinder deres løb.
                </Text>
                <Text white size={'x07'} width={byPlatform('290px', '320px')} condensed>
                    Hvis en hest er markeret med ‘*’, betyder det enten, at ingen spillere har
                    gættet alle resultater korrekt, eller at gevinstens værdi overstiger 200.000 kr.
                </Text>
            </ForecastTooltip>
        </ForecastContainer>
    );
};

export default Forecast;
