import styled from 'styled-components';
import React, { useState } from 'react';
import ValidInput from 'ui/controls/ValidInput';
import Button from 'ui/controls/Button';

import getTexts from 'utils/localization';
import { byPlatform } from '../../../utils/platforms';
const t = getTexts();

const Form = styled.div`
    display: flex;
    height: 80px;
    align-items: center;
    flex-wrap: nowrap;
`;

const InputWrapper = styled.div`
    flex-grow: 2;
    > div {
        border-radius: ${props => props.borderRadius};
        margin-left: 5px;
        margin-right: 5px;
    }
`;

const SetLimitBtn = styled(Button)`
    border-radius: ${props => props.borderRadius};
    font-size: 13px;
    height: 50px;
    width: 90px;
    padding: 0;
`;

const BoldInput = styled(ValidInput)`
    font-weight: bold;
`;

const LimitForm = ({
    onChange,
    initialValue,
    value,
    borderRadius,
    submit,
    validate: { min, max },
}) => {
    const [isValid, setValidity] = useState(true);

    const handleChange = (value, isValid) => {
        setValidity(isValid);
        onChange(value);
    };

    return (
        <Form>
            <InputWrapper borderRadius={borderRadius}>
                <BoldInput
                    onChange={handleChange}
                    placeholder={t.depositLimits.setLimitPlaceholder}
                    defaultValue={initialValue}
                    value={value}
                    type="text"
                    showErrorOnChange
                    validate={{
                        min,
                        minMessage: byPlatform(
                            `Minimumsindbetalingsgrænsen er %d kr.`,
                            `Minimumsindbetalingsgrænse skal mindst være %d kr.`
                        ),
                        max,
                        maxMessage: `Maks grænse er %d kr.`,
                        numeric: true,
                    }}
                />
            </InputWrapper>
            <SetLimitBtn
                size="x2"
                className="uppercase"
                borderRadius={borderRadius}
                onClick={submit}
                disabled={!isValid}
            >
                {t.depositLimits.submit}
            </SetLimitBtn>
        </Form>
    );
};

export default LimitForm;
