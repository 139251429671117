import { useMutation } from '@tanstack/react-query';
import { request } from 'utils/server';
import useAuth from 'common/hooks/useAuth';

interface CommentMutationParams {
    couponId: number;
}

const useCommentMutation = ({ couponId }: CommentMutationParams) => {
    const auth = useAuth();
    const userId = auth.user ? auth.user.id : 0;

    const saveComment = (comment: string) => {
        return request('Spilklub', 'POST', '/coupon/comment/save', {
            comment,
            couponId,
            userId,
        });
    };

    const { mutateAsync, isLoading, isSuccess } = useMutation({
        mutationFn: saveComment,
    });

    return { saveComment: mutateAsync, removeComment: () => mutateAsync(''), isLoading, isSuccess };
};

export default useCommentMutation;
