import { ProductId } from 'features/EventBoard/server/calendar';
import {
    DEFAULT_COMBINATION_PRICES,
    PRODUCT_IDS,
    PRODUCTS_NAMES,
    STRICT_V_GAMES,
    V_GAMES,
} from 'configs/products';

export default interface Product {
    id: ProductId;
    name: string;
    disabled: boolean;
    isMultitrack: boolean;
}

export const defaultProduct: Product = {
    id: 'V',
    name: PRODUCTS_NAMES.V,
    disabled: false,
    isMultitrack: false,
};

export function isVProduct(productId: ProductId) {
    return V_GAMES.some(vProductId => vProductId === productId);
}

export function isDoubleProduct(productId: ProductId) {
    return [PRODUCT_IDS.LD, PRODUCT_IDS.DD].includes(productId);
}

export function isStrictVProduct(productId: ProductId) {
    return STRICT_V_GAMES.some(vProductId => vProductId === productId);
}

export function getBasicPrice(productId: ProductId) {
    return DEFAULT_COMBINATION_PRICES[productId] || 1;
}

export function getProductName(productId: ProductId, { strictBet }: { strictBet: boolean }) {
    if (strictBet) {
        return productId === PRODUCT_IDS.V64 || productId === PRODUCT_IDS.V65
            ? 'V6'
            : productId === PRODUCT_IDS.GS75
            ? 'GS7'
            : productId;
    } else {
        return productId;
    }
}

export function decimalsAllowed(productId: ProductId) {
    const decimalsAllowedFor = [PRODUCT_IDS.V75, PRODUCT_IDS.V86];
    return decimalsAllowedFor.indexOf(productId) !== -1;
}
