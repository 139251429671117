import React from 'react';
import Icon from 'ui/Icon';
import { TriggerButton } from 'features/Spilklub2/Mobile/styled';
import useTrigger from 'features/Spilklub2/hooks/useTrigger.ts';

const SpilklubButton = () => {
    const trigger = useTrigger();

    return (
        <TriggerButton onClick={trigger}>
            <Icon ispilklub2 size="x07" color="white" />
        </TriggerButton>
    );
};

export default SpilklubButton;
