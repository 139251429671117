import React from 'react';
import FlexWrapper from 'ui/FlexWrapper';
import Text from 'ui/Text';
import { byPlatform } from 'utils/platforms';
import { NOT_V_SIMILAR_PRODUCTS, PRODUCTS_MIXED_VIEW, PRODUCTS_WITHOUT_PODDS } from '../';
import styled from 'styled-components';
import { numberWithCommasAndDots } from 'utils/formatters.js';
import { COLORS } from 'themes/index.ts';

const FlexWrapperModified = styled(FlexWrapper)`
    & > * {
        flex: unset;
    }
`;

const FINISHED_HORSE = 1;
const DISQUALIFIED_HORSE = 2;
const SCRATCHED_HORSE = 3;

const getHorseFinishPlace = start => {
    if (start.distibution === DISQUALIFIED_HORSE) {
        return 'udg.';
    }

    if (start.distibution === SCRATCHED_HORSE) {
        return 's';
    }

    if (start.finishPos === '0') {
        return '0';
    }

    return start.finishPos;
};

const WinnerRow = ({ winner: start, leg, product, index }) => {
    return (
        <FlexWrapperModified
            direction="row"
            width="auto"
            align="start"
            padding={byPlatform('10px', '10px 20px', '10px 20px')}
            style={{
                background: index % 2 === 1 ? '#fff' : 'rgb(245, 247, 250)',
            }}
        >
            <Text
                align="center"
                size={byPlatform('12', 'x1', 'x1')}
                padding="8px 0px"
                width={byPlatform('40px', '50px', '50px')}
                color={COLORS.black}
                bold
            >
                {NOT_V_SIMILAR_PRODUCTS.includes(product.id) ? getHorseFinishPlace(start) : leg.leg}
            </Text>

            <Text
                align="left"
                size={byPlatform('12', 'x1', 'x1')}
                padding="0px"
                width={byPlatform('140px', '300px', '300px')}
                color={COLORS.black}
                bold
                style={{
                    textDecoration:
                        start.finishPos === '0' && start.distibution === SCRATCHED_HORSE
                            ? 'line-through'
                            : 'none',
                }}
            >
                <b
                    style={{
                        fontSize: '12px',
                        fontWeight: 900,
                        textTransform: 'uppercase',
                    }}
                >
                    {start.startPos} {start.horse}
                </b>{' '}
                <br />
                <span style={{ fontSize: '10px' }}>{start.driver?.replace(',', '')}</span>
            </Text>

            {!NOT_V_SIMILAR_PRODUCTS.includes(product.id) &&
            !PRODUCTS_MIXED_VIEW.includes(product.id) ? (
                <Text
                    align="left"
                    size={byPlatform('12', 'x1', 'x1')}
                    padding="8px 0px"
                    width={byPlatform('40px', '100px', '100px')}
                    color={COLORS.black}
                    bold
                >
                    {start.distibution}
                </Text>
            ) : null}

            {/* For simple products the V odds are displayed before the P odds */}
            {NOT_V_SIMILAR_PRODUCTS.includes(product.id) && (
                <Text
                    align="left"
                    size={byPlatform('12', 'x1', 'x1')}
                    padding="8px 0px"
                    width={byPlatform('60px', '100px', '100px')}
                    color={COLORS.black}
                    style={{ fontWeight: 600 }}
                >
                    {(start.vOdds / 100).toFixed(2)}
                </Text>
            )}

            {NOT_V_SIMILAR_PRODUCTS.includes(product.id) ? (
                !PRODUCTS_WITHOUT_PODDS.includes(product.id) &&
                !PRODUCTS_MIXED_VIEW.includes(product.id) ? (
                    <Text
                        align="left"
                        size={byPlatform('12', 'x1', 'x1')}
                        padding="8px 0px"
                        width={byPlatform('60px', '100px', '100px')}
                        bold
                        uppercase
                        color={COLORS.black}
                    >
                        {((start.pOdds || 0) / 100).toFixed(2)}
                    </Text>
                ) : null
            ) : !PRODUCTS_MIXED_VIEW.includes(product.id) ? (
                <Text
                    align="left"
                    size={byPlatform('12', 'x1', 'x1')}
                    padding="8px 0px"
                    width={byPlatform('60px', '100px', '100px')}
                    color={COLORS.black}
                >
                    {numberWithCommasAndDots(leg.systemsLeft)}
                </Text>
            ) : null}

            {/* For V-like products the V odds are displayed after systems */}
            {!NOT_V_SIMILAR_PRODUCTS.includes(product.id) && (
                <Text
                    align="left"
                    size={byPlatform('12', 'x1', 'x1')}
                    padding="8px 0px"
                    width={byPlatform('60px', '100px', '100px')}
                    color={COLORS.black}
                    style={{ fontWeight: 600 }}
                >
                    {(start.vOdds / 100).toFixed(2)}
                </Text>
            )}

            {!NOT_V_SIMILAR_PRODUCTS.includes(product.id) &&
            !PRODUCTS_MIXED_VIEW.includes(product.id) ? (
                <Text
                    align="left"
                    size={byPlatform('12', 'x1', 'x1')}
                    padding="8px 0px"
                    width={byPlatform('60px', '100px', '100px')}
                    color={COLORS.black}
                    style={{ fontWeight: 600 }}
                >
                    {leg.value} kr.
                </Text>
            ) : null}

            <Text
                align="center"
                size={byPlatform('12', 'x1', 'x1')}
                padding="8px 0px"
                width={byPlatform('60px', '100px', '100px')}
                color={COLORS.black}
                style={{ fontWeight: 600 }}
            >
                {start.finishTime}
            </Text>
        </FlexWrapperModified>
    );
};

export default WinnerRow;
