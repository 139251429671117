import styled, { css } from 'styled-components';

import { boxShadowMixin, CardHeader as CardHeaderDef } from 'ui/Card';
import Wrapper, { FilledWrapper } from 'ui/Wrapper';
import Button from 'ui/controls/Button';

import { BACKGROUNDS, COLORS } from 'themes';
import { byPlatform } from 'utils/platforms';
import { PRODUCT_IDS } from 'configs/products';
import { typographyCSS, TypographyProps } from 'ui/typography';
import { HorseInfoRowElementProps, nrColVisibilityLogic } from '../common/styled';

export const RaceCardWrapper = styled(FilledWrapper)<{ white?: boolean }>`
    padding: 0;
    margin: 20px 0;
    border-radius: 10px;
    ${boxShadowMixin};
`;

export const RaceCardHeaderContainer = styled(CardHeaderDef)`
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 30px;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    width: auto;
    padding: 12px 15px 10px 15px;
    @media (min-width: 830px) and (max-width: 890px) {
        font-size: 12px;
    }
`;

export const HeaderCol = styled.div`
    font-weight: 900;
    font-size: byPlatform(0, 16, 18);
`;

export const JackpotLabel = styled.div`
    padding: 5px 7px;
    background: ${BACKGROUNDS.accentAlt};
    border-radius: 3px;
    font-weight: 900;
    color: ${COLORS.black};
    font-size: 12px;
    margin-left: 7px;
    align-items: center;
`;

export const RaceInfoContainer = styled.div`
    color: #8bf7c3;
    word-spacing: 7px;
    @media (max-width: 830px) {
        display: none;
    }
`;

export const RaceCardActionsContainer = styled(Wrapper)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 10px;
    padding: ${byPlatform(0, '4px', '6px')} 0;
    @media (max-width: 750px) {
        display: none;
    }
`;

const trioNrCol = '120px';
export const kombNrCol = '110px';

export const RaceCardActionsLeftSide = styled.div<{ showTrioButtons?: boolean }>`
    display: ${props => (props.showTrioButtons ? 'grid' : 'flex')};
    grid-template-columns: ${trioNrCol} 1fr;
    align-items: center;
`;

export const ActionButton = styled(Button).attrs({ grey: true })<{
    specialRed?: boolean;
    specialGreen?: boolean;
}>`
    border-radius: 5px;
    margin: 0;
    width: 100px;
    height: ${byPlatform('auto', 'auto', '45px')};
    font-size: ${byPlatform('10px', '10px', '12px')};
    font-weight: 900;
    cursor: pointer;
    line-height: 14px;
    border: ${props =>
        props.specialRed
            ? `0.5px solid ${COLORS.special.red}`
            : props.specialGreen
            ? `0.5px solid ${COLORS.special.green}`
            : '0.5px solid #c6ccdb'};
    transition: background 300ms ease;
    ${({ specialRed, specialGreen }) =>
        (specialRed || specialGreen) &&
        css`
            background: ${specialRed ? COLORS.special.red : COLORS.special.green};
            color: white;
        `};
    &:hover {
        background: ${props =>
            props.specialRed
                ? COLORS.special.red
                : props.specialGreen
                ? COLORS.special.green
                : '#c6ccdb'};
    }
    ${({ active }) =>
        active &&
        `
        background: ${BACKGROUNDS.primary};
        border: 0;
        color: ${COLORS.white};
        &:hover {
            background: ${BACKGROUNDS.primary};
        }
    `};
`;

export const ReserveButton = styled(ActionButton)`
    ${({ reservesMode }) =>
        reservesMode &&
        `
        background: ${BACKGROUNDS.blue};
        border: 0;
        color: ${COLORS.white};
        &:hover {
            background: ${BACKGROUNDS.blue};
        }
    `};
`;

const getGridColumns = ({
    isVStrictProduct,
    productId,
    gallop,
    hideNrCol,
}: HorseInfoRowElementProps) => {
    const nrColMap = {
        [PRODUCT_IDS.T]: trioNrCol,
        [PRODUCT_IDS.TV]: '110px',
        [PRODUCT_IDS.K]: kombNrCol,
    };
    const oddsColMap = { [PRODUCT_IDS.T]: '60px', [PRODUCT_IDS.VP]: '110px' };

    const nrCol = hideNrCol ? '' : nrColMap[productId] ?? '60px';
    const horseNameCol = '1fr';
    const VOddsCol = '40px';
    const oddsCol = oddsColMap[productId] ?? '100px';
    const trainerCol = '1fr';
    const formtalCol = '50px';
    const shoeOrWeightCol = '50px';
    const moneyCol = '90px';
    const moneyCol2 = '90px';
    const starterLivsCol = '100px';

    return `
        grid-template-columns:
            ${nrCol}
            ${horseNameCol}
            ${isVStrictProduct ? VOddsCol : ''}
            ${oddsCol}
            ${trainerCol}
            ${gallop ? formtalCol : ''}
            ${shoeOrWeightCol}
            ${moneyCol}
            ${moneyCol2}
            ${starterLivsCol};
        
        @media(max-width: 1085px) {
            grid-template-columns:
                ${nrCol}
                ${horseNameCol}
                ${isVStrictProduct ? VOddsCol : ''}
                ${oddsCol}
                ${gallop ? formtalCol : ''}
                ${shoeOrWeightCol}
                ${moneyCol}
                ${moneyCol2}
                ${starterLivsCol}
        }
        
        @media(max-width: 895px) {
            grid-template-columns:
                ${nrCol}
                ${horseNameCol}
                ${isVStrictProduct ? VOddsCol : ''}
                ${oddsCol}
                ${gallop ? formtalCol : ''}
                ${shoeOrWeightCol}
                ${moneyCol}
                ${moneyCol2}
        }
    `;
};

const tableGrid = css<HorseInfoRowElementProps>`
    display: grid;
    align-items: center;
    column-gap: 5px;
    ${getGridColumns};
    ${nrColVisibilityLogic};
`;

export const TableHeader = styled.div<HorseInfoRowElementProps>`
    ${tableGrid};
    grid-template-rows: 20px; // header row height
    background: ${COLORS.black};
    color: ${COLORS.white};
`;

export const TableRowContainer = styled.div<HorseInfoRowElementProps>`
    ${tableGrid};
    grid-template-rows: 50px; // rest rows height
    cursor: pointer;
`;

export const TableRowWithStats = styled.div`
    &:last-of-type ${TableRowContainer} {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    &:nth-of-type(even) ${TableRowContainer} {
        background: #ebeff6;
    }
`;

interface TableColProps {
    center?: boolean;
}
interface TableColVisibilityProps {
    lg?: boolean;
    md?: boolean;
}

const columnsVisibilityCSS = css<TableColVisibilityProps>`
    @media (max-width: 1085px) {
        ${props => (props.lg ? 'display: none;' : '')};
    }
    @media (max-width: 895px) {
        ${props => (props.md ? 'display: none;' : '')};
    }
`;

export const TableHeaderCol = styled.div<TableColProps & TypographyProps & TableColVisibilityProps>`
    ${typographyCSS};
    ${columnsVisibilityCSS};
    font-size: 12px;
    font-weight: 900;
    justify-self: ${props => (props.center ? 'center' : 'auto')};
`;

export const TableCol = styled.div<TableColProps & TypographyProps & TableColVisibilityProps>`
    ${typographyCSS};
    ${columnsVisibilityCSS};
    font-size: 16px;
`;

export const ShoeCol = styled.span`
    font-family: Roboto, sans-serif;
    font-weight: 400;
`;
