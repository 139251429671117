import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BetSlip from 'features/BetSlip2';

import {
    performBet as postBet,
    resetSmartLyn,
    setPrevAmount,
    setSmartLynMode,
    setStrictMode,
} from 'features/BetSlip2/state/actions';
import { isUserLoggedIn, updateUserInfo } from 'common/actions/authActions';
import betSlipSelector from 'common/selectors/betSlipSelector';
import NotificationConductor from 'common/conductors/NotificationConductor';
import {
    ACCEPTABLE_FLOAT_POINTS,
    COMBINATION_PRICE_EVEN_NUMBERS_ONLY,
    MAX_COMBINATION_PRICES,
    MAX_COUPON_AMOUNT,
    MIN_COMBINATION_PRICES,
    DEFAULT_COMBINATION_PRICES,
    SMART_LYN_AMOUNTS,
    MAX_SMART_LYN_COUPON_AMOUNT,
} from 'configs/products';
import { combinationsCountBy } from 'common/selectors/combinationsCountSelector';
import UserSessionAdapter from 'common/adapters/UserSession';
import { extractErrorMessageFromResponse, getTranslatedErrorMessage } from 'utils/betting-service';
import { postPlayAndPaySale } from 'features/BetSlip2/components/PlayAndPay/actions';
import { getMethodName, getQuickPickMethodName, adjustCouponToSmartLyn } from 'utils/retail';
import { setMobileBetInit } from 'features/MobileBet/state/actions';
import getTexts from 'utils/localization';
import { logMessage } from 'utils/log';
import withQuickPickBetting from '../QuickPick/withQuickPickBetting';
import withSmartLynManager from './components/SmartLyn/withSmartLynManager';
import { byPlatform } from 'utils/platforms';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import useServerTimeQuery from 'features/EventBoard/hooks/useServerTimeQuery';
import useBetPicks from './hooks/useBetPicks';
import useAuth from 'common/hooks/useAuth';
import useCoupon from './hooks/useCoupon';
import UnpaidSaleCoupon from './BettingREST/DataObjects/UnpaidSaleCoupon';
import { RootState } from 'common/store';

const t = getTexts();

const BetSlipContainer = (props: any) => {
    const auth = useAuth();

    const betSlip = useSelector(betSlipSelector);
    const performBetError = useSelector((state: RootState) => state.BetSlip.performBetError);
    const performBetPending = useSelector((state: RootState) => state.BetSlip.performBetPending);
    const prevAmount = useSelector((state: RootState) => state.BetSlip.prevAmount);
    const smartLynMode = useSelector((state: RootState) => state.BetSlip.smartLynMode);
    const mobileBetInit = useSelector((state: RootState) => state.MobileBet.initialization);
    const strictBet = useSelector((state: RootState) => state.BetSlip.strictMode);

    const {
        onBetPerformed,
        onAuthenticationRequired,
        onMobileBetWorkflow,
        onCombinationPriceSet,
        smartLynBet,
        createSmartLynLegs,
        performQuickPickBet,
        setQuickPickAmount,
        lockedLegs,
        allLegsLocked,
        toggleLegStatus,
        quickPickBetPending /* from withQuickPickBetting hoc */,
        activateSmartLyn,
    } = props;

    const dispatch = useDispatch();

    const {
        raceDay,
        trackNames,
        productId,
        product,
        isVProduct,
        isMultitrack: isMultitrackProduct,
        trackCode,
        postTime,
        races,
        race,
        racesFetched,
    } = useTrackPage();

    const couponFactory = useCoupon();

    const { serverTime } = useServerTimeQuery();

    const { singleRaceBetPicks, raceReservesMap: reserves } = useBetPicks({
        raceDay,
        productId,
        trackCode,
        race,
    });

    const combinationsCount = useSelector(state =>
        combinationsCountBy(state, {
            productId,
            trackCode,
            date: raceDay.raceDayDate,
            raceIndex: race.index,
            races,
        })
    );

    const minimumPicksValid = betSlip.checkMinimumPicks({
        date: raceDay.raceDayDate,
        trackCode,
        productId: productId,
        races,
    });

    const maximumPicksValid = betSlip.checkMaximumPicks({
        date: raceDay.raceDayDate,
        trackCode,
        productId: productId,
        races,
    });

    const basicPrice = DEFAULT_COMBINATION_PRICES[productId] ?? 1;

    useEffect(() => {
        strictBet && dispatch(setStrictMode(false));

        return () => {
            dispatch(resetSmartLyn());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleSmartLynMode = () => {
        if (smartLynMode) {
            dispatch(setSmartLynMode(false));
        } else {
            activateSmartLyn();
        }
    };

    const [combinationPrice, setCombinationPrice] = useState(!prevAmount ? basicPrice : prevAmount);
    const [isCombinationPriceValid, setCombinationPriceValid] = useState(true);
    const [combPriceError, setCombPriceError] = useState('');

    const [isCouponAmountExceed, setCouponAmountExceed] = useState(false);

    useEffect(
        () => {
            validateCouponAmount(combinationPrice);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [combinationsCount]
    );

    useEffect(() => {
        setCombinationPrice(basicPrice);
    }, [basicPrice, productId]);

    const applyCombinationPrice = (combinationPrice: any) => {
        const isValid = validateCombinationPrice(combinationPrice);
        const isCouponAmountValid = validateCouponAmount(combinationPrice);
        setCombinationPrice(combinationPrice);
        if (smartLynMode) {
            setQuickPickAmount(parseInt(combinationPrice, 10));
        }
        isValid &&
            isCouponAmountValid &&
            onCombinationPriceSet &&
            onCombinationPriceSet(combinationPrice);
    };

    const validateCouponAmount = (combinationPrice: number) => {
        let couponAmountExceed;

        if (smartLynMode) {
            couponAmountExceed = combinationPrice >= MAX_SMART_LYN_COUPON_AMOUNT;
        } else {
            couponAmountExceed = combinationsCount * combinationPrice > MAX_COUPON_AMOUNT;
        }

        setCouponAmountExceed(couponAmountExceed);
        return !couponAmountExceed;
    };

    const isFloat = (val: string) => /\./g.test(val);
    //@TODO use useAmount hook, this code duplicates a lot from it
    const isDecimalPointValid = (val: string) => {
        const acceptablePoints = ACCEPTABLE_FLOAT_POINTS[productId];
        const regex = new RegExp(`^\\d+\\.(${acceptablePoints.join('|')})$`, 'g');
        return regex.test(val);
    };
    const isNumberEven = (val: number) => val % 2 === 0;

    const validateCombinationPrice = (combinationPrice: any) => {
        const MIN_RATE = smartLynMode ? SMART_LYN_AMOUNTS[0] : MIN_COMBINATION_PRICES[productId];
        const MAX_RATE = MAX_COMBINATION_PRICES[productId];

        const incorectMinRate = MIN_RATE && combinationPrice < MIN_RATE;
        const incorectMaxRate = MAX_RATE && combinationPrice > MAX_RATE;
        const incorrectFloatAmount =
            isFloat(combinationPrice) && !isDecimalPointValid(combinationPrice);
        const incorrectByEvenNumberCheck =
            COMBINATION_PRICE_EVEN_NUMBERS_ONLY.includes(productId) &&
            !isNumberEven(combinationPrice);

        if (incorectMinRate) {
            setCombPriceError(t.betSlip.cpLow);
        }

        if (smartLynMode) {
            // smartLynMode needs only checking the min rate
            setCombinationPriceValid(!incorectMinRate);
            return !incorectMinRate;
        }

        if (incorectMaxRate) {
            setCombPriceError(t.betSlip.cpHigh);
        }

        if (incorrectByEvenNumberCheck && !incorectMaxRate) {
            // since there will be displayed only 1 message
            // incorrectMaxRate message is over even numbers checking
            // @see https://ecosys.atlassian.net/browse/DER-1358
            setCombPriceError(t.betSlip.cpEvenNumbers);
        }

        if (incorrectFloatAmount) {
            setCombPriceError(
                `Tilladte decimaltal: ${ACCEPTABLE_FLOAT_POINTS[productId].map(
                    point => `0.${point}`
                )}`
            );
        }

        const isPriceValid = !(
            incorectMinRate ||
            incorectMaxRate ||
            incorrectFloatAmount ||
            incorrectByEvenNumberCheck
        );

        setCombinationPriceValid(isPriceValid);

        return isPriceValid;
    };

    const getUnpaidSaleCoupon = async () => {
        const smartLynWorkflow = smartLynMode && smartLynBet;
        const method = smartLynWorkflow ? getQuickPickMethodName(product) : getMethodName(product);

        const unpaidSaleCoupon = new UnpaidSaleCoupon(couponFactory.create(combinationPrice));

        unpaidSaleCoupon.method = method;
        unpaidSaleCoupon.productInfo = {
            id: product.id,
            name: product.name,
        };

        unpaidSaleCoupon.postTime = postTime;
        unpaidSaleCoupon.trackInfo = {
            id: raceDay.trackId,
            code: raceDay.track.code,
            name: raceDay.track.domesticText,
        };

        if (smartLynWorkflow) {
            // returns serialized coupon
            return adjustCouponToSmartLyn(unpaidSaleCoupon, createSmartLynLegs);
        }

        return unpaidSaleCoupon;
    };

    const performMobileBet = async (prices: number[]) => {
        try {
            dispatch(setMobileBetInit(true));
            const uSaleInfo = await getUnpaidSaleCoupon();
            onMobileBetWorkflow(prices, uSaleInfo, lockedLegs);
        } catch (e) {
            console.error(e);
        }
    };

    const performPlayAndPaySale = async () => {
        try {
            const coupon = await getUnpaidSaleCoupon();

            return dispatch(
                postPlayAndPaySale(coupon, (response: any) => {
                    if (response.success) {
                        onBetPerformed && onBetPerformed(response.data, props, true);
                    } else {
                        NotificationConductor.error(
                            'PlayAndPay request error: ' + response.error_message
                        );
                    }

                    return response;
                })
            );
        } catch (e) {
            console.error(e);
            return e;
        }
    };

    const combinedTrackName = trackNames.join('/');

    const sharedProps = {
        ...props,
        singleRaceBetPicks,
        racesFetched,
        betSlip,
        reserves,
        combinedTrackName,
        date: raceDay.raceDayDate,
        raceDay,
        product,
        trackCode,
        isVProduct,
        isMultitrackProduct,
        races,
        race: isVProduct ? races[0] : race,
        serverTime,
        toggleSmartLynMode: byPlatform(toggleSmartLynMode, props.toggleSmartLynMode),
        combinationPrice,
        applyCombinationPrice,
        isCombinationPriceValid,
        combinationPriceError: combPriceError,
        isCouponAmountExceed,
        performPlayAndPaySale,
        performMobileBet,
        mobileBetInit,
        combinationsCount,
        minimumPicksValid,
        maximumPicksValid,
        performBetPending,
        performBetError,
        user: auth.user,
        onAuthenticationRequired,
        lockedLegs,
        allLegsLocked, // from withQuickPickBetting
        toggleLegStatus,
        smartLynBet,
        smartLynMode,
        quickPickBetPending,
    };

    const performBet = async () => {
        dispatch(setPrevAmount(0));

        try {
            if (!auth.user) {
                onAuthenticationRequired && onAuthenticationRequired();
                return;
            } else {
                const coupon = couponFactory.create(combinationPrice);

                try {
                    const couponData = UserSessionAdapter.getPerformSaleData(
                        coupon,
                        auth.user.id,
                        postTime
                    );
                    const response: any = await dispatch(postBet(product, couponData));
                    if (response.success) {
                        onBetPerformed && onBetPerformed(response.data, sharedProps);

                        await UserSessionAdapter.performUserUpdate(
                            () => dispatch(isUserLoggedIn),
                            () => dispatch(updateUserInfo),
                            response
                        );
                    } else {
                        const error = getTranslatedErrorMessage(response);

                        logMessage(extractErrorMessageFromResponse(response), {
                            context: 'BetSlipConntainer.performBet:responseError',
                        });

                        NotificationConductor.error(error);
                    }
                } catch (e) {
                    console.error(e);
                    logMessage(e, { context: 'BetSlipConntainer.performBet' });
                    NotificationConductor.error(t.betSlip.performBetError);
                }
            }
        } catch (e) {
            console.error(e);
            logMessage(e, { context: 'BetSlipConntainer.performBet' });
            NotificationConductor.error(t.betSlip.getAvailableCardsError);
        }
    };

    return (
        <BetSlip {...sharedProps} confirmBet={smartLynMode ? performQuickPickBet : performBet} />
    );
};

export default withQuickPickBetting(withSmartLynManager(BetSlipContainer));
